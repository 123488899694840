import { Expose } from "class-transformer";
import { IsUUID, IsString, IsDate, IsObject, IsBoolean, IsArray } from "class-validator";
import { ViewColumn } from "./typeorm-mocks";

export interface Contact {
	firstName: string;
	lastName: string;
	email: string;
	address: string;
	phone: string;
	linkedinURL: string;
	website: string;
}

export interface CompanyInfo {
	companyName: string;
	companyAddress: string;
	companyHiringManagerName: string;
}

export interface CoverLetterData {
	introduction: string;
	body: string;
	closing: string;
}

export interface FeedbackDataNew {
	feedback: string;
	score: number;
}

export interface OverallFeedbackDataNew {
	positiveFeedback: string;
	areasToImprove: string;
	score: number;
}

export interface CoverLetterFeedbackNew {
	overall: OverallFeedbackDataNew;
	introduction: FeedbackDataNew;
	body: FeedbackDataNew;
	closing: FeedbackDataNew;
}

export class CoverLetterView {
	@ViewColumn()
	@Expose()
	@IsUUID()
	id!: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	userId!: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	resumeId!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	userEmail!: string;

	@ViewColumn()
	@Expose()
	@IsObject()
	contact!: Contact;

	@ViewColumn()
	@Expose()
	@IsObject()
	companyInfo!: CompanyInfo;

	@ViewColumn()
	@Expose()
	@IsObject()
	coverLetterData!: CoverLetterData;

	@ViewColumn()
	@Expose()
	@IsObject()
	feedbackNew!: CoverLetterFeedbackNew;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	isPublic!: boolean;

	@ViewColumn()
	@Expose()
	@IsDate()
	createdAt!: Date;

	@ViewColumn()
	@Expose()
	@IsDate()
	updatedAt!: Date;
}
