import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AssignmentService } from "../../services/AssignmentService";
import BackButton from "./BackButton";
import { useAuth } from "../../contexts/AuthContext";
import Spinner from "./Spinner";
import { Button } from "../shadcn-ui/Button";

const AssignmentCodePage: React.FC = () => {
	const { assignmentCode } = useParams<{ assignmentCode: string }>();

	const { userId } = useAuth();
	const [assignmentId, setAssignmentId] = useState<string | null>(null);
	const [userInAssignment, setUserInAssignment] = useState<boolean>(false);
	const [assignmentTitle, setAssignmentTitle] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);
	useEffect(() => {
		const fetchAssignment = async () => {
			if (!assignmentCode) {
				return;
			}
			const assignment = await AssignmentService.getClientNameAndIdFromAssignmentCode(assignmentCode);
			setAssignmentId(assignment.assignmentId);
			setAssignmentTitle(assignment.assignmentTitle);
			setIsLoading(false);
		};
		fetchAssignment();
	}, [assignmentCode]);

	useEffect(() => {
		const fetchUserAssignmentStatus = async () => {
			if (!assignmentId || !userId) {
				return;
			}
			console.log("fetching user assignment status", assignmentId, userId);
			const userAssignmentStatus = await AssignmentService.checkIfUserIsInAssignment(assignmentId, userId);
			console.log(userAssignmentStatus, "userAssignmentStatus");
			setUserInAssignment(userAssignmentStatus);
		};
		fetchUserAssignmentStatus();
	}, [assignmentId, userId]);

	async function handleAddUserToAssignment() {
		if (!assignmentId || !userId) {
			return;
		}
		setIsLoading(true);
		await AssignmentService.addUserToAssignment(assignmentId, userId);
		setIsLoading(false);
		window.location.href = "/";
	}

	function handleCancel() {
		window.location.href = "/";
	}

	return (
		<div>
			<BackButton />
			<div className="flex items-center justify-center mb-4">
				<div className="mb-8 bg-white p-8 rounded-lg shadow max-w-md w-full">
					{isLoading ? (
						<div className="flex justify-center items-center h-64">
							<Spinner />
						</div>
					) : (
						<>
							<h1 className="text-xl font-bold mb-4">Assignment Code</h1>
							<p className="text-xl mb-6">{assignmentCode}</p>

							<h1 className="text-xl font-bold mb-4">Assignment Title</h1>
							<p className="text-xl mb-6">{assignmentTitle}</p>

							<h1 className="text-xl font-bold mb-4">Do you want to join this assignment?</h1>

							{!userInAssignment && (
								<div className="flex flex-col gap-4 mt-6">
									<Button variant="default" onClick={handleAddUserToAssignment} className="text-lg py-4">
										Yes
									</Button>
									<Button variant="outline" onClick={handleCancel} className="text-lg py-4">
										No
									</Button>
								</div>
							)}
							{userInAssignment && (
								<div className="mt-6">
									<p className="mb-4">You are already in this assignment</p>
									<Button onClick={handleCancel} className="text-lg py-3 w-full">
										Go Home
									</Button>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default AssignmentCodePage;
