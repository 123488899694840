import React, { useEffect } from "react";
import "reflect-metadata";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { FronteggProvider } from "@frontegg/react";
import { AppContextProvider } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import Intercom from "react-intercom";
import { useAppContext } from "./contexts/AppContext";

// POSTHOG
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY_NEW as string, {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || "",
	disable_session_recording: false,
	enable_recording_console_log: true
});

// Track page navigation for Frontegg pages
const FronteggWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { setPagesNavigated, pagesNavigated } = useAppContext();

	useEffect(() => {
		const currentPath = window.location.pathname;
		setPagesNavigated((prev) => {
			const stored = sessionStorage.getItem("pagesNavigated");
			const prevPages = stored ? JSON.parse(stored) : prev;

			// Don't add if it's the same as the last path
			if (prevPages[prevPages.length - 1] === currentPath) {
				return prevPages;
			}
			return [...prevPages, currentPath];
		});
	}, [setPagesNavigated]);

	useEffect(() => {
		// console.log("pagesNavigated", pagesNavigated);
		if (pagesNavigated.length > 0 && pagesNavigated[pagesNavigated.length - 1] === "/account/login") {
			try {
				// console.log("MAJOR_EVENT: User Navigated To Login Page");
				posthog.capture("MAJOR_EVENT: User Navigated To Login Page", {
					timestamp: new Date().toISOString()
				});
			} catch (err) {
				console.error("Error capturing event 'M.E. User Navigated To Login Page':", err);
			}
		}
		if (pagesNavigated.length > 0 && pagesNavigated[pagesNavigated.length - 1].includes("/account/signup")) {
			try {
				// console.log("MAJOR_EVENT: User Navigated To Custom Signup Page");
				posthog.capture("MAJOR_EVENT: User Navigated To Custom Signup Page", {
					timestamp: new Date().toISOString()
				});
			} catch (err) {
				console.error("Error capturing event 'M.E. User Navigated To Custom Signup Page':", err);
			}
		}
	}, [pagesNavigated]);

	return <>{children}</>;
};

// FRONTEGG
const fronteggDomain = process.env.REACT_APP_FRONTEGG_DOMAIN;

const contextOptions = {
	baseUrl: fronteggDomain || "",
	appId: process.env.REACT_APP_FRONTEGG_APP_ID
};

const themeOptions = {
	loginBox: {
		boxFooter: () => {
			const searchParams = new URLSearchParams(window.location.search);
			let assignmentCode = searchParams.get("assignmentCode");
			// let assignmentCode = "DEMO123";
			//bconsole.log("assignmentCode", assignmentCode);

			// Construct the signup URL with all query parameters preserved
			// If accId exists, append it to the path; otherwise, just use the base signup path
			const signupUrl = `${window.location.origin}/account/signup/${assignmentCode}`;
			if (assignmentCode) {
				return (
					<>
						<AuthProvider>
							<AppContextProvider>
								<FronteggWrapper>
									<Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID || ""} />
								</FronteggWrapper>
							</AppContextProvider>
							<div
								style={{
									textAlign: "center",
									margin: "2rem 0 0.5rem",
									fontSize: "1.75rem",
									fontWeight: "bold",
									color: "rgb(60, 74, 90)"
								}}
							>
								Need an account?{" "}
							</div>
							<div className="text-center mt-1.5">
								<a
									href={signupUrl}
									rel="noreferrer"
									className="block mx-auto h-10 leading-10 w-full px-6 font-['Arimo'] text-sm font-semibold text-white bg-[#00a9af] border border-[#00a9af] rounded no-underline"
								>
									Sign up
								</a>
							</div>
						</AuthProvider>
					</>
				);
			} else {
				return (
					<AuthProvider>
						<AppContextProvider>
							<FronteggWrapper>
								<Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID || ""} />
							</FronteggWrapper>
						</AppContextProvider>
					</AuthProvider>
				);
			}
		}
	}
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<FronteggProvider hostedLoginBox={false} contextOptions={contextOptions} themeOptions={themeOptions} entitlementsOptions={{ enabled: true }}>
		<PostHogProvider client={posthog}>
			<AuthProvider>
				<AppContextProvider>
					<FronteggWrapper>
						<Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID || ""} />

						<App />
					</FronteggWrapper>
				</AppContextProvider>
			</AuthProvider>
		</PostHogProvider>
	</FronteggProvider>
);
