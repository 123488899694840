import axios from "axios";
import { CoverLetterView } from "../types/CoverLetterView";
import { plainToInstance } from "class-transformer";
import { LogAxiosError } from "./AxiosUtility";
import { OverallFeedbackDataNew, FeedbackDataNew, RewriteType } from "./ResumeService";

export class CoverLetterFeedbackNew {
	overall!: OverallFeedbackDataNew;
	introduction!: FeedbackDataNew;
	body!: FeedbackDataNew;
	closing!: FeedbackDataNew;
}

export class CoverLetterService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/cover-letter`;

	public static async fetchCoverLetterByResumeId(resumeId: string): Promise<CoverLetterView> {
		try {
			const response = await axios.get(`${this.baseUrl}/saved/get-saved-cover-letter-by-resume-id/${resumeId}`);
			return plainToInstance(CoverLetterView, response.data as CoverLetterView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `fetch cover letter ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async createCoverLetter(resumeId: string): Promise<CoverLetterView> {
		try {
			const response = await axios.post(`${this.baseUrl}/user/create/cover-letter`, { resumeId });
			return plainToInstance(CoverLetterView, response.data as CoverLetterView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `create resume`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async generateCoverLetter(resumeId: string): Promise<CoverLetterView> {
		try {
			const response = await axios.post(`${this.baseUrl}/user/generate/cover-letter`, { resumeId });
			return plainToInstance(CoverLetterView, response.data as CoverLetterView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `generate cover letter ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async updateCoverLetter(coverLetterId: string, updatedCoverLetter: CoverLetterView): Promise<CoverLetterView> {
		try {
			console.log("coverLetter", updatedCoverLetter);
			const response = await axios.put(`${this.baseUrl}/user/update/cover-letter/${coverLetterId}`, { updatedCoverLetter });
			return plainToInstance(CoverLetterView, response.data as CoverLetterView);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update cover letter ${coverLetterId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async rewriteSection(sectionName: string, rewriteType: RewriteType, coverLetterId: string, sectionData: any): Promise<String> {
		try {
			const response = await axios.post(`${this.baseUrl}/rewrite-section`, {
				sectionName,
				rewriteType,
				coverLetterId,
				sectionData: JSON.stringify(sectionData)
			});
			return plainToInstance(String, response.data as String);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `rewrite section ${sectionName}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async generateFeedbackNew(coverLetterId: string): Promise<CoverLetterFeedbackNew> {
		try {
			const response = await axios.post(`${this.baseUrl}/generate-feedback-new`, { coverLetterId });
			return plainToInstance(CoverLetterFeedbackNew, response.data as CoverLetterFeedbackNew);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `generate feedback new ${coverLetterId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	public static async deleteCoverLetterByResumeId(resumeId: string): Promise<void> {
		try {
			await axios.delete(`${this.baseUrl}/user/delete/cover-letter/${resumeId}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `delete cover letter ${resumeId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}
}
