export enum SessionType {
	REFLECTION_COOP = "Reflection Co-op",
	REFLECTION_COURSE = "Reflection Course",
	INBOUND = "Inbound",
	INTERVIEW = "Interview",
	FRONTLINE = "Frontline",
	JOB_SEARCH = "Job Search",
	CHECK_IN = "Check-In",
	CAREER_PATH = "Career Path"
}
