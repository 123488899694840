import React from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../../../shadcn-ui/Button";
import InterviewPlan from "./InterviewPlan";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import { InterviewStage, InterviewStageType, InterviewStagePlan } from "../../../../types/InterviewPayload";

// Define props for the InterviewSetup component
export interface InterviewSetupProps {
	values: {
		stages?: InterviewStage[];
		stagePlan: {
			[key: string]: InterviewStagePlan;
		};
		[key: string]: any;
	};
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onPrevious: () => void;
	onNext: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

// Helper function to determine stage type based on order
export const determineStageType = (orderNumber: number): InterviewStageType => {
	switch (orderNumber) {
		case 0:
			return "resume";
		case 1:
			return "screening";
		case 2:
			return "middle";
		case 3:
			return "final";
		default:
			return orderNumber > 3 ? "final" : "middle";
	}
};

// Helper function to get stage title based on type and order
export const getStageTitle = (type: InterviewStageType, orderNumber: number): string => {
	switch (type) {
		case "resume":
			return "Resume Review";
		case "screening":
			return "Screening Interview";
		case "middle":
			return `Middle Interview ${orderNumber > 2 ? orderNumber - 1 : ""}`;
		case "final":
			return "Final Interview";
	}
};

// InterviewSetup component for capturing basic assignment details
const InterviewSetup: React.FC<InterviewSetupProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onPrevious,
	onNext,
	isValid,
	dirty,
	isEditMode
}) => {
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");

	// Check if each enabled stage has at least one question
	const isFormValid = () => {
		if (isEditMode) return true;

		return (
			values.stages?.every((stage: InterviewStage) => {
				// Skip check for disabled stages and resume review
				if (!stage.enabled || stage.interviewStageType === "resume") return true;

				const stagePlan = values.stagePlan?.[stage.interviewStageOrderNumber];
				if (!stagePlan) return false;

				// Calculate total questions for this stage
				const totalQuestions =
					(stagePlan.technicalQuestions || 0) +
					(stagePlan.behavioralQuestions || 0) +
					(stagePlan.situationalQuestions || 0) +
					(stagePlan.culturalFitQuestions || 0) +
					(stagePlan.backgroundQuestions || 0) +
					(stagePlan.motivationQuestions || 0) +
					(stagePlan.recallQuestions || 0) +
					(stagePlan.generalQuestions || 0) +
					(stagePlan.resumeQuestions || 0);

				return totalQuestions > 0;
			}) ?? false
		);
	};

	return (
		<div className="space-y-8">
			<Panel title="Customize Your Interview">
				{isAdminPath ? (
					<p className="text-base text-gray-600 mb-6">
						Configure the details of each selected interview stage. You can adjust question lists and set the interviewer's attitude.
						After this, you'll proceed to define the assignment details, send invitations, and set up notifications.
					</p>
				) : (
					<p className="text-base text-gray-600 mb-6">
						Design your practice interview experience. You can customize the questions for each stage and set the interviewer's style.
						This will help you prepare for real interviews in your desired role.
					</p>
				)}
			</Panel>

			{/* Interview Plans for each enabled stage, excluding resume review */}
			{values.stages
				?.filter((stage: InterviewStage) => stage.enabled && stage.interviewStageType !== "resume")
				.map((stage: InterviewStage, index: number) => {
					// Calculate total questions for this stage
					const stagePlan = values.stagePlan?.[stage.interviewStageOrderNumber];
					const totalQuestions = stagePlan
						? (stagePlan.technicalQuestions || 0) +
						  (stagePlan.behavioralQuestions || 0) +
						  (stagePlan.situationalQuestions || 0) +
						  (stagePlan.culturalFitQuestions || 0) +
						  (stagePlan.generalQuestions || 0) +
						  (stagePlan.resumeQuestions || 0)
						: 0;

					return (
						<Panel key={stage.interviewStageOrderNumber} title={`Interview Plan - ${stage.title}`} totalQuestions={totalQuestions}>
							<div className="mb-4">
								<p className="text-base text-gray-600 mb-6">
									Select a preset question order, or customize the sequence and type of questions. You can add, remove, or reorder
									questions to match your interview goals.
								</p>
							</div>
							<InterviewPlan
								interviewStageOrderNumber={stage.interviewStageOrderNumber}
								values={values}
								setFieldValue={setFieldValue}
								isAdminPath={isAdminPath}
							/>
						</Panel>
					);
				})}

			{/* Navigation buttons - changes positions on larger screens */}
			<div className="block md:flex justify-end mt-8 gap-2">
				<Button
					type="button"
					variant="secondary"
					onClick={() => {
						onPrevious();
					}}
				>
					Previous
				</Button>
				<Button
					name="next"
					aria-label="Next"
					value="Next"
					onClick={() => {
						onNext();
					}}
					disabled={!isFormValid()}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

// Panel component for grouping related form fields
const Panel: React.FC<{
	title: string;
	children: React.ReactNode;
	totalQuestions?: number;
	validationStatus?: boolean;
}> = ({ title, children, totalQuestions, validationStatus }) => {
	const getBorderAndIcon = () => {
		if (validationStatus !== undefined) {
			return validationStatus
				? {
						border: "border-[#00a9af]",
						icon: <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />
				  }
				: {
						border: "border-incomplete",
						icon: <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />
				  };
		}
		if (totalQuestions === undefined) {
			return { border: "border-[#eaecf0]", icon: null };
		}
		return totalQuestions > 0
			? {
					border: "border-[#00a9af]",
					icon: <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />
			  }
			: {
					border: "border-incomplete",
					icon: <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />
			  };
	};

	const { border, icon } = getBorderAndIcon();

	return (
		<div className={`bg-white border rounded-lg p-6 ${border}`}>
			<div className="flex justify-between items-center mb-4">
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				{icon}
			</div>
			<div className="space-y-4">{children}</div>
		</div>
	);
};

export default InterviewSetup;
