import { useState, useEffect, useCallback } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { UserService } from "../../../services/UserService";
import { useAuth } from "../../../contexts/AuthContext";
import posthog from "posthog-js";

const usePhoneVerification = (onVerificationComplete: (phoneNumber: string) => void, onCancel: () => void) => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [unformattedPhoneNumber, setUnformattedPhoneNumber] = useState("");
	const [countryCode, setCountryCode] = useState("+1-CA");
	const [error, setError] = useState("");
	const { instageUser } = useAuth();
	const [numberValid, setNumberValid] = useState(false);
	const [verificationCode, setVerificationCode] = useState<string[]>(["", "", "", ""]);
	const [isConsentChecked, setIsConsentChecked] = useState(false);

	const handlePhoneChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value.replace(/\D/g, "");
			setError("");
			if (value.length <= 10) {
				setUnformattedPhoneNumber(value);
				const formatted = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
				setPhoneNumber(formatted);
			}
			if (value.length <= 1 && instageUser) {
				// console.log("started phone verification");
				try {
					posthog.capture("MINOR: Started Phone Verification", { userId: instageUser.fronteggId, timestamp: new Date().toISOString() });
				} catch (err) {
					console.error("Error capturing event 'MINOR: Started Phone Verification':", err);
				}
			}
		},
		[instageUser]
	);

	const handleSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			const countryCodeOnly = countryCode.split("-")[0];
			const fullNumber = `${countryCodeOnly}${unformattedPhoneNumber}`;
			if (isValidPhoneNumber(fullNumber)) {
				try {
					await UserService.addPhoneNumber(fullNumber);

					onVerificationComplete(unformattedPhoneNumber);

					// await UserService.sendVerificationSMS();
					setNumberValid(true);
				} catch (error) {
					console.error("Error adding phone number:", error);
				}
			} else {
				setError("Please enter a valid phone number");
			}
		},
		[countryCode, unformattedPhoneNumber, onVerificationComplete]
	);

	const handleVerificationCodeChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, index: number) => {
			const value = e.target.value.replace(/\D/g, "");
			if (value) {
				const newCode = [...verificationCode];
				newCode[index] = value;
				setVerificationCode(newCode);

				// Auto-focus next input
				if (index < 3 && value.length === 1) {
					const nextInput = document.getElementById(`verificationCode${index + 2}`);
					nextInput?.focus();
				}
			}
		},
		[verificationCode]
	);

	const handleVerificationCodeKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
			if (e.key === "Backspace" && !verificationCode[index]) {
				const prevInput = document.getElementById(`verificationCode${index}`);
				if (index > 0) {
					const newCode = [...verificationCode];
					newCode[index - 1] = "";
					setVerificationCode(newCode);
					const prevInput = document.getElementById(`verificationCode${index}`);
					prevInput?.focus();
				}
			}
		},
		[verificationCode]
	);

	const handleVerificationCodePaste = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const pastedData = e.clipboardData.getData("text/plain").replace(/\D/g, "");
		const newCode = pastedData.slice(0, 4).split("");
		setVerificationCode([...newCode, ...Array(4 - newCode.length).fill("")]);

		// Focus last input after paste
		const lastInput = document.getElementById(`verificationCode4`);
		lastInput?.focus();
	}, []);

	// Add this useEffect to handle code submission
	useEffect(() => {
		if (verificationCode.every((code) => code !== "") && verificationCode.join("").length === 4) {
			const handleVerificationCodeSubmit = async () => {
				try {
					const code = verificationCode.join("");
					await UserService.verifyPhoneNumber(code);
					// Additional success handling if needed
				} catch (error) {
					console.error("Verification failed:", error);
					setVerificationCode(["", "", "", ""]);
				}
			};
			handleVerificationCodeSubmit();
		}
	}, [verificationCode]);

	return {
		phoneNumber,
		countryCode,
		verificationCode,
		isConsentChecked,
		error,
		numberValid,
		handlePhoneChange,
		handleSubmit,
		setCountryCode,
		setIsConsentChecked,
		handleVerificationCodeChange,
		handleVerificationCodeKeyDown,
		handleVerificationCodePaste
	};
};
export default usePhoneVerification;
