import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardContent } from "../shadcn-ui/Card";
import { Button } from "../shadcn-ui/Button";
import PhoneVerification from "../Shared/PhoneVerification";
// import { getCalApi } from "@calcom/embed-react";
import { useAuth } from "../../contexts/AuthContext";
import { UserService } from "../../services/UserService";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { assistants } from "../../constants/Assistants";
import { CheckIcon } from "lucide-react";
import CalendarBooking from "../Utility/CalendarBooking";
import { AssignmentService } from "../../services/AssignmentService";
import { AssignmentPayload } from "../../types/AssignmentPayload";

interface UserScheduleCallProps {
	preVerifiedPhone?: string;
	userEmail?: string;
	onClose?: () => void;
}

const getCalEventDetails = (): { eventTypeId: string; slug: string; seatsPerTimeSlot: number } => {
	switch (process.env.REACT_APP_ENVIRONMENT) {
		case "production":
			return { eventTypeId: "1290742", slug: "reflection-call-prod", seatsPerTimeSlot: 5 };
		case "staging":
			return { eventTypeId: "1290724", slug: "reflection-call-stag", seatsPerTimeSlot: 5 };
		default:
			return { eventTypeId: "1283324", slug: "reflection-call-dev", seatsPerTimeSlot: 2 };
	}
};

const SuccessMessage: React.FC = () => (
	<div className="w-full h-24 bg-gradient-to-r from-[#DB4F86] to-[#00A9AF] rounded-lg flex items-center justify-center mb-4">
		<div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
			<CheckIcon className="text-[#00A9AF] w-8 h-8" />
		</div>
	</div>
);

const formatPhoneNumber = (phoneNumber: string): string => {
	return phoneNumber.length === 10 && !phoneNumber.startsWith("+1") ? `+1${phoneNumber}` : phoneNumber;
};

const UserScheduleCall: React.FC<UserScheduleCallProps> = ({ preVerifiedPhone, userEmail, onClose }) => {
	const navigate = useNavigate();
	const { assignedSessionId, sessionId } = useParams();
	const { instageUser } = useAuth();

	const [isPhoneVerified, setIsPhoneVerified] = useState(!!preVerifiedPhone);
	const [isLoading, setIsLoading] = useState(!preVerifiedPhone);
	const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState(preVerifiedPhone || "");
	const [currentAssistant, setCurrentAssistant] = useState(assistants[0]);
	const [bookingSuccess, setBookingSuccess] = useState(false);
	const [assignedSession, setAssignedSession] = useState<AssignedSessionView | null>(null);
	const [assignment, setAssignment] = useState<AssignmentPayload | null>(null);
	const [confirmedBookingTime, setConfirmedBookingTime] = useState<string | null>(null);

	const { eventTypeId, slug, seatsPerTimeSlot } = getCalEventDetails();

	useEffect(() => {
		const storedAssistantId = localStorage.getItem("selectedAssistantId");
		if (storedAssistantId) {
			const assistant = assistants.find((a) => a.id === storedAssistantId);
			if (assistant) {
				setCurrentAssistant(assistant);
			}
		}
	}, []);

	useEffect(() => {
		const fetchAssignedSession = async () => {
			if (assignedSessionId) {
				try {
					const session = await AssignedSessionService.getViewByAssignedSessionId(assignedSessionId);
					setAssignedSession(session);

					if (session.assignmentId) {
						const sessionDates = await AssignmentService.getSessionDateInfo(session.assignmentId);
						setAssignment(new AssignmentPayload({ sessions: sessionDates.sessions }));
					}
				} catch (error) {
					console.error("Error fetching assigned session:", error);
				}
			}
		};

		fetchAssignedSession();
	}, [assignedSessionId]);

	useEffect(() => {
		const checkPhoneVerification = async () => {
			// Skip phone verification check if we have a pre-verified phone
			if (preVerifiedPhone) {
				setIsLoading(false);
				return;
			}

			try {
				const phoneNumber = await UserService.getPhoneNumber();
				if (phoneNumber && phoneNumber.isVerified) {
					setIsPhoneVerified(true);
					setVerifiedPhoneNumber(formatPhoneNumber(phoneNumber.number));
				}
			} catch (error) {
				console.error("Error checking phone verification:", error);
			} finally {
				setIsLoading(false);
			}
		};

		checkPhoneVerification();
	}, [preVerifiedPhone]);

	const handleVerificationComplete = (verifiedNumber: string) => {
		setIsPhoneVerified(true);
		setVerifiedPhoneNumber(formatPhoneNumber(verifiedNumber));
	};

	const handleCancel = () => {
		navigate(-1); // This will navigate back to the previous page
	};

	const handleBookingSuccess = (slotTime: string) => {
		setBookingSuccess(true);
		setConfirmedBookingTime(slotTime);
	};

	const getDateRange = () => {
		if (!assignedSession || !assignment || !assignment.sessions) {
			const maxDate = new Date();
			maxDate.setDate(maxDate.getDate() + 3); // Default to 3 days out
			return { minDate: new Date(), maxDate: maxDate };
		}

		const currentSessionIndex = assignment.sessions.findIndex((s) => {
			if (!s.date || !assignedSession.date) return false;
			return new Date(s.date).getTime() === assignedSession.date.getTime();
		});

		// Fallback to assignedSession dates if the current session is not found
		if (currentSessionIndex === -1) {
			console.warn("Current session not found in assignment sessions");
			return {
				minDate: new Date(assignedSession.startDate),
				maxDate: new Date(assignedSession.date)
			};
		}

		const maxDate = new Date(assignedSession.date);
		let minDate = new Date(assignedSession.startDate);

		if (currentSessionIndex > 0) {
			const prevSession = assignment.sessions[currentSessionIndex - 1];
			if (prevSession.date) {
				const previousSessionDate = new Date(prevSession.date);
				// Set minDate to one day after the previous session's due date
				previousSessionDate.setDate(previousSessionDate.getDate() + 1);
				minDate = previousSessionDate > minDate ? previousSessionDate : minDate;
			}
		}

		return { minDate, maxDate };
	};

	const { minDate, maxDate } = getDateRange();

	const bookingMetadata = {
		assignedSessionId: assignedSessionId || "",
		sessionId: sessionId || "",
		attendeeEmail: instageUser?.email || userEmail || "",
		provider: currentAssistant.voice_provider,
		voiceId: currentAssistant.voice_id,
		assistantName: currentAssistant.name
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<div className="container mx-auto py-8">
			<Card className="max-w-md mx-auto p-4">
				<CardContent className="space-y-4 p-0">
					{!isPhoneVerified ? (
						<PhoneVerification onVerificationComplete={handleVerificationComplete} onCancel={handleCancel} />
					) : bookingSuccess ? (
						<div>
							<SuccessMessage />
							<p className="text-center mb-2 text-[#101828] text-xl font-bold">Booking Successful!</p>
							<p className="text-center text-[#475467] mb-4">
								Your call is scheduled for:
								<br />
								<span className="font-semibold">{confirmedBookingTime}</span>
							</p>
							<Button className="w-full" onClick={preVerifiedPhone && onClose ? onClose : () => navigate("/user")}>
								{preVerifiedPhone ? "Close" : "Return to Dashboard"}
							</Button>
						</div>
					) : (
						<div>
							<p className="text-center mb-2 text-[#101828] text-xl font-bold">Select available date</p>
							<CalendarBooking
								eventTypeId={eventTypeId}
								eventTypeSlug={slug}
								seatsPerTimeSlot={seatsPerTimeSlot}
								onBookingSuccess={handleBookingSuccess}
								userEmail={instageUser?.email || userEmail || ""}
								userName={`${instageUser?.firstName || ""} ${instageUser?.lastName || ""}`}
								userPhone={verifiedPhoneNumber}
								metadata={bookingMetadata}
								minDate={minDate}
								maxDate={maxDate}
								hideEmailField={!!preVerifiedPhone && !!userEmail}
							/>
						</div>
					)}
				</CardContent>
			</Card>
		</div>
	);
};

export default UserScheduleCall;
