import React, { useEffect, useState } from "react";
import { Document, Packer, Paragraph, TextRun, Table, TableCell, TableRow, HeadingLevel, VerticalAlign, AlignmentType, BorderStyle } from "docx";
import { BaseTemplateProps } from "./BaseTemplate";
import { ResumeView } from "../../../types/ResumeView";
import { CoverLetterView } from "../../../types/CoverLetterView";
import { saveAs } from "file-saver";
import { ModernProfessionalTemplateHTML, ModernProfessionalTemplateDocX } from "./templates/ResumeTemplates/ModernProfessional";
import { ClassicTemplateHTML, ClassicTemplateDocX } from "./templates/ResumeTemplates/ClassicTemplate";
import { ClassicCLTemplateHTML, ClassicCLTemplateDocX } from "./templates/CoverLetterTemplates/ClassicCLTemplate";
import { TemplateType } from "./TemplateRegistry";
import { TemplateTypeCoverLetter } from "./TemplateRegistry";
import ResumeEditForm from "../ResumeEditForm";
import CoverLetterEditForm from "../CoverLetterEditForm";
import { ModernCLTemplateHTML, ModernCLTemplateDocX } from "./templates/CoverLetterTemplates/ModernCLTemplate";
// Docx documentation: https://docx.js.org/#/?id=welcome
interface DocxTemplateProps extends BaseTemplateProps {
	onDownload?: (downloadFn: () => Promise<void>) => void;
	TemplateType?: TemplateType;
	TemplateTypeCoverLetter?: TemplateTypeCoverLetter;
	setResume: (resume: ResumeView) => void;
	setCoverLetter: (coverLetter: CoverLetterView) => void;
	isResume: boolean;
}

const DocxTemplate: React.FC<DocxTemplateProps> = ({
	resume,
	style,
	onDownload,
	TemplateType,
	TemplateTypeCoverLetter,
	setResume,
	isResume,
	setCoverLetter,
	coverLetter
}) => {
	const [isEditFormOpen, setIsEditFormOpen] = useState(false);
	const [editingSection, setEditingSection] = useState("");
	const [editedData, setEditedData] = useState<any>(null);

	const handleSectionClick = (section: string) => {
		if (isResume) {
			if (!resume) {
				console.log("No resume found");
				return;
			}
			setEditingSection(section);
			setEditedData(resume[section as keyof ResumeView]);
			setIsEditFormOpen(true);
		} else {
			if (!coverLetter) {
				console.log("No cover letter found");
				return;
			}
			setEditingSection(section);
			setEditedData(coverLetter[section as keyof CoverLetterView]);
			setIsEditFormOpen(true);
		}
	};

	const downloadDocument = React.useCallback(async () => {
		let doc;
		console.log("style", style);
		if (!style) {
			console.log("No style found");
			return;
		}
		if (!resume) {
			console.log("No resume or cover letter found");
			return;
		}

		try {
			if (isResume) {
				if (TemplateType === "modern-professional") {
					doc = ModernProfessionalTemplateDocX(resume, style);
				} else if (TemplateType === "classic") {
					doc = ClassicTemplateDocX(resume, style);
				} else {
					return;
				}
			} else {
				if (!coverLetter) {
					return;
				}
				console.log("TemplateTypeCoverLetter", TemplateTypeCoverLetter);
				if (TemplateTypeCoverLetter === "classic-cl") {
					doc = ClassicCLTemplateDocX(coverLetter, style);
				} else if (TemplateTypeCoverLetter === "modern-cl") {
					doc = ModernCLTemplateDocX(coverLetter, style);
				} else {
					return;
				}
			}

			const blob = await Packer.toBlob(doc);
			let fileName = "Document.docx";
			if (isResume && resume) {
				fileName = `${resume.contact.firstName}_${resume.contact.lastName}_Resume.docx`;
			} else if (!isResume && coverLetter) {
				fileName = `${coverLetter.contact.firstName}_${coverLetter.contact.lastName}_CoverLetter.docx`;
			}
			saveAs(blob, fileName);
		} catch (error) {
			console.error("Error generating DOCX:", error);
		}
	}, [resume, TemplateType, style, coverLetter, isResume, TemplateTypeCoverLetter]);

	// Expose download function through prop
	useEffect(() => {
		if (onDownload) {
			onDownload(downloadDocument);
		}
	}, [onDownload, downloadDocument]);

	if (!style) {
		console.log("No style found");
		return null;
	}
	if (isResume) {
		if (!resume) {
			return null;
		}
		if (TemplateType === "modern-professional") {
			return (
				<>
					{ModernProfessionalTemplateHTML(resume, style, handleSectionClick, {
						editingSection,
						editedData,
						setEditedData,
						isEditFormOpen,
						setIsEditFormOpen,
						setResume
					})}
					{isEditFormOpen && resume && (
						<ResumeEditForm
							editingSection={editingSection}
							editedData={editedData}
							setEditedData={setEditedData}
							isOpen={isEditFormOpen}
							setIsOpen={setIsEditFormOpen}
							resumeId={resume.id}
							resume={resume}
							setResume={setResume}
						/>
					)}
				</>
			);
		} else if (TemplateType === "classic") {
			return (
				<>
					{ClassicTemplateHTML(resume, style, handleSectionClick, {
						editingSection,
						editedData,
						setEditedData,
						isEditFormOpen,
						setIsEditFormOpen,
						setResume
					})}
					{isEditFormOpen && resume && (
						<ResumeEditForm
							editingSection={editingSection}
							editedData={editedData}
							setEditedData={setEditedData}
							isOpen={isEditFormOpen}
							setIsOpen={setIsEditFormOpen}
							resumeId={resume.id}
							resume={resume}
							setResume={setResume}
						/>
					)}
				</>
			);
		}
	} else {
		if (!coverLetter) {
			return null;
		}

		if (TemplateTypeCoverLetter === "classic-cl") {
			return (
				<>
					{ClassicCLTemplateHTML(coverLetter, style, handleSectionClick, {
						editingSection,
						editedData,
						setEditedData,
						isEditFormOpen,
						setIsEditFormOpen,
						setCoverLetter
					})}
					{isEditFormOpen && coverLetter && (
						<CoverLetterEditForm
							editingSection={editingSection}
							editedData={editedData}
							setEditedData={setEditedData}
							isOpen={isEditFormOpen}
							setIsOpen={setIsEditFormOpen}
							resumeId={coverLetter.resumeId}
							coverLetter={coverLetter}
							setCoverLetter={setCoverLetter}
						/>
					)}
				</>
			);
		} else if (TemplateTypeCoverLetter === "modern-cl") {
			return (
				<>
					{ModernCLTemplateHTML(coverLetter, style, handleSectionClick, {
						editingSection,
						editedData,
						setEditedData,
						isEditFormOpen,
						setIsEditFormOpen,
						setCoverLetter
					})}
					{isEditFormOpen && coverLetter && (
						<CoverLetterEditForm
							editingSection={editingSection}
							editedData={editedData}
							setEditedData={setEditedData}
							isOpen={isEditFormOpen}
							setIsOpen={setIsEditFormOpen}
							resumeId={coverLetter.resumeId}
							coverLetter={coverLetter}
							setCoverLetter={setCoverLetter}
						/>
					)}
				</>
			);
		}
	}
	return null;
};

export default DocxTemplate;
