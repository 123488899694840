// src/contexts/AuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

import { useAuth as useFronteggAuth, useLoginWithRedirect } from "@frontegg/react";
import { useLocation, useNavigate } from "react-router-dom";
import TokenManager from "../services/TokenManager";
import { UserView } from "../types/UserView";
import { UserService } from "../services/UserService";
import useSocket from "../hooks/useSocket";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";

interface User {
	frontEggId: string;
	id: string;
	tenantId: string;
	clientId: string;
	email: string;
	name: string;
	roles: string[];
	mainRole: string;
}
type UserType = "frontegg" | "lti";

interface AuthContextType {
	isAuthenticated: boolean;
	user: User | null;
	instageUser: UserView | null;
	userId: string | null;
	login: (redirectUrl: string, searchParams: string) => void;
	ltiToken: string | null;
	logout: () => void;
	// Any other methods you need
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const TOKEN_STORAGE_KEY = "instageLtiToken";
	const { isAuthenticated: isFronteggAuthenticated, user: fronteggUser } = useFronteggAuth();
	const loginWithRedirect = () => {
		navigate("/account/login");
	};

	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isFronteggAuthenticated || false);
	const { status, emit } = useSocket({ url: process.env.REACT_APP_BACKEND_URL || "http://localhost:5002" });

	const [ltiToken, setLtiToken] = useState<string | null>(null);
	const [user, setUser] = useState<User | null>(null);
	const [instageUser, setInstageUser] = useState<UserView | null>(null);
	const [userId, setUserId] = useState<string | null>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const posthog = usePostHog();
	const backendUrl = process.env.REACT_APP_BACKEND_URL;

	useEffect(() => {
		const OnUserLogin = async (userType: UserType, user: User) => {
			console.log("OnUserLogin", user);
			setUser(user);

			let foundUser = null;
			if (userType === "frontegg") {
				setIsAuthenticated(true);
				console.log("fronteggUser", user.email, user.id, user.name);
				foundUser = await UserService.getUserByFronteggId(user.id);
				if (!foundUser) {
					const createdUser = await UserService.getOrCreateUser(
						user.email,
						user.name.split(" ")[0],
						user.name.split(" ")[1],
						user.tenantId
					);
					if (!createdUser) {
						console.error("Error creating new user");
						return null;
					}
					await UserService.updateUserFronteggIdByEmail(user.id, user.email);
					foundUser = createdUser;
				}
			} else if (userType === "lti") {
				console.log("ltiUser", user.email, user.id, user.name);
				const tokenPayload = TokenManager.getTokenPayload();
				console.log("tokenPayload", tokenPayload);
				foundUser = await UserService.getUserById();
				if (!foundUser) {
					console.error("Error Fetching lti user");
					throw new Error("Error user not created by lti");
				}
				setIsAuthenticated(true);
			}

			if (foundUser && foundUser.id) {
				setUserId(foundUser.id);
				setInstageUser(foundUser);
				console.log("instageUser", foundUser);
				await posthog?.identify(foundUser.id, {
					email: foundUser.email,
					role: user.mainRole,
					isReflectionApp: true
				});

				posthog.group("tenant", foundUser.clientName, {
					clientId: foundUser.clientId,
					tenantName: foundUser.clientName
				});
				// return foundUser;
			}
		};

		const loginWithLTI = async (ltikToken: string, assignmentId: string | null) => {
			try {
				const response = await axios.get(`${backendUrl}/issue-token`, { params: { ltikToken, assignmentId } });
				// const response = await axios.post("http://localhost:5002/issue-token", { litkToken });
				setLtiToken(ltikToken);
				console.log("response", response);
				const instageToken = response.data.token;
				TokenManager.setToken(instageToken);

				// console.log("TokenManager()", TokenManager.getToken());
				const tokenPayload = TokenManager.getTokenPayload();
				console.log("tokenPayload", tokenPayload);
				if (tokenPayload) {
					const ltiUser: User = {
						id: tokenPayload.id,
						frontEggId: tokenPayload.frontEggId,
						email: tokenPayload.email,
						name: tokenPayload.name,
						roles: tokenPayload.roles, // Adjust based on your token's payload
						mainRole: tokenPayload.mainRole,
						tenantId: tokenPayload.tenantId,
						clientId: tokenPayload.clientId
					};

					OnUserLogin("lti", ltiUser);
				} else {
					throw new Error("Invalid token payload");
				}
			} catch (error) {
				console.error("LTI login failed", error);
				setIsAuthenticated(false);
				TokenManager.clearToken();
				throw error;
			}
		};

		if (isFronteggAuthenticated && fronteggUser) {
			// Handle Frontegg user
			TokenManager.setToken(fronteggUser.accessToken);
			const tokenPayload = TokenManager.getTokenPayload();
			if (tokenPayload) {
				const userInfo: User = {
					id: fronteggUser.id,
					frontEggId: fronteggUser.id,
					email: fronteggUser.email,
					name: fronteggUser.name,
					roles: fronteggUser.roles.map((role) => role.key),
					mainRole: fronteggUser.roles[0].key,
					tenantId: tokenPayload.tenantId,
					clientId: tokenPayload.clientId
					// ...any other properties from payload
				};

				OnUserLogin("frontegg", userInfo);
			}
		} else {
			// localStorage.removeItem(TOKEN_STORAGE_KEY);
			// Check for LTI token in URL
			const queryParams = new URLSearchParams(location.search);
			const ltiToken = queryParams.get("ltik") || sessionStorage.getItem(TOKEN_STORAGE_KEY);
			const assignmentId = queryParams.get("resource");
			if (ltiToken) {
				sessionStorage.setItem(TOKEN_STORAGE_KEY, ltiToken);
				loginWithLTI(ltiToken, assignmentId)
					.then(() => {
						console.log("lti token found");
						// Remove the token from the URL after processing
						queryParams.delete("ltik");
						// queryParams.delete("resource");

						navigate({ search: queryParams.toString() });
					})
					.catch((error) => {
						console.error("Error logging in with LTI", error);
						setIsAuthenticated(false);
					});
			} else {
				console.log("not authenticated");
				setIsAuthenticated(false);
			}
		}
	}, [isFronteggAuthenticated, fronteggUser, location.search, navigate, posthog, backendUrl]);

	useEffect(() => {
		if (status === "connected" && userId) {
			console.log("status", status);

			emit("setUserId", { userId, userToken: TokenManager.getToken() }).catch((error) => {
				console.error("Error setting user ID:", error);
			});
		}
	}, [status, userId, emit]);

	const logout = () => {
		TokenManager.clearToken();
		sessionStorage.removeItem(TOKEN_STORAGE_KEY);
		setUser(null);
		setIsAuthenticated(false);
		// Add any other logout logic here
	};

	const login = (redirectUrl: string, searchParams: string) => {
		window.localStorage.setItem("FRONTEGG_AFTER_AUTH_REDIRECT_URL", `${location.pathname + location.search}`);
		loginWithRedirect();
		console.log("on log in", redirectUrl, searchParams);
	};

	return <AuthContext.Provider value={{ isAuthenticated, user, instageUser, userId, logout, login, ltiToken }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
