import React, { useEffect, useState } from "react";
import { ResumeView } from "../../types/ResumeView";
import { FeedbackNew, ResumeService } from "../../services/ResumeService";
import CustomTooltip from "../Utility/CustomTooltip";
import { CoverLetterView } from "../../types/CoverLetterView";
import { CoverLetterFeedbackNew, CoverLetterService } from "../../services/CoverLetterService";

const NewResumeFeedback = ({
	resume,
	setResume,
	coverLetter,
	setCoverLetter,
	isResume
}: {
	resume: ResumeView;
	setResume: (resume: ResumeView) => void;
	coverLetter: CoverLetterView;
	setCoverLetter: (coverLetter: CoverLetterView) => void;
	isResume: boolean;
}) => {
	const [isScoreGenerated, setIsScoreGenerated] = useState(false);
	const [feedback, setFeedback] = useState<FeedbackNew | null>(null);
	const [coverLetterFeedback, setCoverLetterFeedback] = useState<CoverLetterFeedbackNew | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [expandedSection, setExpandedSection] = useState<string | null>(null);
	const [shouldGenerateFeedback, setShouldGenerateFeedback] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	useEffect(() => {
		if (isResume) {
			console.log("resume test: ", resume);
			if (resume.feedbackNew.overall.score > 0) {
				setFeedback(resume.feedbackNew);
				setIsScoreGenerated(true);
				setIsFirstLoad(false);
			} else {
				setShouldGenerateFeedback(true);
				if (isFirstLoad) {
					generateFeedback();
					setIsFirstLoad(false);
				}
			}
		}
	}, [resume]);

	useEffect(() => {
		if (!isResume) {
			console.log("coverLetter test: ", coverLetter);
			if (coverLetter?.feedbackNew?.overall?.score > 0) {
				setCoverLetterFeedback(coverLetter.feedbackNew);
				setIsScoreGenerated(true);
				setIsFirstLoad(false);
				setShouldGenerateFeedback(false);
			} else {
				setShouldGenerateFeedback(true);
				if (isFirstLoad) {
					generateFeedbackCoverLetter();
					setIsFirstLoad(false);
				}
			}
		}
	}, [coverLetter, isResume]);

	const generateFeedback = async () => {
		setIsLoading(true);
		try {
			const feedback = await ResumeService.generateFeedbackNew(resume.id);
			console.log("feedback: ", feedback);
			setFeedback(feedback);
			setIsScoreGenerated(true);
			setShouldGenerateFeedback(false);
			// Update the parent's resume state with the new feedback
			setResume({
				...resume,
				feedbackNew: feedback
			});
		} finally {
			setIsLoading(false);
		}
	};

	const generateFeedbackCoverLetter = async () => {
		setIsLoading(true);
		try {
			const feedback = await CoverLetterService.generateFeedbackNew(coverLetter.id);
			console.log("feedback: ", feedback);
			setCoverLetterFeedback(feedback);
			setIsScoreGenerated(true);
			setShouldGenerateFeedback(false);
			// Update the parent's resume state with the new feedback
			setCoverLetter({
				...coverLetter,
				feedbackNew: feedback
			});
		} finally {
			setIsLoading(false);
		}
	};

	const CircularProgressBar = ({ score }: { score: number }) => {
		return (
			<div className="w-60 h-60 relative">
				<svg className="w-full h-full" viewBox="0 0 100 100">
					<circle className="text-gray-200" strokeWidth="8" stroke="currentColor" fill="transparent" r="42" cx="50" cy="50" />
					<circle
						className="text-[#00a9af]"
						strokeWidth="8"
						strokeLinecap="round"
						stroke="currentColor"
						fill="transparent"
						r="42"
						cx="50"
						cy="50"
						strokeDasharray={`${score * 2.64}, 264`}
						transform="rotate(-90 50 50)"
					/>
				</svg>
				<div className="absolute inset-0 flex flex-col items-center justify-center">
					<span className="text-3xl font-bold text-[#00a9af]">{Math.round(score)}%</span>
					<span className="text-sm text-gray-600">Match Score</span>
				</div>
			</div>
		);
	};

	const HorizontalProgressBar = ({
		score,
		section,
		infoText,
		isResume
	}: {
		score: number;
		section: string;
		infoText: string;
		isResume: boolean;
	}) => {
		const isExpanded = expandedSection === section;
		// Calculate color interpolation between red, yellow, and teal based on score
		const getBarColor = (score: number) => {
			const red = "#FF0000";
			const yellow = "#FFD700";
			const teal = "#00a9af";

			if (score <= 50) {
				// Interpolate between red and yellow for scores 0-50
				return `color-mix(in srgb, ${red} ${100 - score * 2}%, ${yellow} ${score * 2}%)`;
			} else {
				// Interpolate between yellow and teal for scores 51-100
				return `color-mix(in srgb, ${yellow} ${100 - (score - 50) * 2}%, ${teal} ${(score - 50) * 2}%)`;
			}
		};

		const handleClick = () => {
			if (isExpanded) {
				setExpandedSection(null);
			} else {
				setExpandedSection(section);
			}
		};

		const getSectionFeedback = (section: string) => {
			switch (section.toLowerCase()) {
				case "summary":
					return {
						feedback: feedback?.summary.feedback
					};
				case "experience":
					return {
						feedback: feedback?.experience.feedback
					};
				case "education":
					return {
						feedback: feedback?.education.feedback
					};
				case "skills":
					return {
						feedback: feedback?.skills.feedback
					};
				case "projects":
					return {
						feedback: feedback?.projects.feedback
					};
				case "extra curricular":
					return {
						feedback: feedback?.extraCurricular.feedback
					};
				default:
					return { feedback: "" };
			}
		};

		const getSectionFeedbackCoverLetter = (section: string) => {
			switch (section.toLowerCase()) {
				case "introduction":
					return {
						feedback: coverLetterFeedback?.introduction.feedback
					};
				case "body":
					return {
						feedback: coverLetterFeedback?.body.feedback
					};
				case "closing":
					return {
						feedback: coverLetterFeedback?.closing.feedback
					};
				default:
					return { feedback: "" };
			}
		};

		const sectionFeedback = getSectionFeedback(section);
		const sectionFeedbackCoverLetter = getSectionFeedbackCoverLetter(section);

		return (
			<div className="mb-4">
				<div className="flex items-start">
					<div className="w-7 h-7 text-gray-500 flex items-center justify-center mr-2 mt-1">
						<CustomTooltip content={infoText} />
					</div>
					<div className="flex-1">
						<div
							className="flex items-center cursor-pointer hover:bg-[#e6f7f7] rounded-lg p-2 transition-colors duration-200"
							onClick={handleClick}
						>
							<p className="text-sm font-medium text-gray-600 w-60">{section}</p>
							<div className="w-full bg-gray-200 rounded-full h-2.5">
								<div
									className="h-2.5 rounded-full"
									style={{
										width: `${score}%`,
										backgroundColor: getBarColor(score)
									}}
								></div>
							</div>
							<span className="ml-2 text-sm font-medium text-gray-600 w-20">{score}%</span>
							<svg
								className={`w-5 h-5 ml-2 transform transition-transform duration-200 ${isExpanded ? "rotate-180" : ""}`}
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
							</svg>
						</div>
						{isExpanded && (
							<div className="mt-2 px-4 py-4 bg-gray-50 rounded-lg">
								<div className="mb-3">
									<h4 className="text-sm font-semibold text-gray-700 mb-1">Feedback</h4>
									<p className="text-sm text-gray-600">
										{isResume ? sectionFeedback.feedback : sectionFeedbackCoverLetter.feedback}
									</p>
								</div>
								{/* <div>
									<h4 className="text-sm font-semibold text-gray-700 mb-1">Areas to Improve</h4>
									<p className="text-sm text-gray-600">{sectionFeedback.improve}</p>
								</div> */}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	const ScoreBreakdownResume = () => {
		return (
			<div className="flex gap-6 mt-4 mb-4 w-full">
				<div className="flex-1 flex flex-col gap-3 bg-white p-4 rounded-md">
					{isScoreGenerated ? (
						<>
							<div className="flex flex-row items-start gap-8">
								<div className="flex flex-col items-center mt-8 sticky top-8">
									<CircularProgressBar score={feedback?.overall.score ?? 0} />
								</div>
								<div className="flex-1">
									<h3 className="text-lg font-bold">Score Breakdown</h3>
									<p className="text-sm text-gray-500 mb-4">
										The score above is a culmination of each section score below. You can click on one to expand it.
									</p>
									<HorizontalProgressBar
										isResume={true}
										score={feedback?.summary.score ?? 0}
										section="Summary"
										infoText="Score Weight - 20% - Evaluates the clarity, conciseness, and impact of your professional summary or objective statement."
									/>
									<HorizontalProgressBar
										isResume={true}
										score={feedback?.experience.score ?? 0}
										section="Experience"
										infoText="Score Weight - 35% - Assesses your work history, including the use of action verbs, quantifiable achievements, and relevant responsibilities."
									/>
									<HorizontalProgressBar
										isResume={true}
										score={feedback?.education.score ?? 0}
										section="Education"
										infoText="Score Weight - 10% - Reviews your academic credentials, including degrees, certifications, relevant coursework, and academic achievements."
									/>
									<HorizontalProgressBar
										isResume={true}
										score={feedback?.skills.score ?? 0}
										section="Skills"
										infoText="Score Weight - 20% - Evaluates your technical, soft, and industry-specific skills, including their relevance and organization."
									/>
									<HorizontalProgressBar
										isResume={true}
										score={feedback?.projects.score ?? 0}
										section="Projects"
										infoText="Score Weight - 7.5% - Analyzes your project descriptions, including technical details, methodologies used, and demonstrated outcomes."
									/>
									<HorizontalProgressBar
										isResume={true}
										score={feedback?.extraCurricular.score ?? 0}
										section="Extra Curricular"
										infoText="Score Weight - 7.5% - Reviews volunteer work, leadership roles, and other activities that demonstrate additional capabilities and character."
									/>
								</div>
							</div>
						</>
					) : (
						<div className="flex flex-col items-center justify-center h-full">
							<h3 className="text-lg font-bold">Score Breakdown</h3>
							<p className="text-sm text-gray-500">This is a breakdown of the score for the resume.</p>
						</div>
					)}
					<button
						className="bg-[#00a9af] text-white px-4 py-2 rounded-md flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed mt-auto"
						onClick={generateFeedback}
						disabled={isLoading || !shouldGenerateFeedback}
					>
						{isLoading ? (
							<>
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
								<span>Generating...</span>
							</>
						) : (
							<>Generate Feedback</>
						)}
					</button>
				</div>
				<div className="w-70 flex flex-col gap-6">
					<div className="flex flex-col gap-3 bg-white p-4 rounded-md">
						<h3 className="text-lg font-bold">Overall Positive Feedback</h3>
						<p className="text-md text-gray-500">{feedback?.overall.positiveFeedback}</p>
					</div>
					<div className="flex flex-col gap-3 bg-white p-4 rounded-md">
						<h3 className="text-lg font-bold">Overall Areas to Improve</h3>
						<p className="text-md text-gray-500">{feedback?.overall.areasToImprove}</p>
					</div>
				</div>
			</div>
		);
	};

	const ScoreBreakdownCoverLetter = () => {
		console.log("coverLetterFeedback: ", coverLetterFeedback);
		return (
			<div className="flex gap-6 mt-4 mb-4 w-full">
				<div className="flex-1 flex flex-col gap-3 bg-white p-4 rounded-md">
					{isScoreGenerated && coverLetterFeedback ? (
						<>
							<div className="flex flex-row items-start gap-8">
								<div className="flex flex-col items-center mt-8 sticky top-8">
									<CircularProgressBar score={coverLetterFeedback.overall?.score ?? 0} />
								</div>
								<div className="flex-1">
									<h3 className="text-lg font-bold">Score Breakdown</h3>
									<p className="text-sm text-gray-500 mb-4">
										The score above is a culmination of each section score below. You can click on one to expand it.
									</p>
									<HorizontalProgressBar
										isResume={false}
										score={coverLetterFeedback.introduction?.score ?? 0}
										section="Introduction"
										infoText="Score Weight - 25% - Evaluates the clarity, conciseness, and impact of your introduction."
									/>
									<HorizontalProgressBar
										isResume={false}
										score={coverLetterFeedback.body?.score ?? 0}
										section="Body"
										infoText="Score Weight - 50% - Evaluates how well you have presented yourself in alignment with the job description."
									/>
									<HorizontalProgressBar
										isResume={false}
										score={coverLetterFeedback.closing?.score ?? 0}
										section="Closing"
										infoText="Score Weight - 25% - Evaluates the closing of your cover letter and your ability to leave a lasting impression."
									/>
								</div>
							</div>
						</>
					) : (
						<div className="flex flex-col items-center justify-center h-full">
							<h3 className="text-lg font-bold">Score Breakdown</h3>
							<p className="text-sm text-gray-500">This is a breakdown of the score for the cover letter.</p>
						</div>
					)}
					<button
						className="bg-[#00a9af] text-white px-4 py-2 rounded-md flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed mt-auto"
						onClick={generateFeedbackCoverLetter}
						disabled={isLoading || !shouldGenerateFeedback}
					>
						{isLoading ? (
							<>
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
								<span>Generating...</span>
							</>
						) : (
							<>Generate Feedback</>
						)}
					</button>
				</div>
				<div className="w-70 flex flex-col gap-6">
					<div className="flex flex-col gap-3 bg-white p-4 rounded-md">
						<h3 className="text-lg font-bold">Overall Positive Feedback</h3>
						<p className="text-md text-gray-500">{coverLetterFeedback?.overall?.positiveFeedback}</p>
					</div>
					<div className="flex flex-col gap-3 bg-white p-4 rounded-md">
						<h3 className="text-lg font-bold">Overall Areas to Improve</h3>
						<p className="text-md text-gray-500">{coverLetterFeedback?.overall?.areasToImprove}</p>
					</div>
				</div>
			</div>
		);
	};

	return <div>{isResume ? <ScoreBreakdownResume /> : <ScoreBreakdownCoverLetter />}</div>;
};

export default NewResumeFeedback;
