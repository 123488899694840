import React, { useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { ProfileService } from "../../services/ProfileService";
import { useNavigate } from "react-router-dom";
import { ProfileView } from "../../types/ProfileView";
import ProfileProgressBar from "./ProfileProgressBar";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Card, CardContent } from "../shadcn-ui/Card";

interface ProfileButtonProps {
	userId: string;
	profile: ProfileView | null;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ userId, profile }) => {
	const navigate = useNavigate();
	const fetchOrCreateProfile = async () => {
		const profile = await ProfileService.fetchOrCreateProfile(userId);

		if (!profile) {
			return;
		}
		console.log(profile);

		navigate(`/user/resume-assist/profile/${profile.id}/user/${profile.userId}`);
	};

	// useEffect(() => {
	// 	if (profile) {
	// 		console.log("ProfileButton", "Profile fetched", profile);
	// 	}
	// }, [profile]);

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
			hour: "numeric",
			minute: "2-digit"
		});
	};

	if (profile) {
		return (
			<Card className="mb-4 shadow-sm hover:shadow-lg transition-shadow duration-200">
				<CardContent className="p-4">
					<div className="flex flex-col h-full">
						<div className="flex items-start justify-between mb-3">
							<div className="flex items-center gap-3 relative w-full">
								<div className="flex items-center gap-2">
									<FaUserCircle className="w-8 h-8 text-[#303d7c]" />
									<div>
										<h2 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-1">Profile</h2>
										<p className="text-sm text-gray-600">
											Last modified: {new Date(profile.updatedAt).toISOString().split("T")[0]}
										</p>
									</div>
								</div>
								<div className="ml-auto">
									<button onClick={fetchOrCreateProfile} className="p-2 rounded-full hover:bg-gray-100 transition-colors">
										<PencilSquareIcon className="w-5 h-5 text-gray-600" />
									</button>
								</div>
							</div>
							{profile.isPublic && (
								<span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
									Public
								</span>
							)}
						</div>
						<div className="mt-4">
							<ProfileProgressBar profile={profile} />
						</div>
					</div>
				</CardContent>
			</Card>
		);
	}

	return (
		<Card className="mb-4 shadow-sm">
			<CardContent className="p-6">
				<div className="flex flex-col h-full">
					<div className="flex items-start justify-between mb-3">
						<div className="flex items-center gap-3 relative w-full">
							<div className="flex items-center gap-2">
								<FaUserCircle className="w-8 h-8 text-[#303d7c]" />
								<div>
									<h2 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-1">Profile</h2>
									<p className="text-sm text-gray-600">Create your profile to get started</p>
								</div>
							</div>
							<div className="ml-auto">
								<button onClick={fetchOrCreateProfile} className="p-2 rounded-full hover:bg-gray-100 transition-colors">
									<PencilSquareIcon className="w-5 h-5 text-gray-600" />
								</button>
							</div>
						</div>
					</div>
					<div className="mt-4">
						<ProfileProgressBar profile={profile} />
					</div>
				</div>
			</CardContent>
		</Card>
	);

	// return (
	// 	<button
	// 		onClick={fetchOrCreateProfile}
	// 		className="w-full h-[150px] rounded-[12px] border-2 border-dashed border-[#00a9af]
	//   flex flex-col gap-3 items-center justify-center transition-all duration-200
	//   hover:border-[#00a9af] hover:bg-teal-50 mb-10 group"
	// 	>
	// 		<FaUserCircle className="w-8 h-8 text-[#00a9af] group-hover:text-[#008c91] transition-colors" />
	// 		<span className="text-gray-700 font-medium group-hover:text-[#008c91] transition-colors">Resume Profile</span>
	// 	</button>
	// );
};

export default ProfileButton;
