import React from "react";
import { Document, Packer, Paragraph, TextRun, AlignmentType, BorderStyle, Table, TableRow, TableCell, WidthType, VerticalAlign } from "docx";
import { CoverLetterView } from "../../../../../types/CoverLetterView";
import { ResumeStyleProps } from "../../../../UserPages/ResumeAssist";

export const ClassicCLTemplateHTML = (
	coverLetter: CoverLetterView,
	style: ResumeStyleProps,
	handleSectionClick: (section: string) => void,
	{
		editingSection,
		editedData,
		setEditedData,
		isEditFormOpen,
		setIsEditFormOpen,
		setCoverLetter
	}: {
		editingSection: string;
		editedData: any;
		setEditedData: (data: any) => void;
		isEditFormOpen: boolean;
		setIsEditFormOpen: (open: boolean) => void;
		setCoverLetter: (coverLetter: CoverLetterView) => void;
	}
) => {
	const singleColumnStyles = (
		<style>{`
            .cover-letter-section {
                border: 1px solid transparent;
                border-radius: 8px;
                padding: 8px;
                position: relative;
                transition: all 0.2s ease;
            }
            .cover-letter-section:hover {
                background-color: rgba(0, 169, 175, 0.1);
                border: 1px solid #00a9af;
                cursor: pointer;
            }
            .cover-letter-content {
                max-width: 100%;
                margin: 0 auto;
                padding: 80px;
                background: white;
            }
            .header-section {
                border: 1px solid transparent;
				border-radius: 8px;
                padding: 8px;
                margin-bottom: 20px;
                position: relative;
                
                padding-bottom: 24px;
            }
            .header-section:hover {
                background-color: rgba(0, 169, 175, 0.1);
                border: 1px solid #00a9af;
                cursor: pointer;
                
            }
            .name {
                font-size: 48px;
                font-weight: 800;
                margin-bottom: 16px;
                color: #1A202C;
                letter-spacing: -0.02em;
                line-height: 1.1;
            }
            .contact-info-row {
                display: flex;
                gap: 16px;
                align-items: center;
                color: #1A202C;
                font-size: 16px;
            }
            .contact-info {
                margin: 0;
                line-height: 1.5;
            }
            .contact-separator {
                color: #1A202C;
            }
            .job-title {
                font-size: 16px;
                color: #4A5568;
                margin-bottom: 8px;
            }
            .letter-body-section {
                margin-bottom: 16px;
                padding: 8px;
                border: 1px solid transparent;
                border-radius: 8px;
                transition: all 0.2s ease;
            }
            .letter-body-section:hover {
                background-color: rgba(0, 169, 175, 0.1);
                border: 1px solid #00a9af;
                cursor: pointer;
            }
        `}</style>
	);

	return (
		<>
			{singleColumnStyles}
			<div
				style={{
					fontFamily: "Inter, -apple-system, system-ui, sans-serif",
					padding: "0",
					margin: "0 auto",
					fontSize: `${14 * style.fontSize}px`,
					lineHeight: 1.4,
					color: "#1A202C",
					maxWidth: "100%",
					background: "#fff"
				}}
			>
				<div className="cover-letter-content">
					{/* Header Section */}
					<div className="header-section" onClick={() => handleSectionClick("contact")}>
						<h1 className="name">
							{coverLetter.contact.firstName} {coverLetter.contact.lastName}
						</h1>

						<div className="contact-info-row">
							<span className="contact-info">{coverLetter.contact.phone}</span>
							<span className="contact-separator">•</span>
							<span className="contact-info">{coverLetter.contact.email}</span>
							<span className="contact-separator">•</span>
							<span className="contact-info">{coverLetter.contact.address}</span>
						</div>
						<div style={{ borderTop: "1px solid #E2E8F0", margin: "16px 0" }}></div>
					</div>

					{/* Recipient's Information */}
					<div className="cover-letter-section" onClick={() => handleSectionClick("companyInfo")} style={{ marginBottom: "16px" }}>
						<div>
							<p style={{ marginBottom: "2px", color: "#4A5568" }}>{coverLetter.companyInfo.companyHiringManagerName}</p>
							<p style={{ marginBottom: "2px", color: "#4A5568" }}>{coverLetter.companyInfo.companyName}</p>
							<p style={{ color: "#4A5568" }}>{coverLetter.companyInfo.companyAddress}</p>
						</div>
					</div>

					{/* Salutation */}
					<div style={{ marginBottom: "16px", color: "#2D3748", paddingLeft: "8px" }}>
						<p>Dear {coverLetter.companyInfo.companyHiringManagerName},</p>
					</div>

					{/* Letter Body */}
					<div style={{ marginBottom: "24px" }}>
						{/* Introduction */}
						<div className="letter-body-section" onClick={() => handleSectionClick("introduction")}>
							<p style={{ color: "#2D3748", lineHeight: "1.5", textAlign: "justify" }}>{coverLetter.coverLetterData.introduction}</p>
						</div>

						{/* Main Body */}
						<div className="letter-body-section" onClick={() => handleSectionClick("body")}>
							<p style={{ color: "#2D3748", lineHeight: "1.5", textAlign: "justify" }}>{coverLetter.coverLetterData.body}</p>
						</div>

						{/* Closing */}
						<div className="letter-body-section" onClick={() => handleSectionClick("closing")}>
							<p style={{ color: "#2D3748", lineHeight: "1.5", textAlign: "justify" }}>{coverLetter.coverLetterData.closing}</p>
						</div>
					</div>

					{/* Signature */}
					<div style={{ marginTop: "24px", paddingLeft: "8px" }}>
						<p style={{ marginBottom: "24px", color: "#2D3748" }}>Sincerely,</p>
						<p style={{ color: "#2D3748", fontWeight: "500" }}>
							{coverLetter.contact.firstName} {coverLetter.contact.lastName}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export const ClassicCLTemplateDocX = (coverLetter: CoverLetterView, style: ResumeStyleProps) => {
	return new Document({
		styles: {
			paragraphStyles: [
				{
					id: "Normal",
					name: "Normal",
					run: {
						font: "Calibri",
						size: 24
					},
					paragraph: {
						spacing: {
							after: 80,
							line: 300
						}
					}
				}
			]
		},
		sections: [
			{
				properties: {
					page: {
						margin: {
							top: 720,
							right: 720,
							bottom: 720,
							left: 720
						}
					}
				},
				children: [
					// Sender's Information Header
					new Paragraph({
						children: [
							new TextRun({
								text: `${coverLetter.contact.firstName} ${coverLetter.contact.lastName}`,
								size: 52,
								bold: true,
								font: "Inter"
							})
						],
						spacing: { after: 240 }
					}),
					// Contact info in horizontal layout
					new Paragraph({
						children: [
							new TextRun({
								text: coverLetter.contact.phone,
								size: 22,
								font: "Inter"
							}),
							new TextRun({
								text: "  •  ",
								size: 22,
								font: "Inter"
							}),
							new TextRun({
								text: coverLetter.contact.email,
								size: 22,
								font: "Inter"
							}),
							new TextRun({
								text: "  •  ",
								size: 22,
								font: "Inter"
							}),
							new TextRun({
								text: coverLetter.contact.address,
								size: 22,
								font: "Inter"
							})
						],
						alignment: AlignmentType.LEFT,
						spacing: { after: 40 }
					}),
					// Horizontal line
					new Paragraph({
						children: [
							new TextRun({
								text: ""
							})
						],
						border: {
							bottom: {
								color: "E2E8F0",
								space: 1,
								style: BorderStyle.SINGLE,
								size: 6
							}
						},
						spacing: { after: 480 }
					}),

					// Recipient's Information
					new Paragraph({
						children: [
							new TextRun({
								text: coverLetter.companyInfo.companyHiringManagerName,
								size: (24 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 80, before: 80 }
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: coverLetter.companyInfo.companyName,
								size: (24 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 80 }
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: coverLetter.companyInfo.companyAddress,
								size: (24 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 240 }
					}),

					// Salutation
					new Paragraph({
						children: [
							new TextRun({
								text: `Dear ${coverLetter.companyInfo.companyHiringManagerName},`,
								size: (24 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 240 }
					}),

					// Introduction
					new Paragraph({
						children: [
							new TextRun({
								text: coverLetter.coverLetterData.introduction,
								size: (24 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 240 },
						alignment: AlignmentType.JUSTIFIED
					}),

					// Body
					new Paragraph({
						children: [
							new TextRun({
								text: coverLetter.coverLetterData.body,
								size: (24 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 240 },
						alignment: AlignmentType.JUSTIFIED
					}),

					// Closing
					new Paragraph({
						children: [
							new TextRun({
								text: coverLetter.coverLetterData.closing,
								size: (24 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 360 },
						alignment: AlignmentType.JUSTIFIED
					}),

					// Signature
					new Paragraph({
						children: [
							new TextRun({
								text: "Sincerely,",
								size: (24 / 2) * style.fontSize * 2,
								font: "Calibri"
							})
						],
						spacing: { after: 360 }
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: `${coverLetter.contact.firstName} ${coverLetter.contact.lastName}`,
								size: (24 / 2) * style.fontSize * 2,
								bold: true,
								font: "Calibri"
							})
						]
					})
				]
			}
		]
	});
};
