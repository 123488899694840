import React, { useState } from "react";
import { CoverLetterView, Contact, CompanyInfo, CoverLetterData } from "../../types/CoverLetterView";
import { Input } from "../shadcn-ui/Input";
import { Textarea } from "../shadcn-ui/Textarea";
import { Button } from "../shadcn-ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../shadcn-ui/Dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../shadcn-ui/Tabs";
import { CoverLetterService } from "../../services/CoverLetterService";
import { RewriteType } from "../../services/ResumeService";
import CustomTooltip from "../Utility/CustomTooltip";

interface CoverLetterEditFormProps {
	editingSection: string;
	editedData: any;
	setEditedData: (data: any) => void;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	resumeId: string;
	coverLetter: CoverLetterView;
	setCoverLetter: (coverLetter: CoverLetterView) => void;
}

const CoverLetterEditForm: React.FC<CoverLetterEditFormProps> = ({
	editingSection,
	editedData,
	setEditedData,
	isOpen,
	setIsOpen,
	resumeId,
	coverLetter,
	setCoverLetter
}) => {
	const [activeTab, setActiveTab] = useState("edit");
	const [isRewriting, setIsRewriting] = useState<"professional" | "concise" | "detailed" | null>(null);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const [showValidationDialog, setShowValidationDialog] = useState(false);

	// Add validation patterns
	const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
	const URL_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

	const renderContent = () => {
		switch (editingSection) {
			case "contact":
				if (!editedData || typeof editedData !== "object") {
					setEditedData({
						firstName: "",
						lastName: "",
						email: "",
						phone: "",
						address: "",
						linkedinURL: "",
						website: ""
					});
					return null;
				}

				return (
					<div className="space-y-4">
						<div className="grid grid-cols-2 gap-4">
							<div>
								<label className="text-sm font-medium">First Name</label>
								<Input
									value={editedData?.firstName || ""}
									onChange={(e) => setEditedData({ ...editedData, firstName: e.target.value })}
									placeholder="John"
									className="mt-1"
								/>
							</div>
							<div>
								<label className="text-sm font-medium">Last Name</label>
								<Input
									value={editedData?.lastName || ""}
									onChange={(e) => setEditedData({ ...editedData, lastName: e.target.value })}
									placeholder="Doe"
									className="mt-1"
								/>
							</div>
						</div>
						<div>
							<label className="text-sm font-medium">Email</label>
							<Input
								value={editedData?.email || ""}
								onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
								placeholder="john.doe@gmail.com"
								type="email"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">Phone</label>
							<Input
								value={editedData?.phone || ""}
								onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
								placeholder="(123) 456-7890"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">Address</label>
							<Input
								value={editedData?.address || ""}
								onChange={(e) => setEditedData({ ...editedData, address: e.target.value })}
								placeholder="123 Main St, City, State 12345"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">LinkedIn URL</label>
							<Input
								value={editedData?.linkedinURL || ""}
								onChange={(e) => setEditedData({ ...editedData, linkedinURL: e.target.value })}
								placeholder="linkedin.com/in/johndoe"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">Website</label>
							<Input
								value={editedData?.website || ""}
								onChange={(e) => setEditedData({ ...editedData, website: e.target.value })}
								placeholder="www.johndoe.com"
								className="mt-1"
							/>
						</div>
					</div>
				);

			case "companyInfo":
				if (!editedData || typeof editedData !== "object") {
					setEditedData({
						companyName: "",
						companyAddress: "",
						companyHiringManagerName: ""
					});
					return null;
				}

				return (
					<div className="space-y-4">
						<div>
							<label className="text-sm font-medium">Company Name</label>
							<Input
								value={editedData?.companyName || ""}
								onChange={(e) => setEditedData({ ...editedData, companyName: e.target.value })}
								placeholder="Acme Corporation"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">Company Address</label>
							<Input
								value={editedData?.companyAddress || ""}
								onChange={(e) => setEditedData({ ...editedData, companyAddress: e.target.value })}
								placeholder="456 Business Ave, City, State 12345"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">Hiring Manager Name</label>
							<Input
								value={editedData?.companyHiringManagerName || ""}
								onChange={(e) => setEditedData({ ...editedData, companyHiringManagerName: e.target.value })}
								placeholder="Mr. John Smith"
								className="mt-1"
							/>
						</div>
					</div>
				);

			case "introduction":
				if (!editedData || typeof editedData !== "object") {
					setEditedData({
						introduction: coverLetter.coverLetterData?.introduction || ""
					});
					return null;
				}

				return (
					<div className="space-y-4">
						<div>
							<label className="text-sm font-medium">Introduction</label>
							<Textarea
								value={editedData?.introduction || ""}
								onChange={(e) => setEditedData({ ...editedData, introduction: e.target.value })}
								placeholder="Write your introduction paragraph here..."
								className="mt-1"
								rows={4}
							/>
						</div>
					</div>
				);
			case "body":
				if (!editedData || typeof editedData !== "object") {
					setEditedData({
						body: coverLetter.coverLetterData?.body || ""
					});
					return null;
				}

				return (
					<div className="space-y-4">
						<div>
							<label className="text-sm font-medium">Body</label>
							<Textarea
								value={editedData?.body || ""}
								onChange={(e) => setEditedData({ ...editedData, body: e.target.value })}
								placeholder="Write your body paragraph here..."
								className="mt-1"
								rows={4}
							/>
						</div>
					</div>
				);

			case "closing":
				if (!editedData || typeof editedData !== "object") {
					setEditedData({
						closing: coverLetter.coverLetterData?.closing || ""
					});
					return null;
				}

				return (
					<div className="space-y-4">
						<div>
							<label className="text-sm font-medium">Closing</label>
							<Textarea
								value={editedData?.closing || ""}
								onChange={(e) => setEditedData({ ...editedData, closing: e.target.value })}
								placeholder="Write your closing paragraph here..."
								className="mt-1"
								rows={4}
							/>
						</div>
					</div>
				);

			default:
				return null;
		}
	};

	const validateRequiredFields = () => {
		const errors: string[] = [];

		if (editingSection === "contact" && typeof editedData === "object") {
			const { firstName, lastName, email, phone, linkedinURL, website } = editedData;
			if (!firstName) errors.push("First Name is required");
			if (!lastName) errors.push("Last Name is required");
			if (!email) {
				errors.push("Email is required");
			} else if (!EMAIL_REGEX.test(email)) {
				errors.push("Email format is invalid");
			}
			if (!phone) {
				errors.push("Phone is required");
			} else if (!PHONE_REGEX.test(phone)) {
				errors.push("Phone format is invalid (e.g., (123) 456-7890)");
			}
			if (linkedinURL && !URL_REGEX.test(linkedinURL)) {
				errors.push("LinkedIn URL format is invalid");
			}
			if (website && !URL_REGEX.test(website)) {
				errors.push("Website URL format is invalid");
			}
		}

		if (editingSection === "companyInfo" && typeof editedData === "object") {
			const { companyName, companyAddress, companyHiringManagerName } = editedData;
			// Removing required field validation for company info
		}

		if (editingSection === "introduction" && typeof editedData === "object") {
			const { introduction } = editedData;
			if (!introduction) errors.push("Introduction is required");
		}

		if (editingSection === "body" && typeof editedData === "object") {
			const { body } = editedData;
			if (!body) errors.push("Body is required");
		}

		if (editingSection === "closing" && typeof editedData === "object") {
			const { closing } = editedData;
			if (!closing) errors.push("Closing is required");
		}

		setValidationErrors(errors);
		return errors.length === 0;
	};

	const handleConfirm = async () => {
		if (!validateRequiredFields()) {
			setShowValidationDialog(true);
			return;
		}

		try {
			const updatedCoverLetter = { ...coverLetter };
			if (editingSection === "contact") {
				updatedCoverLetter.contact = editedData;
			} else if (editingSection === "companyInfo") {
				updatedCoverLetter.companyInfo = editedData;
			} else if (editingSection === "introduction") {
				updatedCoverLetter.coverLetterData.introduction = editedData.introduction;
			} else if (editingSection === "body") {
				updatedCoverLetter.coverLetterData.body = editedData.body;
			} else if (editingSection === "closing") {
				updatedCoverLetter.coverLetterData.closing = editedData.closing;
			}

			// Reset the feedback when content is updated
			updatedCoverLetter.feedbackNew = {
				...updatedCoverLetter.feedbackNew,
				overall: { ...updatedCoverLetter.feedbackNew.overall, score: 0 }
			};

			await CoverLetterService.updateCoverLetter(updatedCoverLetter.id, updatedCoverLetter);
			setCoverLetter(updatedCoverLetter);
			setIsOpen(false);
		} catch (error) {
			console.error("Error saving cover letter:", error);
		}
	};

	const formatContent = (section: string, data: any) => {
		if (section === "contact" && typeof data === "object") {
			return (
				<div className="space-y-2">
					{Object.entries(data).map(([key, value]) => (
						<div key={key} className="flex text-sm">
							<span className="font-medium text-gray-700 w-24">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
							<span className="text-gray-600">{value as string}</span>
						</div>
					))}
				</div>
			);
		}

		if (section === "companyInfo" && typeof data === "object") {
			return (
				<div className="space-y-2">
					{Object.entries(data).map(([key, value]) => (
						<div key={key} className="flex text-sm">
							<span className="font-medium text-gray-700 w-32">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
							<span className="text-gray-600">{value as string}</span>
						</div>
					))}
				</div>
			);
		}

		if (section === "introduction" && typeof data === "object") {
			return (
				<div className="space-y-4">
					<div>
						<h3 className="font-medium text-gray-900 text-sm mb-2">Introduction</h3>
						<p className="text-sm text-gray-700 whitespace-pre-wrap">{data.introduction}</p>
					</div>
				</div>
			);
		}

		if (section === "body" && typeof data === "object") {
			return (
				<div className="space-y-4">
					<div>
						<h3 className="font-medium text-gray-900 text-sm mb-2">Body</h3>
						<p className="text-sm text-gray-700 whitespace-pre-wrap">{data.body}</p>
					</div>
				</div>
			);
		}

		if (section === "closing" && typeof data === "object") {
			return (
				<div className="space-y-4">
					<div>
						<h3 className="font-medium text-gray-900 text-sm mb-2">Closing</h3>
						<p className="text-sm text-gray-700 whitespace-pre-wrap">{data.closing}</p>
					</div>
				</div>
			);
		}

		return <p className="text-sm text-gray-700 whitespace-pre-wrap">{JSON.stringify(data, null, 2)}</p>;
	};

	const rewriteSection = async (sectionName: string, type: "professional" | "concise" | "detailed") => {
		try {
			setIsRewriting(type);
			const rewriteTypeMap: Record<string, RewriteType> = {
				professional: RewriteType.formalize,
				concise: RewriteType.shorten,
				detailed: RewriteType.elaborate
			};
			const rewriteType = rewriteTypeMap[type];

			// For coverLetterData, we need to specify which part we're rewriting
			const actualSection = sectionName === "introduction" ? editedData : editedData[sectionName];
			const updatedCoverLetter = await CoverLetterService.rewriteSection(sectionName, rewriteType, coverLetter.id, actualSection);
			console.log("Updated Cover Letter:", updatedCoverLetter);
			if (sectionName === "introduction" || sectionName === "body" || sectionName === "closing") {
				setEditedData({ [sectionName]: updatedCoverLetter });
			}
		} catch (error) {
			console.error("Error rewriting section:", error);
		} finally {
			setIsRewriting(null);
		}
	};

	return (
		<>
			<Dialog open={showValidationDialog} onOpenChange={setShowValidationDialog}>
				<DialogContent className="sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle className="text-xl text-red-600">Required Fields Missing</DialogTitle>
					</DialogHeader>
					<div className="py-4">
						<div className="max-h-[300px] overflow-y-auto">
							<ul className="list-disc pl-5 space-y-2">
								{validationErrors.map((error, index) => (
									<li key={index} className="text-sm text-gray-600">
										{error}
									</li>
								))}
							</ul>
						</div>
						<div className="flex justify-end mt-4">
							<Button onClick={() => setShowValidationDialog(false)}>Close</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>

			<Dialog open={isOpen} onOpenChange={setIsOpen} modal={true}>
				<DialogContent
					className="max-w-3xl max-h-[80vh] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg flex flex-col"
					onInteractOutside={(e) => e.preventDefault()}
				>
					<div className="p-6 pb-0">
						<DialogHeader>
							<DialogTitle className="text-lg font-bold">
								Edit {editingSection.charAt(0).toUpperCase() + editingSection.slice(1)}
							</DialogTitle>
						</DialogHeader>
					</div>

					<Tabs defaultValue="edit" className="flex-1 flex flex-col min-h-0" onValueChange={setActiveTab}>
						<div className="px-6 pb-4">
							<TabsList className="grid w-full grid-cols-2 bg-gray-100 p-1 rounded-lg">
								<TabsTrigger
									value="edit"
									className="data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-sm text-gray-600 rounded-md"
								>
									Edit / AI Rewrite
								</TabsTrigger>
								<TabsTrigger
									value="original"
									className="data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-sm text-gray-600 rounded-md"
								>
									Original
								</TabsTrigger>
							</TabsList>

							{activeTab === "edit" &&
								(editingSection === "introduction" || editingSection === "body" || editingSection === "closing") && (
									<div className="mt-4">
										<div className="flex gap-2">
											<Button
												variant="outline"
												size="sm"
												onClick={() => rewriteSection(editingSection, "professional")}
												disabled={isRewriting !== null}
											>
												{isRewriting === "professional" ? (
													<>
														<span className="animate-spin mr-2">⟳</span>
														Rewriting...
													</>
												) : (
													"Formalize"
												)}
											</Button>
											<Button
												variant="outline"
												size="sm"
												onClick={() => rewriteSection(editingSection, "concise")}
												disabled={isRewriting !== null}
											>
												{isRewriting === "concise" ? (
													<>
														<span className="animate-spin mr-2">⟳</span>
														Rewriting...
													</>
												) : (
													"Shorten"
												)}
											</Button>
											<Button
												variant="outline"
												size="sm"
												onClick={() => rewriteSection(editingSection, "detailed")}
												disabled={isRewriting !== null}
											>
												{isRewriting === "detailed" ? (
													<>
														<span className="animate-spin mr-2">⟳</span>
														Rewriting...
													</>
												) : (
													"Elaborate"
												)}
											</Button>
											<CustomTooltip content="Use these buttons to rephrase the content of the section. Confirm or Cancel after each change."></CustomTooltip>
										</div>
									</div>
								)}
						</div>

						<div className="flex-1 overflow-y-auto px-6 min-h-0">
							<TabsContent value="original" className="mt-0 h-full">
								<div className="bg-white border border-gray-200 rounded-lg p-4">
									{formatContent(editingSection, coverLetter.coverLetterData[editingSection as keyof CoverLetterData])}
								</div>
							</TabsContent>

							<TabsContent value="edit" className="mt-0 h-full">
								{renderContent()}
							</TabsContent>
						</div>
					</Tabs>

					<div className="border-t p-2">
						<DialogFooter className="flex justify-end gap-2">
							<Button variant="outline" onClick={() => setIsOpen(false)}>
								Cancel
							</Button>
							<Button onClick={handleConfirm}>Confirm</Button>
						</DialogFooter>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default CoverLetterEditForm;
