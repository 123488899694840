import { UserView } from "../types/UserView";
import { plainToInstance } from "class-transformer";
import axios from "axios";
interface ConnectedUser {
	userId: string;
	socketId: string;
	isInCall: boolean;
	email: string;
	firstName: string;
	lastName: string;
	clientName: string;
	tenantId: string;
	clientId: string;
}

export interface PhoneNumber {
	number: string;
	isVerified: boolean;
}
// TODO: implement plainToInstance for all the responses

export class UserService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/customer-portal`;

	static async findAll(): Promise<UserView[]> {
		// TODO: use axios instead of fetch
		const response = await fetch(`${this.baseUrl}/users`);
		const data = await response.json();
		return data.map((user: object) => plainToInstance(UserView, user));
	}

	static async getConnectedUsers(): Promise<ConnectedUser[] | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/connected-users`);

			return response.data;
		} catch (error) {
			console.error("Error fetching connected users:", error);
			return null;
		}
	}

	static async getUserBySessionIdEmail(authToken: string): Promise<UserView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/user-email`, { headers: { Authorization: `Bearer ${authToken}` } });
			console.log("response.data", response.data);
			return response.data;
		} catch (error) {
			console.error("Error fetching user by session ID email:", error);
			return null;
		}
	}

	static async getUserByFronteggId(fronteggId: string): Promise<UserView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/userByFronteggId/${fronteggId}`);
			if (response.data) {
				return response.data;
			}
			return null;
		} catch (error) {
			console.error("Error fetching user ID by Frontegg ID:", error);
			return null;
		}
	}

	static async getUserByEmail(email: string): Promise<UserView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/userByEmail`, { params: { email } });
			console.log("getUserByEmail", response.data);
			return response.data;
		} catch (error) {
			console.error("Error fetching user by email:", error);
			return null;
		}
	}

	static async getUserById(): Promise<UserView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/userById`);
			console.log("getUserById", response.data);
			return response.data;
		} catch (error) {
			console.error("Error fetching user by id:", error);
			return null;
		}
	}

	static async createUserFrontEgg(email: string, firstName: string, lastName: string, tenantId: string): Promise<void> {
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/createUserFrontEgg`, {
				email,
				firstName,
				lastName,
				tenantId
			});
			return response.data;
		} catch (error) {
			console.error("Error creating user:", error);
			throw error;
		}
	}
	static async getOrCreateUser(email: string, firstName: string, lastName: string, tenantId: string): Promise<UserView | null> {
		try {
			const response = await axios.post(`${this.baseUrl}/user/getOrCreate`, {
				email,
				firstName,
				lastName,
				tenantId
			});
			return response.data;
		} catch (error) {
			console.error("Error creating user:", error);
			throw error;
		}
	}

	static async updateHasDoneTutorial(userId: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/user/${userId}/updateTutorialStatus`, {
				hasDoneTutorial: true
			});
		} catch (error) {
			console.error("Error updating user's tutorial status:", error);
			throw error;
		}
	}

	static async updateUserFronteggIdByEmail(fronteggId: string, email: string): Promise<UserView | null> {
		try {
			const response = await axios.put(`${this.baseUrl}/user/updateFronteggId`, { fronteggId, email });
			return response.data;
		} catch (error) {
			console.error("Error updating user Frontegg ID:", error);
			return null;
		}
	}

	static async getPhoneNumber(): Promise<PhoneNumber | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/user/phone-number`, {
				params: {}
			});
			// console.log(response.data);
			return response.data as PhoneNumber;
		} catch (error) {
			console.error("Error getting user's phone number:", error);
			return null;
		}
	}

	static async addPhoneNumber(number: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/user/phone-number`, {
				number
			});
		} catch (error) {
			console.error("Error adding user's phone number:", error);
			throw error;
		}
	}

	static async sendVerificationSMS(): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/user/send-verification-sms`, {});
		} catch (error) {
			console.error("Error sending verification SMS:", error);
			throw error;
		}
	}

	static async verifyPhoneNumber(code: string): Promise<boolean> {
		try {
			const response = await axios.post(`${this.baseUrl}/user/verify-phone-number`, {
				code
			});
			console.log(response.data);
			return response.data.isVerified;
		} catch (error) {
			console.error("Error verifying user's phone number:", error);
			return false;
		}
	}

	// static async getClientById(clientId: string): Promise<ClientView | null> {
	// 	try {
	// 		const response = await axios.get(`${this.baseUrl}/client/${clientId}`);
	// 		return response.data;
	// 	} catch (error) {
	// 		console.error("Error fetching client by ID:", error);
	// 		return null;
	// 	}
	// }
}
