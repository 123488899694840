import React, { useState } from "react";
import { ResumeView, Contact, Experience, Education, ExtraCurricular, Project, Certification, Skills } from "../../types/ResumeView";
import { Input } from "../shadcn-ui/Input";
import { Textarea } from "../shadcn-ui/Textarea";
import { Button } from "../shadcn-ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../shadcn-ui/Dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../shadcn-ui/Tabs";
import { ResumeService, RewriteType, FeedbackNew, FeedbackDataNew } from "../../services/ResumeService";
import NewResumeFeedback from "./NewResumeFeedback";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/Select";
import CustomTooltip from "../Utility/CustomTooltip";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => (currentYear - i).toString());

interface ResumeEditFormProps {
	editingSection: string;
	editedData: any;
	setEditedData: (data: any) => void;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	resumeId: string;
	resume: ResumeView;
	setResume: (resume: ResumeView) => void;
}

const ResumeEditForm: React.FC<ResumeEditFormProps> = ({
	editingSection,
	editedData,
	setEditedData,
	isOpen,
	setIsOpen,
	resumeId,
	resume,
	setResume
}) => {
	const [activeTab, setActiveTab] = useState("edit");
	const [isRewriting, setIsRewriting] = useState<"professional" | "concise" | "detailed" | null>(null);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const [showValidationDialog, setShowValidationDialog] = useState(false);

	// Add validation patterns
	const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
	const URL_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

	const handleArrayChange = (index: number, value: string, field: string, parentField?: string) => {
		if (parentField) {
			setEditedData({
				...editedData,
				[parentField]: {
					...editedData[parentField],
					[field]: editedData[parentField]?.[field]?.map((item: string, i: number) => (i === index ? value : item)) || []
				}
			});
		} else {
			setEditedData({
				...editedData,
				[field]: editedData[field]?.map((item: string, i: number) => (i === index ? value : item)) || []
			});
		}
	};

	const handleObjectArrayChange = (index: number, field: string, value: any) => {
		// If the field is an array type (roles, notes, tasks) and it would be empty, set it to [""]
		if (Array.isArray(value) && value.length === 0) {
			value = [""];
		}
		setEditedData(editedData?.map((item: any, i: number) => (i === index ? { ...item, [field]: value } : item)) || []);
	};

	const addObjectArrayItem = () => {
		const newItem = { id: Date.now() }; // Temporary ID for new items

		// Initialize all fields based on section type
		switch (editingSection) {
			case "experience":
				Object.assign(newItem, {
					jobTitle: "",
					companyName: "",
					city: "",
					stateOrProv: "",
					startMonth: "",
					startYear: "",
					endMonth: "",
					endYear: "",
					roles: [""]
				});
				break;
			case "education":
				Object.assign(newItem, {
					school: "",
					degree: "",
					program: "",
					graduatingYear: "",
					notes: [""]
				});
				break;
			case "projects":
				Object.assign(newItem, {
					projectName: "",
					description: "",
					startMonth: "",
					startYear: "",
					endMonth: "",
					endYear: "",
					projectURL: "",
					notes: [""]
				});
				break;
			case "extraCurricular":
				Object.assign(newItem, {
					activity: "",
					orgName: "",
					startMonth: "",
					startYear: "",
					endMonth: "",
					endYear: "",
					tasks: [""]
				});
				break;
		}

		setEditedData([...(editedData || []), newItem]);
	};

	const removeObjectArrayItem = (index: number) => {
		const updatedData = editedData?.filter((_: any, i: number) => i !== index) || [];
		setEditedData(updatedData);
	};

	const addArrayItem = (field: string, parentField?: string) => {
		if (parentField) {
			setEditedData({
				...editedData,
				[parentField]: {
					...editedData[parentField],
					[field]: [...(editedData[parentField]?.[field] || []), ""]
				}
			});
		} else {
			setEditedData({
				...editedData,
				[field]: [...(editedData[field] || []), ""]
			});
		}
	};

	const removeArrayItem = (index: number, field: string, parentField?: string) => {
		if (parentField) {
			const currentArray = editedData[parentField]?.[field] || [];
			// If this would be the last item, replace it with an empty string instead of removing
			if (currentArray.length <= 1) {
				setEditedData({
					...editedData,
					[parentField]: {
						...editedData[parentField],
						[field]: [""]
					}
				});
			} else {
				setEditedData({
					...editedData,
					[parentField]: {
						...editedData[parentField],
						[field]: currentArray.filter((_: string, i: number) => i !== index)
					}
				});
			}
		} else {
			const currentArray = editedData[field] || [];
			// If this would be the last item, replace it with an empty string instead of removing
			if (currentArray.length <= 1) {
				setEditedData({
					...editedData,
					[field]: [""]
				});
			} else {
				setEditedData({
					...editedData,
					[field]: currentArray.filter((_: string, i: number) => i !== index)
				});
			}
		}
	};

	const renderArrayInput = (field: string, label: string, parentField?: string) => {
		let items: string[] = [];
		let placeholder = "";

		// Set appropriate placeholder based on the field
		switch (field) {
			case "technical":
				placeholder = "Salesforce CRM";
				break;
			case "coreCompetencies":
				placeholder = "Contract Negotiation";
				break;
			case "languages":
				placeholder = "English (Native)";
				break;
			case "tools":
				placeholder = "LinkedIn Sales Navigator";
				break;
			case "frameworks":
				placeholder = "SPIN Selling";
				break;
			case "interests":
				placeholder = "Sales Psychology";
				break;
			case "hobbies":
				placeholder = "Public Speaking";
				break;
			default:
				placeholder = `Enter ${label}`;
		}

		if (parentField) {
			if (editingSection === "skills") {
				items = editedData?.[field] || [];
				if (!editedData?.[field]) {
					setEditedData({
						...editedData,
						[field]: []
					});
				}
			} else {
				if (!editedData?.[parentField]) {
					setEditedData({
						...editedData,
						[parentField]: {}
					});
				}
				items = editedData?.[parentField]?.[field] || [];
				if (!editedData?.[parentField]?.[field]) {
					setEditedData({
						...editedData,
						[parentField]: {
							...editedData[parentField],
							[field]: []
						}
					});
				}
			}
		} else {
			items = editedData?.[field] || [];
			if (!editedData?.[field]) {
				setEditedData({
					...editedData,
					[field]: []
				});
			}
		}

		return (
			<div className="space-y-2">
				<label className="text-sm font-medium">{label}</label>
				{items.map((item: string, index: number) => (
					<div key={index} className="flex gap-2">
						<Input
							value={item}
							onChange={(e) => {
								if (editingSection === "skills") {
									const newItems = [...items];
									newItems[index] = e.target.value;
									setEditedData({
										...editedData,
										[field]: newItems
									});
								} else {
									handleArrayChange(index, e.target.value, field, parentField);
								}
							}}
							className="flex-1"
							placeholder={placeholder}
						/>
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								if (editingSection === "skills") {
									const newItems = items.filter((_, i) => i !== index);
									setEditedData({
										...editedData,
										[field]: newItems
									});
								} else {
									removeArrayItem(index, field, parentField);
								}
							}}
						>
							Remove
						</Button>
					</div>
				))}
				<Button
					variant="outline"
					size="sm"
					onClick={() => {
						if (editingSection === "skills") {
							setEditedData({
								...editedData,
								[field]: [...items, ""]
							});
						} else {
							addArrayItem(field, parentField);
						}
					}}
				>
					Add {label}
				</Button>
			</div>
		);
	};

	const renderContent = () => {
		switch (editingSection) {
			case "summary":
				return (
					<div className="space-y-4">
						<div>
							<label className="text-sm font-medium">Summary</label>
							<Textarea
								value={editedData || ""}
								onChange={(e) => setEditedData(e.target.value)}
								placeholder="Results-driven Sales Professional with 5+ years of experience in B2B software sales. Proven track record of exceeding quarterly targets and building long-term client relationships. Skilled in consultative selling, pipeline management, and contract negotiations."
								className="mt-1 p-2"
								rows={10}
							/>
						</div>
					</div>
				);

			case "contact":
				if (!editedData || typeof editedData !== "object") {
					setEditedData({
						firstName: "",
						lastName: "",
						email: "",
						phone: "",
						address: "",
						linkedinURL: "",
						website: ""
					});
					return null;
				}

				return (
					<div className="space-y-4">
						<div className="grid grid-cols-2 gap-4">
							<div>
								<label className="text-sm font-medium">First Name</label>
								<Input
									value={editedData?.firstName || ""}
									onChange={(e) => setEditedData({ ...editedData, firstName: e.target.value })}
									placeholder="John"
									className="mt-1"
								/>
							</div>
							<div>
								<label className="text-sm font-medium">Last Name</label>
								<Input
									value={editedData?.lastName || ""}
									onChange={(e) => setEditedData({ ...editedData, lastName: e.target.value })}
									placeholder="Doe"
									className="mt-1"
								/>
							</div>
						</div>
						<div>
							<label className="text-sm font-medium">Email</label>
							<Input
								value={editedData?.email || ""}
								onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
								placeholder="john.doe@gmail.com"
								type="email"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">Phone</label>
							<Input
								value={editedData?.phone || ""}
								onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
								placeholder="(123) 456-7890"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">Address</label>
							<Input
								value={editedData?.address || ""}
								onChange={(e) => setEditedData({ ...editedData, address: e.target.value })}
								placeholder="123 Main St, City, State 12345"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">LinkedIn URL</label>
							<Input
								value={editedData?.linkedinURL || ""}
								onChange={(e) => setEditedData({ ...editedData, linkedinURL: e.target.value })}
								placeholder="linkedin.com/in/johndoe"
								className="mt-1"
							/>
						</div>
						<div>
							<label className="text-sm font-medium">Website</label>
							<Input
								value={editedData?.website || ""}
								onChange={(e) => setEditedData({ ...editedData, website: e.target.value })}
								placeholder="www.johndoe.com"
								className="mt-1"
							/>
						</div>
					</div>
				);

			case "experience":
				return (
					<div className="space-y-8">
						{(editedData || []).map((exp: Experience, index: number) => (
							<div key={exp.id || index} className="space-y-4 p-4 border rounded-lg">
								<div className="flex justify-between items-center">
									<h3 className="text-lg font-medium">Experience {index + 1}</h3>
									<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
										Remove Experience
									</Button>
								</div>
								<div>
									<label className="text-sm font-medium">Job Title</label>
									<Input
										value={exp.jobTitle || ""}
										onChange={(e) => handleObjectArrayChange(index, "jobTitle", e.target.value)}
										placeholder="Senior Sales Representative"
										className="mt-1"
									/>
								</div>
								<div>
									<label className="text-sm font-medium">Company Name</label>
									<Input
										value={exp.companyName || ""}
										onChange={(e) => handleObjectArrayChange(index, "companyName", e.target.value)}
										placeholder="Enterprise Solutions Group"
										className="mt-1"
									/>
								</div>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="text-sm font-medium">City</label>
										<Input
											value={exp.city || ""}
											onChange={(e) => handleObjectArrayChange(index, "city", e.target.value)}
											placeholder="City"
											className="mt-1"
										/>
									</div>
									<div>
										<label className="text-sm font-medium">State/Province</label>
										<Input
											value={exp.stateOrProv || ""}
											onChange={(e) => handleObjectArrayChange(index, "stateOrProv", e.target.value)}
											placeholder="State/Province"
											className="mt-1"
										/>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="text-sm font-medium">Start Month</label>
										<Select
											value={exp.startMonth || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select month" />
											</SelectTrigger>
											<SelectContent>
												{months.map((month) => (
													<SelectItem key={month} value={month}>
														{month}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
									<div>
										<label className="text-sm font-medium">Start Year</label>
										<Select
											value={exp.startYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select year" />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="text-sm font-medium">End Month</label>
										<Select
											value={exp.endMonth || ""}
											onValueChange={(value) => {
												if (value === "Present") {
													handleObjectArrayChange(index, "endYear", "");
												}
												handleObjectArrayChange(index, "endMonth", value);
											}}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select month" />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="Present">Present</SelectItem>
												{months.map((month) => (
													<SelectItem key={month} value={month}>
														{month}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
									{exp.endMonth && exp.endMonth !== "Present" && (
										<div>
											<label className="text-sm font-medium">End Year</label>
											<Select
												value={exp.endYear || ""}
												onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											>
												<SelectTrigger className="w-[180px] bg-white">
													<SelectValue placeholder="Select year" />
												</SelectTrigger>
												<SelectContent>
													{years.map((year) => (
														<SelectItem key={year} value={year}>
															{year}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</div>
									)}
								</div>
								<div className="space-y-2">
									<label className="text-sm font-medium">Accomplishments</label>
									{(exp.roles || []).map((role: string, roleIndex: number) => (
										<div key={roleIndex} className="flex gap-2">
											<Input
												value={role}
												onChange={(e) => {
													const newRoles = [...(exp.roles || [])];
													newRoles[roleIndex] = e.target.value;
													handleObjectArrayChange(index, "roles", newRoles);
												}}
												className="flex-1"
											/>
											<Button
												variant="outline"
												size="sm"
												onClick={() => {
													const newRoles = exp.roles.filter((_, i) => i !== roleIndex);
													handleObjectArrayChange(index, "roles", newRoles);
												}}
											>
												Remove
											</Button>
										</div>
									))}
									<Button
										variant="outline"
										size="sm"
										onClick={() => {
											const newRoles = [...(exp.roles || []), ""];
											handleObjectArrayChange(index, "roles", newRoles);
										}}
									>
										Add Accomplishment
									</Button>
								</div>
							</div>
						))}
						<Button variant="outline" onClick={addObjectArrayItem}>
							Add Experience
						</Button>
					</div>
				);

			case "education":
				return (
					<div className="space-y-8">
						{(editedData || []).map((edu: Education, index: number) => (
							<div key={edu.id || index} className="space-y-4 p-4 border rounded-lg">
								<div className="flex justify-between items-center">
									<h3 className="text-lg font-medium">Education {index + 1}</h3>
									<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
										Remove Education
									</Button>
								</div>
								<div>
									<label className="text-sm font-medium">School</label>
									<Input
										value={edu.school || ""}
										onChange={(e) => handleObjectArrayChange(index, "school", e.target.value)}
										placeholder="School Name"
										className="mt-1"
									/>
								</div>
								<div>
									<label className="text-sm font-medium">Degree</label>
									<Input
										value={edu.degree || ""}
										onChange={(e) => handleObjectArrayChange(index, "degree", e.target.value)}
										placeholder="Degree"
										className="mt-1"
									/>
								</div>
								<div>
									<label className="text-sm font-medium">Program</label>
									<Input
										value={edu.program || ""}
										onChange={(e) => handleObjectArrayChange(index, "program", e.target.value)}
										placeholder="Program"
										className="mt-1"
									/>
								</div>
								<div>
									<label className="text-sm font-medium">Graduating Year</label>
									<Select
										value={edu.graduatingYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "graduatingYear", value)}
									>
										<SelectTrigger className="w-[180px] bg-white">
											<SelectValue placeholder="Select year" />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div className="space-y-2">
									<label className="text-sm font-medium">Notes</label>
									{(edu.notes || []).map((note: string, noteIndex: number) => (
										<div key={noteIndex} className="flex gap-2">
											<Input
												value={note}
												onChange={(e) => {
													const newNotes = [...(edu.notes || [])];
													newNotes[noteIndex] = e.target.value;
													handleObjectArrayChange(index, "notes", newNotes);
												}}
												className="flex-1"
											/>
											<Button
												variant="outline"
												size="sm"
												onClick={() => {
													const newNotes = edu.notes.filter((_, i) => i !== noteIndex);
													handleObjectArrayChange(index, "notes", newNotes);
												}}
											>
												Remove
											</Button>
										</div>
									))}
									<Button
										variant="outline"
										size="sm"
										onClick={() => {
											const newNotes = [...(edu.notes || []), ""];
											handleObjectArrayChange(index, "notes", newNotes);
										}}
									>
										Add Note
									</Button>
								</div>
							</div>
						))}
						<Button variant="outline" onClick={addObjectArrayItem}>
							Add Education
						</Button>
					</div>
				);

			case "projects":
				return (
					<div className="space-y-8">
						{(editedData || []).map((project: Project, index: number) => (
							<div key={project.id || index} className="space-y-4 p-4 border rounded-lg">
								<div className="flex justify-between items-center">
									<h3 className="text-lg font-medium">Project {index + 1}</h3>
									<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
										Remove Project
									</Button>
								</div>
								<div>
									<label className="text-sm font-medium">Project Name</label>
									<Input
										value={project.projectName || ""}
										onChange={(e) => handleObjectArrayChange(index, "projectName", e.target.value)}
										placeholder="Regional Sales Strategy Overhaul"
										className="mt-1"
									/>
								</div>
								<div>
									<label className="text-sm font-medium">Description</label>
									<Textarea
										value={project.description || ""}
										onChange={(e) => handleObjectArrayChange(index, "description", e.target.value)}
										placeholder="Led a team initiative to restructure our regional sales approach, resulting in a 45% increase in quarterly revenue and improved client retention rates"
										className="mt-1"
										rows={4}
									/>
								</div>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="text-sm font-medium">Start Month</label>
										<Select
											value={project.startMonth || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select month" />
											</SelectTrigger>
											<SelectContent>
												{months.map((month) => (
													<SelectItem key={month} value={month}>
														{month}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
									<div>
										<label className="text-sm font-medium">Start Year</label>
										<Select
											value={project.startYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select year" />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="text-sm font-medium">End Month</label>
										<Select
											value={project.endMonth || ""}
											onValueChange={(value) => {
												if (value === "Present") {
													handleObjectArrayChange(index, "endYear", "");
												}
												handleObjectArrayChange(index, "endMonth", value);
											}}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select month" />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="Present">Present</SelectItem>
												{months.map((month) => (
													<SelectItem key={month} value={month}>
														{month}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
									{project.endMonth && project.endMonth !== "Present" && (
										<div>
											<label className="text-sm font-medium">End Year</label>
											<Select
												value={project.endYear || ""}
												onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											>
												<SelectTrigger className="w-[180px] bg-white">
													<SelectValue placeholder="Select year" />
												</SelectTrigger>
												<SelectContent>
													{years.map((year) => (
														<SelectItem key={year} value={year}>
															{year}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</div>
									)}
								</div>
								<div>
									<label className="text-sm font-medium">Project URL</label>
									<Input
										value={project.projectURL || ""}
										onChange={(e) => handleObjectArrayChange(index, "projectURL", e.target.value)}
										placeholder="Project URL"
										className="mt-1"
									/>
								</div>
								<div className="space-y-2">
									<label className="text-sm font-medium">Notes</label>
									{(project.notes || []).map((note: string, noteIndex: number) => (
										<div key={noteIndex} className="flex gap-2">
											<Input
												value={note}
												onChange={(e) => {
													const newNotes = [...(project.notes || [])];
													newNotes[noteIndex] = e.target.value;
													handleObjectArrayChange(index, "notes", newNotes);
												}}
												className="flex-1"
											/>
											<Button
												variant="outline"
												size="sm"
												onClick={() => {
													const newNotes = project.notes.filter((_, i) => i !== noteIndex);
													handleObjectArrayChange(index, "notes", newNotes);
												}}
											>
												Remove
											</Button>
										</div>
									))}
									<Button
										variant="outline"
										size="sm"
										onClick={() => {
											const newNotes = [...(project.notes || []), ""];
											handleObjectArrayChange(index, "notes", newNotes);
										}}
									>
										Add Note
									</Button>
								</div>
							</div>
						))}
						<Button variant="outline" onClick={addObjectArrayItem}>
							Add Project
						</Button>
					</div>
				);

			case "extraCurricular":
				return (
					<div className="space-y-8">
						{(editedData || []).map((activity: ExtraCurricular, index: number) => (
							<div key={activity.id || index} className="space-y-4 p-4 border rounded-lg">
								<div className="flex justify-between items-center">
									<h3 className="text-lg font-medium">Activity {index + 1}</h3>
									<Button variant="outline" size="sm" onClick={() => removeObjectArrayItem(index)}>
										Remove Activity
									</Button>
								</div>
								<div>
									<label className="text-sm font-medium">Activity</label>
									<Input
										value={activity.activity || ""}
										onChange={(e) => handleObjectArrayChange(index, "activity", e.target.value)}
										placeholder="Sales Mentor"
										className="mt-1"
									/>
								</div>
								<div>
									<label className="text-sm font-medium">Organization Name</label>
									<Input
										value={activity.orgName || ""}
										onChange={(e) => handleObjectArrayChange(index, "orgName", e.target.value)}
										placeholder="Young Professionals Sales Network"
										className="mt-1"
									/>
								</div>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="text-sm font-medium">Start Month</label>
										<Select
											value={activity.startMonth || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select month" />
											</SelectTrigger>
											<SelectContent>
												{months.map((month) => (
													<SelectItem key={month} value={month}>
														{month}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
									<div>
										<label className="text-sm font-medium">Start Year</label>
										<Select
											value={activity.startYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select year" />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								</div>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="text-sm font-medium">End Month</label>
										<Select
											value={activity.endMonth || ""}
											onValueChange={(value) => {
												if (value === "Present") {
													handleObjectArrayChange(index, "endYear", "");
												}
												handleObjectArrayChange(index, "endMonth", value);
											}}
										>
											<SelectTrigger className="w-[180px] bg-white">
												<SelectValue placeholder="Select month" />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="Present">Present</SelectItem>
												{months.map((month) => (
													<SelectItem key={month} value={month}>
														{month}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
									{activity.endMonth && activity.endMonth !== "Present" && (
										<div>
											<label className="text-sm font-medium">End Year</label>
											<Select
												value={activity.endYear || ""}
												onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											>
												<SelectTrigger className="w-[180px] bg-white">
													<SelectValue placeholder="Select year" />
												</SelectTrigger>
												<SelectContent>
													{years.map((year) => (
														<SelectItem key={year} value={year}>
															{year}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</div>
									)}
								</div>
								<div className="space-y-2">
									<label className="text-sm font-medium">Tasks</label>
									{(activity.tasks || []).map((task: string, taskIndex: number) => (
										<div key={taskIndex} className="flex gap-2">
											<Input
												value={task}
												onChange={(e) => {
													const newTasks = [...(activity.tasks || [])];
													newTasks[taskIndex] = e.target.value;
													handleObjectArrayChange(index, "tasks", newTasks);
												}}
												className="flex-1"
											/>
											<Button
												variant="outline"
												size="sm"
												onClick={() => {
													const newTasks = activity.tasks.filter((_, i) => i !== taskIndex);
													handleObjectArrayChange(index, "tasks", newTasks);
												}}
											>
												Remove
											</Button>
										</div>
									))}
									<Button
										variant="outline"
										size="sm"
										onClick={() => {
											const newTasks = [...(activity.tasks || []), ""];
											handleObjectArrayChange(index, "tasks", newTasks);
										}}
									>
										Add Task
									</Button>
								</div>
							</div>
						))}
						<Button variant="outline" onClick={addObjectArrayItem}>
							Add Activity
						</Button>
					</div>
				);

			case "skills":
				if (!editedData || typeof editedData !== "object") {
					setEditedData({
						technical: [""],
						coreCompetencies: [""],
						languages: [""],
						tools: [""],
						frameworks: [""]
					});
					return null;
				}
				return (
					<div className="space-y-4">
						{renderArrayInput("technical", "Technical Skill", "skills")}
						{renderArrayInput("coreCompetencies", "Core Competency", "skills")}
						{renderArrayInput("languages", "Language", "skills")}
						{renderArrayInput("tools", "Tool", "skills")}
						{renderArrayInput("frameworks", "Framework", "skills")}
					</div>
				);

			case "interests":
				if (!editedData || !Array.isArray(editedData)) {
					setEditedData([""]); // Initialize with an empty string
					return null;
				}
				return <div className="space-y-4">{renderArrayInput("interests", "Interest")}</div>;

			case "hobbies":
				if (!editedData || !Array.isArray(editedData)) {
					setEditedData([""]); // Initialize with an empty string
					return null;
				}
				return <div className="space-y-4">{renderArrayInput("hobbies", "Hobby")}</div>;

			default:
				return null;
		}
	};

	const validateRequiredFields = () => {
		const errors: string[] = [];

		if (editingSection === "contact" && typeof editedData === "object") {
			const { firstName, lastName, email, phone, linkedinURL, website } = editedData;
			if (!firstName) errors.push("First Name is required");
			if (!lastName) errors.push("Last Name is required");
			if (!email) {
				errors.push("Email is required");
			} else if (!EMAIL_REGEX.test(email)) {
				errors.push("Email format is invalid");
			}
			if (!phone) {
				errors.push("Phone is required");
			} else if (!PHONE_REGEX.test(phone)) {
				errors.push("Phone format is invalid (e.g., (123) 456-7890)");
			}
			if (linkedinURL && !URL_REGEX.test(linkedinURL)) {
				errors.push("LinkedIn URL format is invalid");
			}
			if (website && !URL_REGEX.test(website)) {
				errors.push("Website URL format is invalid");
			}
		}

		if (editingSection === "summary" && editedData === "") {
			errors.push("Summary is required");
		}

		if (editingSection === "experience" && Array.isArray(editedData)) {
			editedData.forEach((exp, index) => {
				if (!exp.jobTitle) errors.push(`Experience ${index + 1}: Job Title is required`);
				if (!exp.companyName) errors.push(`Experience ${index + 1}: Company Name is required`);
				if (!exp.city) errors.push(`Experience ${index + 1}: City is required`);
				if (!exp.stateOrProv) errors.push(`Experience ${index + 1}: State/Province is required`);
				if (!exp.startMonth) errors.push(`Experience ${index + 1}: Start Month is required`);
				if (!exp.startYear) errors.push(`Experience ${index + 1}: Start Year is required`);
				if (!exp.endMonth) errors.push(`Experience ${index + 1}: End Month is required`);
				if (exp.endMonth !== "Present" && !exp.endYear) errors.push(`Experience ${index + 1}: End Year is required`);
				if (!exp.roles?.length) errors.push(`Experience ${index + 1}: At least one role is required`);
			});
		}

		if (editingSection === "education" && Array.isArray(editedData)) {
			editedData.forEach((edu, index) => {
				if (!edu.school) errors.push(`Education ${index + 1}: School is required`);
				if (!edu.degree) errors.push(`Education ${index + 1}: Degree is required`);
				if (!edu.program) errors.push(`Education ${index + 1}: Program is required`);
				if (!edu.graduatingYear) errors.push(`Education ${index + 1}: Graduating Year is required`);
			});
		}

		if (editingSection === "projects" && Array.isArray(editedData)) {
			editedData.forEach((proj, index) => {
				if (!proj.projectName) errors.push(`Project ${index + 1}: Project Name is required`);
				if (!proj.description) errors.push(`Project ${index + 1}: Description is required`);
				if (!proj.startMonth) errors.push(`Project ${index + 1}: Start Month is required`);
				if (!proj.startYear) errors.push(`Project ${index + 1}: Start Year is required`);
				if (!proj.endMonth) errors.push(`Project ${index + 1}: End Month is required`);
				if (proj.endMonth !== "Present" && !proj.endYear) errors.push(`Project ${index + 1}: End Year is required`);
				if (proj.projectURL && !URL_REGEX.test(proj.projectURL)) {
					errors.push(`Project ${index + 1}: Project URL format is invalid`);
				}
			});
		}

		if (editingSection === "extraCurricular" && Array.isArray(editedData)) {
			editedData.forEach((activity, index) => {
				if (!activity.activity) errors.push(`Activity ${index + 1}: Activity is required`);
				if (!activity.orgName) errors.push(`Activity ${index + 1}: Organization Name is required`);
				if (!activity.startMonth) errors.push(`Activity ${index + 1}: Start Month is required`);
				if (!activity.startYear) errors.push(`Activity ${index + 1}: Start Year is required`);
				if (!activity.endMonth) errors.push(`Activity ${index + 1}: End Month is required`);
				if (activity.endMonth !== "Present" && !activity.endYear) errors.push(`Activity ${index + 1}: End Year is required`);
			});
		}

		setValidationErrors(errors);
		return errors.length === 0;
	};

	const handleConfirm = async () => {
		if (!validateRequiredFields()) {
			setShowValidationDialog(true);
			return;
		}

		try {
			const updatedResume = { ...resume };
			if (editingSection === "summary") {
				updatedResume.summary = editedData;
				resume.summary = editedData;
			} else if (editingSection === "contact") {
				updatedResume.contact = editedData;
				resume.contact = editedData;
			} else if (editingSection === "experience") {
				updatedResume.experience = editedData;
				resume.experience = editedData;
			} else if (editingSection === "education") {
				updatedResume.education = editedData;
				resume.education = editedData;
			} else if (editingSection === "projects") {
				updatedResume.projects = editedData;
				resume.projects = editedData;
			} else if (editingSection === "extraCurricular") {
				updatedResume.extraCurricular = editedData;
				resume.extraCurricular = editedData;
			} else if (editingSection === "skills") {
				updatedResume.skills = editedData;
				resume.skills = editedData;
			} else if (editingSection === "interests") {
				updatedResume.interests = editedData;
				resume.interests = editedData;
			} else if (editingSection === "hobbies") {
				updatedResume.hobbies = editedData;
				resume.hobbies = editedData;
			}

			await ResumeService.updateResume(resumeId, updatedResume);
			// Reset the feedback score to trigger regeneration
			updatedResume.feedbackNew = {
				...updatedResume.feedbackNew,
				overall: {
					...updatedResume.feedbackNew.overall,
					score: 0
				}
			};
			setResume(updatedResume);
			setIsOpen(false);
		} catch (error) {
			console.error("Error saving resume:", error);
		}
	};

	const formatContent = (section: string, data: any) => {
		// Handle array-based sections with dates
		if (["experience", "education", "extraCurricular", "projects", "certifications"].includes(section) && Array.isArray(data)) {
			return (
				<div className="space-y-4">
					{data.map((item: any, index: number) => (
						<div key={index} className="border-b border-gray-200 pb-3 last:border-b-0">
							<div className="flex justify-between items-start mb-1">
								<h3 className="font-medium text-gray-900 text-sm">
									{item.jobTitle || item.degree || item.activity || item.projectName || item.certificationName}
								</h3>
								<span className="text-sm text-gray-500">
									{item.startMonth} {item.startYear} - {item.endMonth} {item.endYear}
								</span>
							</div>
							<div className="text-sm text-gray-700 mb-1">
								{item.companyName || item.school || item.orgName || item.issuingOrganization || ""}
								{item.city && item.stateOrProv ? ` • ${item.city}, ${item.stateOrProv}` : ""}
							</div>
							{item.program && <div className="text-sm text-gray-600">{item.program}</div>}
							{item.description && <div className="text-sm text-gray-600 mt-1">{item.description}</div>}
							{(item.roles || item.notes || item.tasks) && (
								<ul className="list-disc list-inside text-sm text-gray-600 space-y-1 ml-4 mt-2">
									{(item.roles || item.notes || item.tasks)?.map((text: string, idx: number) => (
										<li key={idx}>{text}</li>
									))}
								</ul>
							)}
						</div>
					))}
				</div>
			);
		}

		// Handle contact information
		if (section === "contact" && typeof data === "object") {
			return (
				<div className="space-y-2">
					{Object.entries(data).map(([key, value]) => (
						<div key={key} className="flex text-sm">
							<span className="font-medium text-gray-700 w-24">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
							<span className="text-gray-600">{value as string}</span>
						</div>
					))}
				</div>
			);
		}

		// Handle skills section
		if (section === "skills" && typeof data === "object") {
			return (
				<div className="space-y-4">
					{Object.entries(data).map(([category, skills]) => (
						<div key={category}>
							<h3 className="font-medium text-gray-900 text-sm mb-2">{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
							<div className="flex flex-wrap gap-1">
								{(skills as string[]).map((skill, idx) => (
									<span key={idx} className="bg-gray-100 px-2 py-0.5 rounded text-sm text-gray-700">
										{skill}
									</span>
								))}
							</div>
						</div>
					))}
				</div>
			);
		}

		// Handle simple array sections (hobbies, interests)
		if (Array.isArray(data)) {
			return (
				<div className="flex flex-wrap gap-1">
					{data.map((item, idx) => (
						<span key={idx} className="bg-gray-100 px-2 py-0.5 rounded text-sm text-gray-700">
							{item}
						</span>
					))}
				</div>
			);
		}

		// Handle simple string sections (summary, title, jobTitle, jobDescription)
		if (typeof data === "string") {
			return <p className="text-sm text-gray-700 whitespace-pre-wrap">{data}</p>;
		}

		// Default fallback for any other data types
		return <p className="text-sm text-gray-700 whitespace-pre-wrap">{JSON.stringify(data, null, 2)}</p>;
	};

	const rewriteSection = async (sectionName: string, type: "professional" | "concise" | "detailed") => {
		try {
			setIsRewriting(type);
			// Map UI rewrite types to API rewrite types
			const rewriteTypeMap: Record<string, RewriteType> = {
				professional: RewriteType.formalize,
				concise: RewriteType.shorten,
				detailed: RewriteType.elaborate
			};
			const rewriteType = rewriteTypeMap[type];

			const updatedResume = await ResumeService.rewriteSection(sectionName, rewriteType, resumeId);
			const sectionData = updatedResume[sectionName as keyof ResumeView];
			setEditedData(sectionData);
		} catch (error) {
			console.error("Error rewriting section:", error);
		} finally {
			setIsRewriting(null);
		}
	};

	return (
		<>
			<Dialog open={showValidationDialog} onOpenChange={setShowValidationDialog}>
				<DialogContent className="sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle className="text-xl text-red-600">Required Fields Missing</DialogTitle>
					</DialogHeader>
					<div className="py-4">
						<div className="max-h-[300px] overflow-y-auto">
							<ul className="list-disc pl-5 space-y-2">
								{validationErrors.map((error, index) => (
									<li key={index} className="text-sm text-gray-600">
										{error}
									</li>
								))}
							</ul>
						</div>
						<div className="flex justify-end mt-4">
							<Button onClick={() => setShowValidationDialog(false)}>Close</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>

			<Dialog open={isOpen} onOpenChange={setIsOpen} modal={true}>
				<DialogContent
					className="max-w-3xl max-h-[80vh] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg flex flex-col"
					onInteractOutside={(e) => e.preventDefault()}
				>
					<div className="p-6 pb-0">
						<DialogHeader>
							<DialogTitle className="text-lg font-bold">
								Edit {editingSection.charAt(0).toUpperCase() + editingSection.slice(1)}
							</DialogTitle>
						</DialogHeader>
					</div>

					<Tabs defaultValue="edit" className="flex-1 flex flex-col min-h-0" onValueChange={setActiveTab}>
						<div className="px-6 pb-4">
							<TabsList className="grid w-full grid-cols-2 bg-gray-100 p-1 rounded-lg">
								<TabsTrigger
									value="edit"
									className="data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-sm text-gray-600 rounded-md"
								>
									Edit / AI Rewrite
								</TabsTrigger>
								<TabsTrigger
									value="original"
									className="data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-sm text-gray-600 rounded-md"
								>
									Original
								</TabsTrigger>
								{/* <TabsTrigger
									value="feedback"
									className="data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-sm text-gray-600 rounded-md"
								>
									Feedback
								</TabsTrigger> */}
							</TabsList>

							{activeTab === "edit" && (
								<div className="mt-4">
									<div className="flex gap-2">
										<Button
											variant="outline"
											size="sm"
											onClick={() => rewriteSection(editingSection, "professional")}
											disabled={isRewriting !== null}
										>
											{isRewriting === "professional" ? (
												<>
													<span className="animate-spin mr-2">⟳</span>
													Rewriting...
												</>
											) : (
												"Formalize"
											)}
										</Button>
										<Button
											variant="outline"
											size="sm"
											onClick={() => rewriteSection(editingSection, "concise")}
											disabled={isRewriting !== null}
										>
											{isRewriting === "concise" ? (
												<>
													<span className="animate-spin mr-2">⟳</span>
													Rewriting...
												</>
											) : (
												"Shorten"
											)}
										</Button>

										<Button
											variant="outline"
											size="sm"
											onClick={() => rewriteSection(editingSection, "detailed")}
											disabled={isRewriting !== null}
										>
											{isRewriting === "detailed" ? (
												<>
													<span className="animate-spin mr-2">⟳</span>
													Rewriting...
												</>
											) : (
												"Elaborate"
											)}
										</Button>
										<CustomTooltip content="Use these buttons to rephrase the content of the section. Confirm or Cancel after each change."></CustomTooltip>
									</div>
								</div>
							)}
						</div>

						<div className="flex-1 overflow-y-auto px-6 min-h-0">
							<TabsContent value="original" className="mt-0 h-full">
								<div className="bg-white border border-gray-200 rounded-lg p-4">
									{formatContent(editingSection, resume[editingSection as keyof ResumeView])}
								</div>
							</TabsContent>

							<TabsContent value="edit" className="mt-0 h-full">
								{renderContent()}
							</TabsContent>

							{/* <TabsContent value="feedback" className="mt-0 h-full">
								<div className="bg-white border border-gray-200 rounded-lg p-4">
									{editingSection === "overall" ? (
										<>
											<h2 className="text-lg font-semibold text-gray-800">Positive Feedback</h2>
											<h4 className="text-sm text-gray-500 whitespace-pre-wrap">
												{resume.feedbackNew.overall.positiveFeedback}
											</h4>
											<h2 className="text-lg font-semibold text-gray-800 mt-4">Areas to Improve</h2>
											<h4 className="text-sm text-gray-500 whitespace-pre-wrap">{resume.feedbackNew.overall.areasToImprove}</h4>
										</>
									) : (
										<>
											<h2 className="text-lg font-semibold text-gray-800">Feedback</h2>
											<h4 className="text-sm text-gray-500 whitespace-pre-wrap">
												{resume.feedbackNew[editingSection as keyof Omit<FeedbackNew, "overall">]?.feedback}
											</h4>
										</>
									)}
								</div>
							</TabsContent> */}
						</div>
					</Tabs>

					<div className="border-t p-2">
						<DialogFooter className="flex justify-end gap-2">
							<Button variant="outline" onClick={() => setIsOpen(false)}>
								Cancel
							</Button>
							<Button onClick={handleConfirm}>Confirm</Button>
						</DialogFooter>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ResumeEditForm;
