import React, { useState, useEffect, useMemo } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import {
	AcademicCapIcon,
	ClipboardDocumentCheckIcon,
	ClipboardDocumentListIcon,
	ExclamationTriangleIcon,
	StarIcon,
	PencilIcon,
	LightBulbIcon,
	FlagIcon,
	ClockIcon
} from "@heroicons/react/24/solid";
import { BoltIcon, CheckBadgeIcon, ChevronDownIcon, ChevronUpIcon, UsersIcon } from "@heroicons/react/24/outline";

import { ScheduleService } from "../../services/ScheduleService";
import { ScheduleView, ActivityType } from "../../types/ScheduleView";
import ReportCard, { ReportScoreDataPoint } from "../Utility/ReportCard";
import HorizontalTabs from "../Utility/HorizontalTabs";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import { Button } from "../../components/shadcn-ui/Button";
import { AssignmentService } from "../../services/AssignmentService";
import { AdminAssignmentView } from "../../types/AdminAssignmentView";
import { Badge } from "../shadcn-ui/Badge";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn-ui/Card";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../shadcn-ui/Chart";
import { Label as RechartsLabel, Pie, PieChart } from "recharts";
import TokenManager from "../../services/TokenManager";
import { NavigateService } from "../../services/NavigateService";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { SessionService, SessionStats } from "../../services/SessionService";
import StudentDetailsTable from "../AdminPages/StudentDetailsTable";
import ScoreTimeline, { TimelineSegment } from "../AdminPages/ScoreTimeline";
import { SessionView } from "../../types/SessionView";
import Spinner from "../Utility/Spinner";

const EmailReportPostAssignment: React.FC = () => {
	const { debugMode } = useAppContext();
	// const { assignmentId, scheduleId } = useParams<{ assignmentId: string; scheduleId: string }>();
	const [assignmentId, setAssignmentId] = useState<string>(""); //
	const [scheduleId, setScheduleId] = useState<string>(""); //
	const [selectedScheduleId, setSelectedScheduleId] = useState<string>(scheduleId || ""); //
	const [schedules, setSchedules] = useState<ScheduleView[]>([]);
	const [assignedSessions, setAssignedSessions] = useState<AssignedSessionView[]>([]); //
	const [assignment, setAssignment] = useState<AdminAssignmentView | null>(null); //
	const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);

	const { token } = useParams<{ token: string }>();
	const { scheduleToken } = useParams<{ scheduleToken: string }>();

	const [assignmentSessionStats, setAssignmentSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});

	const [scheduleSessionStats, setScheduleSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});
	const [isLoadingUserSessions, setIsLoadingUserSessions] = useState<boolean>(false);
	const [userSessions, setUserSessions] = useState<{ [key: string]: SessionView }>({});
	const [expandedTables, setExpandedTables] = useState<{ [key: string]: boolean }>({});

	useEffect(() => {
		const fetchData = async () => {
			// Make the function async
			if (token && scheduleToken) {
				console.log("token", token);
				console.log("scheduleToken", scheduleToken);
				const data = await AssignmentService.getAssignmentEmail(token); // Use await
				setAssignment(data); // Update assignment state
				setAssignmentId(data.assignmentId);
				// console.log("token data", data);
				// setAssignedSessions(data.assignedSessions);
				// const assignedSessionsData = await AssignedSessionService.getByAssignmentIdEmail(token); // Use await
				// setAssignedSessions(assignedSessionsData);
				// console.log("assignment data", data);
				// console.log("scheduleToken", scheduleToken);
				const schedulesData = await ScheduleService.getByScheduleIdEmail(scheduleToken);
				setSchedules([schedulesData]);
				setScheduleId(schedulesData.scheduleId);
				setSelectedScheduleId(schedulesData.scheduleId);
			} else {
				console.log("no token");
			}
		};

		fetchData(); // Call the async function
	}, [token]);

	const tabs = schedules.map((schedule) => ({
		label: schedule.scheduleDescription,
		status: schedule.status as AssignedSessionStatus,
		date: schedule.scheduleDate,
		scheduleId: schedule.scheduleId,
		activityType: schedule.activityType,
		interviewStageOrderNumber: schedule.interviewStageOrderNumber ?? undefined,
		interviewStageType: schedule.interviewStageType ?? undefined,
		careerPathStageOrderNumber: schedule.careerPathStageOrderNumber ?? undefined,
		careerPathStageType: schedule.careerPathStageType ?? undefined,
		frontlineStageOrderNumber: schedule.frontlineStageOrderNumber ?? undefined,
		frontlineStageType: schedule.frontlineStageType ?? undefined
	}));

	const selectedTab = schedules.findIndex((schedule) => schedule.scheduleId === selectedScheduleId) || 0;
	const schedule = schedules.find((schedule) => schedule.scheduleId === selectedScheduleId);

	const fetchUserSessionData = async (schedule: ScheduleView) => {
		if (!schedule) {
			return;
		}

		try {
			setIsLoadingUserSessions(true);
			if (!scheduleToken) {
				return;
			}
			const sessions = await SessionService.getSubmittedSessionsByScheduleIdEmail(scheduleToken);

			// Convert array to map with userId as key
			const sessionsMap = sessions.reduce<{ [key: string]: SessionView }>((acc, session) => {
				if (session.userId) {
					acc[session.userId] = session;
				}
				return acc;
			}, {});

			setUserSessions(sessionsMap);

			const assignedSessions = await AssignedSessionService.getAssignedSessionsByScheduleIdEmail(scheduleToken);
			setAssignedSessions(assignedSessions);

			const stats = await SessionService.getSessionStatsByScheduleIdEmail(scheduleToken);
			setScheduleSessionStats(stats);
		} catch (error) {
			console.error("Error fetching submitted sessions:", error);
		} finally {
			setIsLoadingUserSessions(false);
		}
	};

	useEffect(() => {
		if (schedule) {
			fetchUserSessionData(schedule);
		}
	}, [schedule]);

	const renderGoalStatusChart = () => {
		if (!schedule || !schedule.goalProgress || !schedule.goalProgress.goals) return null;

		const { goals } = schedule.goalProgress;
		const totalGoals = goals.total_count;
		const completedPercentage = totalGoals > 0 ? Math.round((goals.yes_count / totalGoals) * 100) : 0;

		// const chartData = [
		// 	{ status: "Yes", count: goals.yes_count, fill: "#00a9af" },
		// 	{ status: "Partial", count: goals.partial_count, fill: "#a0e2e5" },
		// 	{ status: "No", count: goals.no_count, fill: "#808080" }
		// ];

		// const chartConfig: ChartConfig = {
		// 	count: { label: "Count" },
		// 	yes: { label: "Yes", color: "#00a9af" },
		// 	partial: { label: "Partial", color: "#a0e2e5" },
		// 	no: { label: "No", color: "#808080" }
		// };

		return (
			<Card>
				<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
					<FlagIcon className="h-5 w-5 text-[#16013e]" />
					<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
						Goal Completion Rate
						{/* <CustomTooltip content="This is the percentage of goals that have been completed by students." /> */}
					</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="text-2xl font-bold text-[#16013e]">{completedPercentage.toFixed(2)}%</div>
				</CardContent>
				{/* <CardContent className="flex-1 pb-0">
					<ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[250px]">
						<PieChart>
							<ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
							<Pie data={chartData} dataKey="count" nameKey="status" innerRadius={60} strokeWidth={5}>
								<RechartsLabel
									content={({ viewBox }) => {
										if (viewBox && "cx" in viewBox && "cy" in viewBox) {
											return (
												<text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
													<tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-3xl font-bold">
														{completedPercentage}%
													</tspan>
													<tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground">
														Completed
													</tspan>
												</text>
											);
										}
									}}
								/>
							</Pie>
						</PieChart>
					</ChartContainer>
				</CardContent> */}
			</Card>
		);
	};

	const renderMetrics = () => {
		if (!schedule || !assignedSessions) return null;

		const enrolledStudents = assignedSessions.length;
		const submittedSessions = assignedSessions.filter((session) => session.status === AssignedSessionStatus.SUBMITTED).length;
		console.log("submittedSessions", submittedSessions);
		console.log("enrolledStudents", enrolledStudents);
		const completionRate = enrolledStudents > 0 ? (submittedSessions / enrolledStudents) * 100 : 0;

		return (
			<div className="space-y-6 my-8">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
					<Card>
						<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
							<CheckBadgeIcon className="h-5 w-5 text-[#16013e]" />
							<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
								Session Completion Rate
								{/* <CustomTooltip content="This is the percentage of students who have completed this reflection call." /> */}
							</CardTitle>
						</CardHeader>
						<CardContent>
							<div className="text-2xl font-bold text-[#16013e]">{completionRate.toFixed(2)}%</div>
						</CardContent>
					</Card>
					<Card>
						<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
							<ClockIcon className="h-5 w-5 text-[#16013e]" />
							<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
								Total Usage
								{/* <CustomTooltip content="This is the total duration of all sessions for this schedule." /> */}
							</CardTitle>
						</CardHeader>
						<CardContent>
							<div className="text-2xl font-bold text-[#16013e]">{scheduleSessionStats.totalDuration}</div>
						</CardContent>
					</Card>

					{renderGoalStatusChart()}

					{/* {schedule.studentSatisfaction && schedule.activityType !== ActivityType.FIRST && (
						<Card>
							<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
								<CardTitle className="text-sm font-medium text-gray-500">Student Satisfaction</CardTitle>
								<StarIcon className="h-4 w-4 text-[#00a9af]" />
							</CardHeader>
							<CardContent>
								<div className="text-2xl font-bold text-[#16013e]">{schedule.studentSatisfaction.average_score}/10</div>
							</CardContent>
						</Card>
					)} */}

					{/* {schedule.competencyProgress &&
						schedule.competencyProgress.map((competency, index) => (
							<Card key={`progress-${index}`}>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium text-gray-500">Avg Rating for {competency.name}</CardTitle>
									<BoltIcon className="h-4 w-4 text-[#00a9af]" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold text-[#16013e]">{competency.average_assessment.toFixed(2)}/10</div>
								</CardContent>
							</Card>
						))} */}
				</div>
			</div>
		);
	};

	const normalizeCompetencyName = (name: string): string => {
		return name.toLowerCase().replace(/[-\s]/g, "");
	};

	const generateTimelineData = (type: string, competencyName?: string): TimelineSegment[] => {
		if (!userSessions || Object.keys(userSessions).length === 0) return [];

		const sessions = Object.values(userSessions);

		// Sort sessions based on type
		const sortedSessions = [...sessions].sort((a, b) => {
			switch (type) {
				case "satisfaction": {
					return (b.satisfaction?.score || 0) - (a.satisfaction?.score || 0);
				}
				case "expectations": {
					const order = { positive: 0, mixed: 1, negative: 2 };
					const aOutlook = (a.expectations?.outlook || "").toLowerCase();
					const bOutlook = (b.expectations?.outlook || "").toLowerCase();
					return order[aOutlook as keyof typeof order] - order[bOutlook as keyof typeof order];
				}
				case "purpose": {
					const order = { Yes: 0, Partial: 1, No: 2, New: 3 };
					const aProgress = a.purpose?.progress || "No";
					const bProgress = b.purpose?.progress || "No";
					return order[aProgress as keyof typeof order] - order[bProgress as keyof typeof order];
				}
				case "goalProgress": {
					const order = { Yes: 0, Partial: 1, No: 2 };

					// Determine the highest priority status for each session
					const getHighestPriorityStatus = (session: SessionView) => {
						if (!Array.isArray(session.goalProgress)) return "No";

						if (session.goalProgress.some((goal) => goal.goal_status === "Yes")) return "Yes";
						if (session.goalProgress.some((goal) => goal.goal_status === "Partial")) return "Partial";
						return "No";
					};

					const aProgress = getHighestPriorityStatus(a);
					const bProgress = getHighestPriorityStatus(b);

					return order[aProgress as keyof typeof order] - order[bProgress as keyof typeof order];
				}
				case "competency": {
					if (!competencyName) return 0;
					const aCompetency = a.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					const bCompetency = b.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					return Number(bCompetency?.assessment || 0) - Number(aCompetency?.assessment || 0);
				}
				case "finalReview": {
					return (b.finalReview?.feedback?.rating || 0) - (a.finalReview?.feedback?.rating || 0);
				}
				default:
					return 0;
			}
		});

		// Map sorted sessions to timeline segments
		return sortedSessions.map((session): TimelineSegment => {
			let value: string | number;
			let variant: "high" | "medium" | "low" | "new";

			switch (type) {
				case "satisfaction": {
					const score = session.satisfaction?.score || 0;
					value = score;
					variant = score >= 8 ? "high" : score >= 6 ? "medium" : "low";
					break;
				}
				case "purpose": {
					const progress = session.purpose?.progress || "No";
					value = progress;
					variant = progress === "Yes" ? "high" : progress === "Partial" ? "medium" : progress === "New" ? "new" : "low";
					break;
				}
				case "goalProgress": {
					if (!Array.isArray(session.goalProgress)) return { value: 0, variant: "low" };
					const progress = session.goalProgress.map((goal) => goal.goal_status).join(", ") || "No";
					value = progress;
					variant = progress.includes("Yes") ? "high" : progress.includes("Partial") ? "medium" : "low";
					break;
				}
				case "expectations": {
					const outlook = (session.expectations?.outlook || "").toLowerCase();
					value = outlook;
					variant = outlook === "positive" ? "high" : outlook === "mixed" ? "medium" : "low";
					break;
				}
				case "competency": {
					if (!competencyName) {
						value = 0;
						variant = "low";
						break;
					}
					const competency = session.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					const score = Number(competency?.assessment || 0);
					value = score;
					variant = score >= 8 ? "high" : score >= 6 ? "medium" : "low";
					break;
				}
				case "finalReview": {
					const rating = session.finalReview?.feedback?.rating || 0;
					value = rating;
					variant = rating >= 8 ? "high" : rating >= 6 ? "medium" : "low";
					break;
				}
				default: {
					value = 0;
					variant = "low";
				}
			}

			return { value, variant };
		});
	};

	const generateReportScoreData = (schedules: ScheduleView[], currentScheduleId: string) => {
		// Sort schedules by scheduleDate ascending (oldest first)
		const sortedSchedules = [...schedules].sort((a, b) => new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime());

		// Find the index of the current schedule
		const currentScheduleIndex = sortedSchedules.findIndex((schedule) => schedule.scheduleId === currentScheduleId);

		// If the current schedule is not found, return empty data
		if (currentScheduleIndex === -1)
			return {
				satisfactionData: [],
				satisfactionDetails: [],
				competencyData: {},
				competencyDetails: {}
			};

		// Include all schedules up to and including the current one
		const relevantSchedules = sortedSchedules.slice(0, currentScheduleIndex + 1);

		// Satisfaction data with details
		const satisfactionDetails = relevantSchedules
			.filter((schedule) => schedule.studentSatisfaction && typeof schedule.studentSatisfaction.average_score === "number")
			.map((schedule) => ({
				date: schedule.scheduleDate,
				value: schedule.studentSatisfaction!.average_score,
				scheduleDescription: schedule.scheduleDescription
			}));

		const satisfactionData = satisfactionDetails.map((detail) => detail.value);

		// Competency data with details
		// Extract all unique competency names (case-insensitive and ignore hyphens)
		const competencyNames = Array.from(
			new Set(
				relevantSchedules.flatMap((schedule) =>
					schedule.competencyProgress && Array.isArray(schedule.competencyProgress)
						? schedule.competencyProgress.map((competency) => normalizeCompetencyName(competency.name))
						: []
				)
			)
		);

		// For each competency, extract assessment scores
		const competencyData: { [key: string]: number[] } = {};
		const competencyDetails: { [key: string]: ReportScoreDataPoint[] } = {};

		competencyNames.forEach((name) => {
			const details = relevantSchedules
				.map((schedule) => {
					const competency = schedule.competencyProgress?.find((c) => normalizeCompetencyName(c.name) === name);
					if (competency && typeof competency.average_assessment === "number") {
						return {
							date: new Date(schedule.scheduleDate),
							value: competency.average_assessment,
							scheduleDescription: schedule.scheduleDescription
						};
					}
					return null;
				})
				.filter((detail): detail is { date: Date; value: number; scheduleDescription: string } => detail !== null);

			competencyDetails[name] = details;
			competencyData[name] = details.map((detail) => detail.value);
		});

		return { satisfactionData, satisfactionDetails, competencyData, competencyDetails };
	};

	const { satisfactionData, satisfactionDetails, competencyData, competencyDetails } = useMemo(
		() => generateReportScoreData(schedules, selectedScheduleId),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[schedules, selectedScheduleId]
	);

	const renderStudentSatisfaction = () => {
		if (!schedule || !schedule.studentSatisfaction || !schedule.studentSatisfaction.average_score) return null;

		const isExpanded = expandedTables["satisfaction"] || false;

		// Calculate percentages
		const satisfactionSegments = generateTimelineData("satisfaction");
		const totalCount = satisfactionSegments.length;
		const highCount = satisfactionSegments.filter((s) => s.variant === "high").length;
		const mediumCount = satisfactionSegments.filter((s) => s.variant === "medium").length;
		const lowCount = satisfactionSegments.filter((s) => s.variant === "low").length;

		return (
			<ReportCard
				title="Student Satisfaction"
				icon={<StarIcon className="h-5 w-5 text-[#16013e]" />}
				reportScoreData={satisfactionData}
				reportScoreDetails={satisfactionDetails}
				lineColor="#d4d4d8"
				currentScore={schedule.studentSatisfaction.average_score}
				isAdminView={true}
				showDataTable={true}
			>
				<p className="text-gray-600">{schedule.studentSatisfaction.aggregated_summary}</p>
				<div className="mt-6">
					{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
						<div className="flex items-center space-x-2">
							<Spinner className="h-4 w-4" />
							<span className="text-sm text-gray-500">Loading student details...</span>
						</div>
					) : (
						<>
							<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
								<span className="font-semibold text-gray-800">Distribution: </span>
								<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
									<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
										High: {((highCount / totalCount) * 100).toFixed(1)}%
									</div>
									<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
										Medium: {((mediumCount / totalCount) * 100).toFixed(1)}%
									</div>
									<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
										Low: {((lowCount / totalCount) * 100).toFixed(1)}%
									</div>
								</div>
							</div>
							<div className="mb-4 mt-4">
								<ScoreTimeline segments={satisfactionSegments} />
							</div>
							<Button
								variant="outline"
								size="sm"
								onClick={() => setExpandedTables((prev) => ({ ...prev, satisfaction: !prev.satisfaction }))}
								className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
							>
								<UsersIcon className="h-4 w-4" />
								<span>{isExpanded ? "Hide" : "Show"} Sources</span>
								{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
							</Button>
							{isExpanded && (
								<StudentDetailsTable
									sessions={Object.values(userSessions)}
									type="satisfaction"
									onNavigateToReport={() => console.log("navigating")}
								/>
							)}
						</>
					)}
				</div>
			</ReportCard>
		);
	};

	const renderCompetencyProgress = () => {
		if (!schedule || !schedule.competencyProgress || schedule.competencyProgress.length === 0) return null;

		const toggleTable = (competencyName: string) => {
			setExpandedTables((prev) => ({
				...prev,
				[competencyName]: !prev[competencyName]
			}));
		};

		return (
			<div className="my-12">
				{schedule.competencyProgress.map((competency, index) => {
					const normalizedName = normalizeCompetencyName(competency.name);
					const assessments = competencyData[normalizedName] || [];
					const details = competencyDetails[normalizedName] || [];
					const isExpanded = expandedTables[competency.name] || false;

					return (
						<ReportCard
							key={`competency-progress-${index}`}
							title={`Competency Progress: ${competency.name
								.split(" ")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								.join(" ")}`}
							icon={<BoltIcon className="h-5 w-5 text-[#16013e]" />}
							reportScoreData={assessments}
							reportScoreDetails={details}
							lineColor="#d4d4d8"
							currentScore={competency.average_assessment}
							isAdminView={true}
							showDataTable={true}
						>
							<p className="text-gray-600">{competency.aggregated_summary}</p>
							<div className="mt-6">
								{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
									<div className="flex items-center space-x-2">
										<Spinner className="h-4 w-4" />
										<span className="text-sm text-gray-500">Loading student details...</span>
									</div>
								) : (
									<>
										{/* Add this new section for competency distribution */}
										{(() => {
											const segments = generateTimelineData("competency", competency.name);
											const totalCount = segments.length;
											const highCount = segments.filter((s) => s.variant === "high").length;
											const mediumCount = segments.filter((s) => s.variant === "medium").length;
											const lowCount = segments.filter((s) => s.variant === "low").length;

											return (
												<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
													<span className="font-semibold text-gray-800">Distribution: </span>
													<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
														<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
															High: {((highCount / totalCount) * 100).toFixed(1)}%
														</div>
														<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
															Medium: {((mediumCount / totalCount) * 100).toFixed(1)}%
														</div>
														<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
															Low: {((lowCount / totalCount) * 100).toFixed(1)}%
														</div>
													</div>
												</div>
											);
										})()}
										<div className="mb-4 mt-4">
											<ScoreTimeline segments={generateTimelineData("competency", competency.name)} />
										</div>
										<Button
											variant="outline"
											size="sm"
											onClick={() => toggleTable(competency.name)}
											className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
										>
											<UsersIcon className="h-4 w-4" />
											<span>{isExpanded ? "Hide" : "Show"} Sources</span>
											{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
										</Button>
										{isExpanded && (
											<StudentDetailsTable
												sessions={Object.values(userSessions)}
												type="competency"
												competencyName={competency.name}
												onNavigateToReport={() => console.log("navigating")}
											/>
										)}
									</>
								)}
							</div>
						</ReportCard>
					);
				})}
			</div>
		);
	};

	const renderPurpose = () => {
		if (!schedule || !schedule.purpose || !schedule.purpose.progress) return null;

		const isExpanded = expandedTables["purpose"] || false;

		return (
			<ReportCard title="Purpose" icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}>
				<p className="text-gray-600 mb-4">{schedule.purpose.summary}</p>
				{schedule.purpose.progress.total_count > 0 && (
					<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
						<span className="font-semibold text-gray-800">Progress: </span>
						<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
							<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
								Yes: {((schedule.purpose.progress.yes_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
							</div>
							<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
								Partial: {((schedule.purpose.progress.partial_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
							</div>
							<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
								No: {((schedule.purpose.progress.no_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
							</div>
							{schedule.purpose.progress.new_count > 0 && (
								<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-blue-100 text-blue-800 border-blue-300">
									New: {((schedule.purpose.progress.new_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
								</div>
							)}
						</div>
					</div>
				)}
				{userSessions && Object.keys(userSessions).length > 0 && (
					<div className="mt-6">
						{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
							<div className="flex items-center space-x-2">
								<Spinner className="h-4 w-4" />
								<span className="text-sm text-gray-500">Loading student details...</span>
							</div>
						) : (
							<>
								<div className="mb-4">
									<ScoreTimeline segments={generateTimelineData("purpose")} />
								</div>
								<Button
									variant="outline"
									size="sm"
									onClick={() => setExpandedTables((prev) => ({ ...prev, purpose: !prev.purpose }))}
									className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
								>
									<UsersIcon className="h-4 w-4" />
									<span>{isExpanded ? "Hide" : "Show"} Sources</span>
									{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
								</Button>
								{isExpanded && (
									<StudentDetailsTable
										sessions={Object.values(userSessions)}
										type="purpose"
										onNavigateToReport={() => console.log("navigating")}
									/>
								)}
							</>
						)}
					</div>
				)}
			</ReportCard>
		);
	};

	const renderGoalProgress = () => {
		if (!schedule || !schedule.goalProgress || !schedule.goalProgress.goals) return null;

		const isExpanded = expandedTables["goalProgress"] || false;

		// Aggregate goal data
		const totalGoals = schedule.goalProgress.goals.total_count;
		const yesCount = schedule.goalProgress.goals.yes_count;
		const partialCount = schedule.goalProgress.goals.partial_count;
		const noCount = schedule.goalProgress.goals.no_count;

		return (
			<ReportCard title="Goal Progress" icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}>
				<p className="text-gray-600 mb-4">{schedule.goalProgress.summary}</p>
				<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
					<span className="font-semibold text-gray-800">Progress: </span>
					<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
							Yes: {((yesCount / totalGoals) * 100).toFixed(1)}%
						</div>
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
							Partial: {((partialCount / totalGoals) * 100).toFixed(1)}%
						</div>
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
							No: {((noCount / totalGoals) * 100).toFixed(1)}%
						</div>
					</div>
				</div>
				<div className="mb-4 mt-4">
					<ScoreTimeline segments={generateTimelineData("goalProgress")} />
				</div>
				<Button
					variant="outline"
					size="sm"
					onClick={() => setExpandedTables((prev) => ({ ...prev, goalProgress: !prev.goalProgress }))}
					className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				>
					<UsersIcon className="h-4 w-4" />
					<span>{isExpanded ? "Hide" : "Show"} Sources</span>
					{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
				</Button>
				{isExpanded && (
					<StudentDetailsTable
						sessions={Object.values(userSessions)}
						type="goalProgress"
						onNavigateToReport={() => console.log("navigating")}
					/>
				)}
			</ReportCard>
		);
	};

	return (
		<div className="email-report-post-session">
			{/* <BackButton /> */}
			<div className="flex items-center justify-between mb-4">
				<h2 className="text-2xl md:text-3xl font-bold text-[#16013e]">Aggregate Summary: {assignment?.assignmentTitle}</h2>
			</div>
			<Button variant="ghost" size="sm" className="p-0 mb-4" onClick={() => setIsDetailsVisible(!isDetailsVisible)}>
				{isDetailsVisible ? "Hide Assignment Details" : "Show Assignment Details"}
				{isDetailsVisible ? <ChevronUpIcon className="h-4 w-4 ml-1" /> : <ChevronDownIcon className="h-4 w-4 ml-1" />}
			</Button>
			{assignment &&
				isDetailsVisible && ( // Conditional rendering based on toggle state
					<div className="text-gray-600 mb-16">
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Course: </span>
							<span className="font-normal">{assignment.courseName}</span>
						</p>
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Experience Type: </span>
							<span className="font-normal">{assignment.experienceType}</span>
						</p>
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Code: </span>
							<span className="font-normal">{assignment.assignmentCode}</span>
						</p>
						<p className="text-sm">{assignment.assignmentDescription}</p>

						{assignment.competencies && assignment.competencies.length > 0 && (
							<div className="text-sm mt-4 flex items-center">
								<span className="font-semibold mr-2">Competencies:</span>
								<div className="font-normal flex flex-wrap">
									{assignment.competencies.map((competency) => (
										<Badge key={competency.id} className="mr-2 border border-[#00a9af]" variant="secondary">
											{competency.name}
										</Badge>
									))}
								</div>
							</div>
						)}
					</div>
				)}

			<div>
				{assignmentId && (
					<HorizontalTabs
						tabs={tabs}
						type="admin"
						selectedTab={selectedTab}
						assignmentId={assignmentId}
						userId={null}
						onTabSelect={setSelectedScheduleId}
					/>
				)}
			</div>
			{schedule && (
				// Add padding to the top of this div
				<div key={schedule.scheduleId + assignedSessions.length} className="pt-6 mb-4">
					<div className="flex justify-between items-center mb-4">
						<h2 className="text-2xl font-semibold">{schedule.scheduleDescription}</h2>
					</div>
					{!schedule.aggregateDataId && (
						<div className="mb-4">
							<p className="text-sm text-gray-600">
								The aggregate report will auto generate on{" "}
								{new Date(new Date(schedule.scheduleDate).getTime() + 48 * 60 * 60 * 1000).toLocaleDateString("en-US", {
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric"
								})}
							</p>
						</div>
					)}

					{renderMetrics()}
					{/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">{renderGoalStatusChart()}</div> */}
					<div className="mb-6">
						{schedule.flaggedComments?.negative && schedule.flaggedComments.negative.length > 0 && (
							<ReportCard title="Flagged Comments" icon={<ExclamationTriangleIcon className="h-5 w-5 text-[#16013e]" />}>
								<div className="space-y-4">
									{["high", "medium"].map((priority) => {
										const priorityComments = schedule.flaggedComments?.negative?.filter(
											(comment) => comment.priority === priority
										);
										if (priorityComments && priorityComments.length > 0) {
											return (
												<div
													key={priority}
													className={`${priority === "high" ? "bg-red-50" : "bg-yellow-50"} p-3 rounded-md`}
												>
													<h3 className={`font-semibold mb-2 ${priority === "high" ? "text-red-700" : "text-yellow-700"}`}>
														{priority === "medium" ? "Low" : priority.charAt(0).toUpperCase() + priority.slice(1)}{" "}
														Priority
													</h3>
													{priorityComments.map((comment, index) => (
														<p key={index} className="mb-2">
															• "{comment.comment}"
														</p>
													))}
												</div>
											);
										}
										return null;
									})}
								</div>
							</ReportCard>
						)}

						{renderStudentSatisfaction()}
						{renderCompetencyProgress()}

						{schedule.expectations && schedule.expectations.content && (
							<ReportCard title="Expectations" icon={<ClipboardDocumentCheckIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.expectations.content}</p>
								{schedule.expectations.outlook && schedule.expectations.outlook.total_count > 0 && (
									<div className="mt-4">
										<span className="font-semibold text-gray-800 mb-2">Outlook: </span>
										<div className="space-x-2 mt-2 ml-4">
											<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
												Positive:{" "}
												{(
													(schedule.expectations.outlook.positive_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
											<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
												Negative:{" "}
												{(
													(schedule.expectations.outlook.negative_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
											<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-600">
												Mixed:{" "}
												{(
													(schedule.expectations.outlook.mixed_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
										</div>
									</div>
								)}
							</ReportCard>
						)}
						{schedule.projectGoals && (
							<ReportCard title="Project Goals" icon={<FlagIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.projectGoals}</p>
							</ReportCard>
						)}
						{(schedule.activityType === ActivityType.MIDDLE || schedule.activityType === ActivityType.LAST) && renderGoalProgress()}
						{renderPurpose()}

						{schedule.finalReview && schedule.finalReview.takeaways && (
							<ReportCard title="Final Review" icon={<ClipboardDocumentListIcon className="h-5 w-5 text-[#16013e]" />}>
								{schedule.finalReview.takeaways && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-2">Key Takeaways</h3>
										<p className="text-gray-600 ml-4">{schedule.finalReview.takeaways}</p>
									</div>
								)}
								{schedule.finalReview.growth && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-2">Growth</h3>
										<p className="text-gray-600 ml-4">{schedule.finalReview.growth}</p>
									</div>
								)}
								{schedule.finalReview.feedback && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-4">Feedback</h3>
										<div className="space-y-3 ml-4">
											<div>
												<span className="font-medium text-gray-700">Valuable Aspects: </span>
												<span className="text-gray-600">{schedule.finalReview.feedback.valuableAspects}</span>
											</div>
											<div>
												<span className="font-medium text-gray-700">Contribution: </span>
												<span className="text-gray-600">{schedule.finalReview.feedback.contribution}</span>
											</div>
											<div>
												<span className="font-medium text-gray-700">Suggestions: </span>
												<span className="text-gray-600">{schedule.finalReview.feedback.suggestions}</span>
											</div>
											<div>
												<span className="font-medium text-gray-700">Preferred Method: </span>
												<span className="text-gray-600">
													Guided:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.guided /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%, Traditional:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.traditional /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%, Unknown:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.unknown /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%
												</span>
											</div>
											<div>
												<span className="font-medium text-gray-700">Avg Rating for Guided Reflection: </span>
												<span className="text-gray-600">{schedule.finalReview.feedback.rating}/10</span>
											</div>
										</div>
									</div>
								)}
							</ReportCard>
						)}

						{/* {schedule.overview && (
							<ReportCard title="Overview" icon={<DocumentTextIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.overview}</p>
							</ReportCard>
						)} */}

						<div className="mb-6 flex justify-between items-center"></div>
						{/* {assignment && assignment.status !== "Drafts" ? (
							<AssignedSessionsTable
								key={assignedSessions.length}
								length={assignedSessions.length}
								assignedSessions={assignedSessions}
								onDownloadCSV={downloadAssignedSessionsCSV}
								onAddUser={handleAddUser}
								onEditUser={handleEditUser}
								onRemoveUser={handleRemoveUser}
							/>
						) : (
							<>
								<h2 className="text-xl font-semibold">Assigned Sessions</h2>

								<div className="text-gray-500 italic">
									The Assigned Sessions table will be displayed here once the assignment is no longer in draft status.
								</div>
							</>
						)} */}
					</div>

					{/* <div className="mb-6">
						<h3 className="text-lg font-semibold mb-2">Assigned Sessions</h3>
						<AssignedSessionsTable assignedSessions={assignedSessions} />
					</div> */}
				</div>
			)}
		</div>
	);
};

export default EmailReportPostAssignment;
