import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../components/shadcn-ui/Dialog";
import { Button } from "../../components/shadcn-ui/Button";
import { Input } from "../../components/shadcn-ui/Input";
import { Textarea } from "../../components/shadcn-ui/Textarea";
import { format, parseISO } from "date-fns";
import { ConferenceService } from "../../services/ConferenceService";
import { DataTable, ExtendedColumnDef, Filter } from "../../components/shadcn-ui/DataTable";
 
interface ConferenceSession {
	id: string;
	userId: string;
	createdAt: string;
	activityType: string;
}

interface Conference {
	id: string;
	name: string;
	description: string;
	city: string;
	startDate: Date;
	endDate: Date;
	createdAt: Date;
	updatedAt: Date;
	version: number;
	sessions: ConferenceSession[];
}

const ConferenceManager: React.FC = () => {
 	const [conferences, setConferences] = useState<Conference[]>([]);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const [selectedConference, setSelectedConference] = useState<Conference | null>(null);
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		city: "",
		startDate: "",
		endDate: ""
	});

	useEffect(() => {
		loadConferences();
	}, []);

	const loadConferences = async () => {
		try {
			const data = await ConferenceService.getAllConferences();
			const conferencesWithDates = data.map((conference) => ({
				...conference,
				startDate: new Date(conference.startDate),
				endDate: new Date(conference.endDate),
				createdAt: new Date(conference.createdAt),
				updatedAt: new Date(conference.updatedAt)
			}));
			setConferences(conferencesWithDates);
		} catch (error) {
			console.error("Error loading conferences:", error);
		}
	};

	const formatDateForDisplay = (dateString: string | Date) => {
		const date = typeof dateString === "string" ? parseISO(dateString) : dateString;
		const offsetDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
		return format(offsetDate, "MMM dd, yyyy");
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const formattedData = {
				...formData,
				startDate: new Date(formData.startDate).setUTCHours(0, 0, 0, 0),
				endDate: new Date(formData.endDate).setUTCHours(23, 59, 59, 999)
			};

			if (selectedConference) {
				await ConferenceService.updateConference(selectedConference.id, formattedData);
			} else {
				await ConferenceService.createConference(formattedData);
			}
			setIsAddDialogOpen(false);
			setSelectedConference(null);
			resetForm();
			loadConferences();
		} catch (error) {
			console.error("Error submitting conference:", error);
		}
	};

	const handleDelete = async (id: string) => {
		if (window.confirm("Are you sure you want to delete this conference?")) {
			try {
				await ConferenceService.deleteConference(id);
				loadConferences();
			} catch (error) {
				console.error("Error deleting conference:", error);
			}
		}
	};

	const handleEdit = (conference: Conference) => {
		setSelectedConference(conference);
		const startDate = new Date(conference.startDate.getTime() + conference.startDate.getTimezoneOffset() * 60 * 1000);
		const endDate = new Date(conference.endDate.getTime() + conference.endDate.getTimezoneOffset() * 60 * 1000);

		setFormData({
			name: conference.name,
			description: conference.description,
			city: conference.city,
			startDate: format(startDate, "yyyy-MM-dd"),
			endDate: format(endDate, "yyyy-MM-dd")
		});
		setIsAddDialogOpen(true);
	};

	const resetForm = () => {
		setFormData({
			name: "",
			description: "",
			city: "",
			startDate: "",
			endDate: ""
		});
	};

	const columns: ExtendedColumnDef<Conference, any>[] = [
		{
			accessorKey: "name",
			header: "Name"
		},
		{
			accessorKey: "description",
			header: "Description",
			cell: ({ row }) => {
				const description = row.getValue("description") as string;
				return (
					<div className="max-w-[300px] truncate" title={description}>
						{description}
					</div>
				);
			}
		},
		{
			accessorKey: "city",
			header: "City"
		},
		{
			accessorKey: "startDate",
			header: "Start Date",
			cell: ({ row }) => {
				const date = row.getValue("startDate") as Date;
				return formatDateForDisplay(date);
			}
		},
		{
			accessorKey: "endDate",
			header: "End Date",
			cell: ({ row }) => {
				const date = row.getValue("endDate") as Date;
				return formatDateForDisplay(date);
			}
		},
		{
			accessorKey: "createdAt",
			header: "Created",
			cell: ({ row }) => {
				const date = row.getValue("createdAt") as Date;
				return formatDateForDisplay(date);
			},
			initiallyHidden: true
		},
		{
			accessorKey: "updatedAt",
			header: "Last Updated",
			cell: ({ row }) => {
				const date = row.getValue("updatedAt") as Date;
				return formatDateForDisplay(date);
			},
			initiallyHidden: true
		},
		{
			id: "actions",
			cell: ({ row }) => {
				const conference = row.original;
				const hasNoSessions = conference.sessions.length === 0;

				return (
					<div className="flex gap-2">
						<Button variant="outline" size="sm" onClick={() => handleEdit(conference)}>
							Edit
						</Button>
						{hasNoSessions ? (
							<Button variant="destructive" size="sm" onClick={() => handleDelete(conference.id)}>
								Delete
							</Button>
						) : (
							<Button variant="destructive" size="sm" disabled title="Cannot delete conference with existing sessions">
								Delete
							</Button>
						)}
					</div>
				);
			}
		}
	];

	const columnsWithSessions: ExtendedColumnDef<Conference, any>[] = [
		...columns.slice(0, -1),
		{
			accessorKey: "sessions",
			header: "Sessions",
			cell: ({ row }) => row.original.sessions.length
		},
		columns[columns.length - 1]
	];

	const filters: Filter[] = [
		{
			columnId: "name",
			type: "input",
			placeholder: "Search by name"
		},
		{
			columnId: "city",
			type: "input",
			placeholder: "Search by city"
		},
		{
			columnId: "startDate",
			type: "dateRange",
			placeholder: "Filter by date"
		}
	];

	return (
		<div className="container mx-auto py-10">
			<div className="flex justify-between items-center mb-6">
				<h1 className="text-2xl font-bold">Conference Manager</h1>
				<Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
					<DialogTrigger asChild>
						<Button
							onClick={() => {
								setSelectedConference(null);
								resetForm();
							}}
						>
							Add Conference
						</Button>
					</DialogTrigger>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>{selectedConference ? "Edit Conference" : "Add New Conference"}</DialogTitle>
						</DialogHeader>
						<form onSubmit={handleSubmit} className="space-y-4">
							<div>
								<label className="block text-sm font-medium mb-1">
									Name <span className="text-red-500">*</span>
								</label>
								<Input value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
							</div>
							<div>
								<label className="block text-sm font-medium mb-1">
									Description <span className="text-red-500">*</span>
								</label>
								<Textarea
									value={formData.description}
									onChange={(e) => setFormData({ ...formData, description: e.target.value })}
									required
								/>
							</div>
							<div>
								<label className="block text-sm font-medium mb-1">
									City <span className="text-red-500">*</span>
								</label>
								<Input value={formData.city} onChange={(e) => setFormData({ ...formData, city: e.target.value })} required />
							</div>
							<div>
								<label className="block text-sm font-medium mb-1">
									Start Date <span className="text-red-500">*</span>
								</label>
								<Input
									type="date"
									value={formData.startDate}
									onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
									required
								/>
							</div>
							<div>
								<label className="block text-sm font-medium mb-1">
									End Date <span className="text-red-500">*</span>
								</label>
								<Input
									type="date"
									value={formData.endDate}
									onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
									required
								/>
							</div>
							<div className="flex justify-end gap-2">
								<Button type="button" variant="outline" onClick={() => setIsAddDialogOpen(false)}>
									Cancel
								</Button>
								<Button type="submit">{selectedConference ? "Update" : "Create"}</Button>
							</div>
						</form>
					</DialogContent>
				</Dialog>
			</div>

			<DataTable columns={columnsWithSessions} data={conferences} filters={filters} pagination={true} />
		</div>
	);
};

export default ConferenceManager;
