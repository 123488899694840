import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Field, ErrorMessage, FieldArray } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon, PlusIcon, TrashIcon, ChevronDownIcon, ChevronUpIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../shadcn-ui/Button";
import CustomTooltip from "../../../Utility/CustomTooltip";

interface Session {
	title: string;
	date: Date | null;
	startDate: Date | null;
}

interface Values {
	sessions: Session[];
	[key: string]: any;
}

interface SessionErrors {
	date?: string;
}

interface DateRange {
	startDate: Date | null;
	endDate: Date | null;
}

type FrequencyOption = "weekly" | "biweekly" | "custom";

export interface JobSearchDatesProps {
	values: Values;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

interface DateRangePickerProps {
	startDate: Date | null;
	endDate: Date | null;
	onStartDateChange: (date: Date | null) => void;
	onEndDateChange: (date: Date | null) => void;
	disabled?: boolean;
	minDate?: Date;
	maxDate?: Date;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, endDate, onStartDateChange, onEndDateChange, disabled, minDate, maxDate }) => {
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");

	// Convert null to undefined for react-datepicker
	const convertNullToUndefined = (date: Date | null): Date | undefined => {
		return date || undefined;
	};

	// Helper function to set default start date
	const handleEndDateChange = (date: Date | null) => {
		onEndDateChange(date);
		// Only set default start date if there isn't already a start date
		// or if the current start date is after the new end date
		if (date) {
			if (!startDate || startDate > date) {
				const defaultStartDate = new Date(date);
				defaultStartDate.setDate(defaultStartDate.getDate() - 5);
				onStartDateChange(defaultStartDate);
			}
		}
	};

	// Validate start date is not after end date
	const handleStartDateChange = (date: Date | null) => {
		if (date && endDate && date > endDate) {
			// If selected start date is after end date, don't allow it
			return;
		}
		onStartDateChange(date);
	};

	return (
		<div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
			<div className="flex-1">
				<label className="flex items-center text-sm font-medium text-gray-700 mb-1">
					Due Date*
					<CustomTooltip
						content={
							isAdminPath
								? "Students will have until midnight eastern time to complete their call"
								: "You will have until midnight eastern time to complete your call"
						}
					/>
				</label>
				<div className="relative">
					<DatePicker
						selected={endDate}
						onChange={handleEndDateChange}
						selectsEnd
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						minDate={minDate}
						maxDate={maxDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-10 pr-3 py-2 text-gray-900"
						placeholderText="Select due date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
			<div className="flex-1">
				<label className="block text-sm font-medium text-gray-700 mb-1">Start of Availability Window*</label>
				<div className="relative">
					<DatePicker
						selected={startDate}
						onChange={handleStartDateChange}
						selectsStart
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						maxDate={endDate || undefined}
						minDate={minDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-10 pr-3 py-2 text-gray-900"
						placeholderText="Select start date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
		</div>
	);
};

const PeriodSelector: React.FC<{
	dateRange: DateRange;
	onDateRangeChange: (range: DateRange) => void;
	disabled?: boolean;
	startDateTooltip?: string;
	endDateTooltip?: string;
}> = ({ dateRange, onDateRangeChange, disabled, startDateTooltip, endDateTooltip }) => {
	const handleDateChange = (field: "startDate" | "endDate", date: Date | null) => {
		// If changing start date and it's after end date, clear end date
		if (field === "startDate" && date && dateRange.endDate && date > dateRange.endDate) {
			onDateRangeChange({ startDate: date, endDate: null });
			return;
		}

		// If changing end date and it's before start date, clear start date
		if (field === "endDate" && date && dateRange.startDate && date < dateRange.startDate) {
			onDateRangeChange({ startDate: null, endDate: date });
			return;
		}

		onDateRangeChange({ ...dateRange, [field]: date });
	};

	return (
		<div className="bg-white mb-6 flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
			<div className="flex-1">
				<div className="flex items-center mb-1">
					<label className="text-sm font-medium text-gray-700">Start Date*</label>
					{startDateTooltip && <CustomTooltip content={startDateTooltip} />}
				</div>
				<div className="relative">
					<DatePicker
						selected={dateRange.startDate}
						onChange={(date) => handleDateChange("startDate", date)}
						minDate={new Date()}
						className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-10 pr-3 py-2"
						disabled={disabled}
						placeholderText="Select start date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
			<div className="flex-1">
				<div className="flex items-center mb-1">
					<label className="text-sm font-medium text-gray-700">End Date*</label>
					{endDateTooltip && <CustomTooltip content={endDateTooltip} />}
				</div>
				<div className="relative">
					<DatePicker
						selected={dateRange.endDate}
						onChange={(date) => handleDateChange("endDate", date)}
						minDate={dateRange.startDate || new Date()}
						className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-10 pr-3 py-2"
						disabled={disabled}
						placeholderText="Select end date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
		</div>
	);
};

const FrequencySelector: React.FC<{
	frequency: FrequencyOption | null;
	onFrequencyChange: (freq: FrequencyOption) => void;
	dateRange: DateRange;
	disabled?: boolean;
}> = ({ frequency, onFrequencyChange, dateRange, disabled }) => {
	const getAvailableFrequencies = (): FrequencyOption[] => {
		if (!dateRange.startDate || !dateRange.endDate) return [];

		const daysDiff = Math.floor((dateRange.endDate.getTime() - dateRange.startDate.getTime()) / (1000 * 60 * 60 * 24));
		const frequencies: FrequencyOption[] = [];

		// If less than 14 days, return only custom
		if (daysDiff < 14) return ["custom"];

		// For longer periods, include all applicable frequencies
		if (daysDiff >= 14) frequencies.push("weekly");
		if (daysDiff >= 28) frequencies.push("biweekly");
		frequencies.push("custom"); // Always include custom as an option

		return frequencies;
	};

	const availableFrequencies = getAvailableFrequencies();
	const isCustomPeriod = availableFrequencies.length === 1 && availableFrequencies[0] === "custom";

	// If it's a custom period (less than 14 days), show only "custom" option
	if (isCustomPeriod) {
		return (
			<div className="bg-white border border-[#eaecf0] rounded-lg p-6 mb-6">
				<div className="flex items-center">
					<input
						type="radio"
						id="custom"
						name="frequency"
						value="custom"
						checked={true}
						disabled={true}
						className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
					/>
					<label htmlFor="custom" className="ml-3 block text-sm font-medium text-gray-700">
						Custom
					</label>
				</div>
			</div>
		);
	}

	return (
		<div className="bg-white border border-[#eaecf0] rounded-lg p-6 mb-6">
			<div className="space-y-4">
				{availableFrequencies.map((freq) => (
					<div key={freq} className="flex items-center">
						<input
							type="radio"
							id={freq}
							name="frequency"
							value={freq}
							checked={frequency === freq}
							onChange={() => onFrequencyChange(freq)}
							disabled={disabled}
							className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
						/>
						<label htmlFor={freq} className="ml-3 block text-sm font-medium text-gray-700 capitalize">
							{freq}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

// JobSearchDates component for configuring sessions
const JobSearchDates: React.FC<JobSearchDatesProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onNext,
	onPrevious,
	isValid,
	dirty,
	isEditMode
}) => {
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");

	// Ensure values.sessions is always an array
	const sessions = useMemo(() => (Array.isArray(values.sessions) ? values.sessions : []), [values.sessions]);

	// Initialize sessions if not already set
	useEffect(() => {
		if (!sessions || sessions.length < 3) {
			setFieldValue("sessions", [
				{ title: "First Call", date: null, startDate: null },
				{ title: "Middle Call", date: null, startDate: null },
				{ title: "Final Call", date: null, startDate: null }
			]);
		}
	}, [sessions, setFieldValue]);

	// Helper function to get session date
	const getSessionDate = (index: number): Date | null | undefined => {
		return sessions && sessions[index] ? sessions[index].date : undefined;
	};

	// Check if the form is valid
	const isFormValid = () => {
		if (isEditMode) return true;
		if (isPublished) return true;
		// Check if all sessions have a date and start date
		return sessions?.every((session) => session?.date && session?.startDate) ?? false;
	};

	const isPublished = values.isPublished;

	// Helper function to set default start date
	const setDefaultStartDate = (dueDate: Date | null, index: number) => {
		if (dueDate) {
			const defaultStartDate = new Date(dueDate);
			defaultStartDate.setDate(defaultStartDate.getDate() - 5);
			setFieldValue(`sessions[${index}].startDate`, defaultStartDate);
		} else {
			setFieldValue(`sessions[${index}].startDate`, null);
		}
	};

	// Update the handleDateChange function
	const handleDateChange = (index: number, date: Date | null) => {
		if (!date) return;

		const updatedSessions = [...sessions];
		updatedSessions[index] = {
			...updatedSessions[index],
			date: date
		};

		// Set default start date for the current session
		setDefaultStartDate(date, index);

		// Clear dates for all subsequent sessions, including the final session
		for (let i = index + 1; i < updatedSessions.length; i++) {
			updatedSessions[i] = {
				...updatedSessions[i],
				date: null,
				startDate: null
			};
		}

		setFieldValue("sessions", updatedSessions);
	};

	const addNewCall = () => {
		const updatedSessions = [...sessions];
		const newIndex = updatedSessions.length - 1;

		// Add new mid session
		updatedSessions.splice(newIndex, 0, { title: `Middle Call ${newIndex}`, date: null, startDate: null });

		// Clear final session dates and any subsequent mid-session dates
		for (let i = newIndex; i < updatedSessions.length; i++) {
			updatedSessions[i] = {
				...updatedSessions[i],
				date: null,
				startDate: null
			};
		}

		setFieldValue("sessions", updatedSessions);
	};

	const validateSessionDate = (index: number, date: Date | null) => {
		const sessions = values.sessions || [];

		// Helper function to compare dates ignoring time
		const isSameOrBefore = (date1: Date, date2: Date) => {
			const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
			const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
			return d1 <= d2;
		};

		// If there's a date, validate it against the rules
		if (date) {
			if (index === 0) {
				// First session with date: no validation needed
				return undefined;
			} else if (index === sessions.length - 1) {
				// Final session with date: check against last mid-session
				const lastMidSessionDate = sessions[sessions.length - 2]?.date;
				if (lastMidSessionDate && isSameOrBefore(date, lastMidSessionDate)) {
					return "Final call due date must be at least one day after the last middle call";
				}
			} else {
				// Mid-session with date: check against previous and next
				const previousDate = sessions[index - 1]?.date;
				if (previousDate && isSameOrBefore(date, previousDate)) {
					return "Due date must be at least one day after the previous session";
				}
				const nextDate = sessions[index + 1]?.date;
				if (nextDate && date >= nextDate) {
					return "Due date must be before the next session";
				}
			}
			return undefined;
		}

		if (index === 0) {
			return "Due date is required";
		}
	};

	// Add this helper function at the beginning of the component
	const getFinalSessionIndex = () => sessions.length - 1;

	// Add this helper function to calculate the minimum date for a session
	const getMinDateForSession = (index: number): Date => {
		if (index === 0) {
			// First session can't be earlier than today
			return new Date();
		}

		// For all other sessions, must be at least one day after previous session
		const previousDate = getSessionDate(index - 1);
		if (previousDate) {
			const minDate = new Date(previousDate);
			minDate.setDate(minDate.getDate() + 1);
			return minDate;
		}

		return new Date();
	};

	// Add these new states
	const [dateRange, setDateRange] = useState<DateRange>({ startDate: null, endDate: null });
	const [frequency, setFrequency] = useState<FrequencyOption | null>(null);

	// Add state for panel visibility
	const [openPanels, setOpenPanels] = useState({
		period: true,
		frequency: false,
		firstSession: false,
		middleSession: false,
		finalSession: false
	});

	// Helper to toggle panel state
	const togglePanel = (panel: keyof typeof openPanels) => {
		setOpenPanels((prev) => ({ ...prev, [panel]: !prev[panel] }));
	};

	// Effect to handle panel visibility based on selections
	useEffect(() => {
		// If either date is cleared, reset everything
		if (!dateRange.startDate || !dateRange.endDate) {
			setFrequency(null);
			setOpenPanels({
				period: true,
				frequency: false,
				firstSession: false,
				middleSession: false,
				finalSession: false
			});
			return;
		}

		// Calculate days difference
		const daysDiff = Math.floor((dateRange.endDate.getTime() - dateRange.startDate.getTime()) / (1000 * 60 * 60 * 24));

		// Reset frequency and collapse all panels except period
		setFrequency(null);
		setOpenPanels({
			period: false,
			frequency: true,
			firstSession: false,
			middleSession: false,
			finalSession: false
		});

		// Reset all session dates
		setFieldValue("sessions", [
			{ title: "First Call", date: null, startDate: null },
			{ title: "Middle Call", date: null, startDate: null },
			{ title: "Final Call", date: null, startDate: null }
		]);

		// If less than 14 days, automatically set custom frequency
		if (daysDiff < 14) {
			setFrequency("custom" as FrequencyOption);
			setOpenPanels({
				period: false,
				frequency: false,
				firstSession: true,
				middleSession: true,
				finalSession: true
			});
		}
	}, [dateRange.startDate, dateRange.endDate, setFieldValue]);

	// Add another useEffect to handle frequency selection
	useEffect(() => {
		if (frequency) {
			setOpenPanels((prev) => ({
				...prev,
				frequency: false, // Close frequency panel
				firstSession: true,
				middleSession: true,
				finalSession: true
			}));
		}
	}, [frequency]);

	// Add this new function to calculate session dates
	const calculateJobSearchDates = useCallback(
		(range: DateRange, freq: FrequencyOption) => {
			if (!range.startDate || !range.endDate || !freq) return;

			const dates: Date[] = [range.startDate];
			let currentDate = new Date(range.startDate);

			if (freq === "custom") {
				// For custom frequency, only use start and end dates
				dates.push(range.endDate);
			} else {
				const interval = {
					weekly: 7,
					biweekly: 14,
					monthly: 30
				}[freq];

				while (currentDate < range.endDate) {
					currentDate = new Date(currentDate);
					currentDate.setDate(currentDate.getDate() + interval);
					if (currentDate < range.endDate) {
						dates.push(new Date(currentDate));
					}
				}
				dates.push(range.endDate);
			}

			// Update sessions with calculated dates
			const newSessions = dates.map((date, index) => ({
				title: index === 0 ? "First Call" : index === dates.length - 1 ? "Final Call" : `Middle Call ${index}`,
				date,
				startDate: new Date(new Date(date).setDate(date.getDate() - 5))
			}));

			setFieldValue("sessions", newSessions);
		},
		[setFieldValue]
	);

	// Add effect to update dates when frequency changes
	useEffect(() => {
		if (dateRange.startDate && dateRange.endDate && frequency) {
			calculateJobSearchDates(dateRange, frequency);
		}
	}, [dateRange, frequency, calculateJobSearchDates]);

	return (
		<div className="space-y-4">
			{/* Welcome Panel */}
			<Panel title="Configure Your Job Search Assignment">
				{isAdminPath ? (
					<>
						<p className="text-base text-gray-600 mb-4">Welcome to the Job Search Assignment setup! Over the next few steps, you'll:</p>
						<ol className="list-decimal list-inside text-base text-gray-600 mb-4 space-y-2">
							<li>Set a start and end date for the job search period.</li>
							<li>Choose how frequently students should check in—or manually select specific dates.</li>
							<li>Name the assignment, decide how to invite students, and configure notifications.</li>
							<li>Review everything and deploy the assignment.</li>
						</ol>
						<p className="text-base text-gray-600">
							Let's begin by defining the overall job search period that aligns with your program's timeline.
						</p>
					</>
				) : (
					<>
						<p className="text-base text-gray-600 mb-4">Welcome to your self-guided job search setup! Over the next few steps, you'll:</p>
						<ol className="list-decimal list-inside text-base text-gray-600 mb-4 space-y-2">
							<li>Set a start and end date for your job search period.</li>
							<li>Choose how frequently you want to check in—or manually select specific dates.</li>
							<li>Name your job search series and provide the necessary information.</li>
							<li>Review everything and begin your job search journey.</li>
						</ol>
						<p className="text-base text-gray-600">
							Let's begin by defining the overall job search period that aligns with your personal timeline.
						</p>
					</>
				)}
			</Panel>

			{/* Period Selection Panel */}
			<Panel
				title="1. Select Job Search Period"
				isOpen={openPanels.period}
				onToggle={() => togglePanel("period")}
				isValid={!!(dateRange.startDate && dateRange.endDate)}
				isEnabled={!isEditMode}
				tooltip="Define the overall timeframe for your job search assignment."
			>
				{!isEditMode && (
					<PeriodSelector
						dateRange={dateRange}
						onDateRangeChange={setDateRange}
						disabled={isPublished}
						startDateTooltip="Choose when the job search period begins (e.g., start of the co-op/internship/course)."
						endDateTooltip="Choose when the job search period ends (e.g., end of the co-op/internship/course)."
					/>
				)}
			</Panel>

			{/* Frequency Selection Panel */}
			<Panel
				title="2. Select Call Frequency"
				isOpen={openPanels.frequency}
				onToggle={() => togglePanel("frequency")}
				isEnabled={!!(dateRange.startDate && dateRange.endDate)}
				isValid={!!frequency}
				tooltip="Select a repeating interval to create evenly spaced job search check-in dates."
			>
				<p className="text-base text-gray-600 mb-4">
					Set a frequency to auto-generate job search check-in dates. If the period is too short or you prefer not to use a frequency,
					you'll schedule calls manually in the next step.
				</p>
				<FrequencySelector frequency={frequency} onFrequencyChange={setFrequency} dateRange={dateRange} disabled={isPublished} />
				{dateRange.startDate &&
					dateRange.endDate &&
					Math.floor((dateRange.endDate.getTime() - dateRange.startDate.getTime()) / (1000 * 60 * 60 * 24)) < 14 && (
						<p className="text-sm text-gray-600 italic mt-4">
							The selected period is too short for multiple calls at a set frequency. You'll pick specific call dates in the next step.
						</p>
					)}
			</Panel>

			{/* First Session Panel */}
			<Panel
				title="3. First Call"
				tooltip="Students discuss their upcoming experiences and set goals in this initial call."
				isOpen={openPanels.firstSession}
				onToggle={() => togglePanel("firstSession")}
				isEnabled={!!frequency}
				isValid={!!(values.sessions?.[0]?.date && values.sessions?.[0]?.startDate)}
			>
				<div className="space-y-4">
					<Field
						name="sessions[0]"
						validate={(value: any) => {
							const errors: SessionErrors = {};
							const dateError = validateSessionDate(0, values.sessions[0]?.date);
							if (dateError) errors.date = dateError;
							return Object.keys(errors).length ? errors : undefined;
						}}
					>
						{({ field, form, meta }: any) => (
							<DateRangePicker
								startDate={values.sessions[0]?.startDate || null}
								endDate={values.sessions[0]?.date || null}
								onStartDateChange={(date) => setFieldValue("sessions[0].startDate", date)}
								onEndDateChange={(date) => handleDateChange(0, date)}
								disabled={isPublished}
								minDate={getMinDateForSession(0)}
								maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
							/>
						)}
					</Field>
					<ErrorMessage name="sessions[0].date" component="div" className="mt-1 text-sm text-red-600" />
					<ErrorMessage name="sessions[0].startDate" component="div" className="mt-1 text-sm text-red-600" />
				</div>
			</Panel>

			{/* Middle Session Panel */}
			<Panel
				title="4. Middle Call(s)"
				tooltip="Students check in on their ongoing job search experiences and progress since their last call."
				isOpen={openPanels.middleSession}
				onToggle={() => togglePanel("middleSession")}
				isEnabled={!!frequency}
				isValid={values.sessions?.slice(1, -1).every((session) => session.date && session.startDate)}
			>
				<FieldArray name="sessions">
					{({ push, remove }) => (
						<div>
							{sessions?.slice(1, -1).map((session: Session, index: number) => (
								<div key={index + 1} className="flex items-start bg-gray-50 p-4 rounded-md mb-4">
									<div className="flex-grow">
										<Field
											name={`sessions[${index + 1}]`}
											validate={(value: any) => {
												const errors: SessionErrors = {};
												const dateError = validateSessionDate(index + 1, values.sessions[index + 1]?.date);
												if (dateError) errors.date = dateError;
												return Object.keys(errors).length ? errors : undefined;
											}}
										>
											{({ field, form, meta }: any) => (
												<DateRangePicker
													startDate={values.sessions[index + 1]?.startDate || null}
													endDate={values.sessions[index + 1]?.date || null}
													onStartDateChange={(date) => setFieldValue(`sessions[${index + 1}].startDate`, date)}
													onEndDateChange={(date) => handleDateChange(index + 1, date)}
													disabled={isPublished}
													minDate={getMinDateForSession(index + 1)}
													maxDate={getSessionDate(index + 2) || undefined}
												/>
											)}
										</Field>
										<ErrorMessage name={`sessions[${index + 1}].date`} component="div" className="mt-1 text-sm text-red-600" />
										<ErrorMessage
											name={`sessions[${index + 1}].startDate`}
											component="div"
											className="mt-1 text-sm text-red-600"
										/>
									</div>
									{index > 0 && (
										<button
											type="button"
											onClick={() => remove(index + 1)}
											className="mt-8 ml-4 text-gray-400 hover:text-gray-600"
										>
											<TrashIcon className="h-5 w-5" />
										</button>
									)}
								</div>
							))}
							{sessions?.length < 11 && !isPublished && (
								<div className="flex items-center mt-4">
									<Button variant="outline" onClick={addNewCall} className="text-primary-900 border-primary-900">
										<PlusIcon className="h-5 w-5 mr-2 text-primary-900" />
										<span className="text-primary-900">Add Another Call</span>
									</Button>
								</div>
							)}
						</div>
					)}
				</FieldArray>
			</Panel>

			{/* Final Session Panel */}
			<Panel
				title="5. Final Call"
				tooltip="Students reflect on their overall learning experience and outcomes in this concluding call."
				isOpen={openPanels.finalSession}
				onToggle={() => togglePanel("finalSession")}
				isEnabled={!!frequency}
				isValid={!!(values.sessions?.[values.sessions.length - 1]?.date && values.sessions?.[values.sessions.length - 1]?.startDate)}
			>
				<div className={`bg-white border border-[#eaecf0] rounded-lg p-6`}>
					<Field
						name={`sessions[${getFinalSessionIndex()}]`}
						validate={(value: any) => {
							const errors: SessionErrors = {};
							const dateError = validateSessionDate(getFinalSessionIndex(), values.sessions[getFinalSessionIndex()]?.date);
							if (dateError) errors.date = dateError;
							return Object.keys(errors).length ? errors : undefined;
						}}
					>
						{({ field, form, meta }: any) => (
							<DateRangePicker
								startDate={values.sessions[getFinalSessionIndex()]?.startDate || null}
								endDate={values.sessions[getFinalSessionIndex()]?.date || null}
								onStartDateChange={(date) => setFieldValue(`sessions[${getFinalSessionIndex()}].startDate`, date)}
								onEndDateChange={(date) => handleDateChange(getFinalSessionIndex(), date)}
								disabled={isPublished}
								minDate={getMinDateForSession(getFinalSessionIndex())}
								maxDate={undefined}
							/>
						)}
					</Field>
					<ErrorMessage name={`sessions[${getFinalSessionIndex()}].date`} component="div" className="mt-1 text-sm text-red-600" />
					<ErrorMessage name={`sessions[${getFinalSessionIndex()}].startDate`} component="div" className="mt-1 text-sm text-red-600" />
				</div>
			</Panel>

			{/* Navigation buttons */}
			<div className="block md:flex justify-end mt-8 gap-2">
				<Button
					name="next"
					aria-label="Next"
					value="Next"
					onClick={() => {
						onNext();
					}}
					disabled={!isFormValid()}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

interface PanelProps {
	title: string;
	tooltip?: string;
	children: React.ReactNode;
	isOpen?: boolean;
	onToggle?: () => void;
	isEnabled?: boolean;
	isValid?: boolean;
}

const Panel: React.FC<PanelProps> = ({ title, tooltip, children, isOpen, onToggle, isEnabled = true, isValid }) => (
	<div className={`bg-white border rounded-lg ${!isEnabled ? "border-gray-200" : isValid ? "border-[#00a9af]" : "border-incomplete"}`}>
		{isOpen !== undefined ? (
			<button
				type="button"
				className={`w-full px-6 py-4 text-left flex justify-between items-center ${isEnabled ? "" : "opacity-50 cursor-not-allowed"}`}
				onClick={onToggle}
				disabled={!isEnabled}
			>
				<div className="flex items-center">
					<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
					{tooltip && (
						<span
							className="flex items-center justify-center"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<CustomTooltip content={tooltip} />
						</span>
					)}
				</div>
				<div className="flex items-center space-x-2">
					{isEnabled &&
						isValid !== undefined &&
						(isValid ? (
							<CheckCircleIcon className="w-5 h-5 text-[#00a9af]" />
						) : (
							<CheckCircleOutlineIcon className="w-5 h-5 text-incomplete" />
						))}
					{isEnabled &&
						(isOpen ? <ChevronUpIcon className="w-5 h-5 text-gray-500" /> : <ChevronDownIcon className="w-5 h-5 text-gray-500" />)}
				</div>
			</button>
		) : (
			<div className={`w-full px-6 py-4 text-left flex justify-between items-center ${isEnabled ? "" : "opacity-50"}`}>
				<div className="flex items-center">
					<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
					{tooltip && (
						<span className="flex items-center justify-center">
							<CustomTooltip content={tooltip} />
						</span>
					)}
				</div>
				<div className="flex items-center space-x-2">
					{isEnabled &&
						isValid !== undefined &&
						(isValid ? (
							<CheckCircleIcon className="w-5 h-5 text-[#00a9af]" />
						) : (
							<CheckCircleOutlineIcon className="w-5 h-5 text-incomplete" />
						))}
				</div>
			</div>
		)}
		{(isOpen === undefined || isOpen) && <div className="px-6 pb-6">{children}</div>}
	</div>
);

export default JobSearchDates;
