// ... existing imports ...
import usePhoneVerification from "./usePhoneVerification";
import { TextMessageDisclaimer, PhoneInputForm, VerificationCodeInputs, VerificationHeader } from "./phoneVerificationComponents";
import { Card, CardContent, CardTitle } from "../../shadcn-ui/Card";

interface PhoneVerificationProps {
	onVerificationComplete: (phoneNumber: string) => void;
	onCancel: () => void;
}
const PhoneVerificationV2: React.FC<PhoneVerificationProps> = ({ onVerificationComplete, onCancel }) => {
	const {
		phoneNumber,
		countryCode,
		verificationCode,
		isConsentChecked,
		error,
		numberValid,
		handlePhoneChange,
		handleSubmit,
		setCountryCode,
		setIsConsentChecked,
		handleVerificationCodeChange,
		handleVerificationCodeKeyDown,
		handleVerificationCodePaste
	} = usePhoneVerification(onVerificationComplete, onCancel);

	return (
		<Card className="max-w-md mx-auto p-4">
			<CardContent className="space-y-4 p-0">
				<VerificationHeader />
				<div className="p-0 space-y-4">
					<div>
						<CardTitle className="mb-2 text-[#101828] text-xl font-bold">Verification Required</CardTitle>
						<p className="text-gray-600">Please enter your phone number</p>
					</div>
					<PhoneInputForm
						onSubmit={handleSubmit}
						numberValid={numberValid}
						error={error}
						countryCode={countryCode}
						phoneNumber={phoneNumber}
						isConsentChecked={isConsentChecked}
						onCountryCodeChange={setCountryCode}
						onPhoneChange={handlePhoneChange}
						onCancel={onCancel}
					>
						{numberValid && (
							<VerificationCodeInputs
								verificationCode={verificationCode}
								onChange={handleVerificationCodeChange}
								onKeyDown={handleVerificationCodeKeyDown}
								onPaste={handleVerificationCodePaste}
							/>
						)}
						<TextMessageDisclaimer isChecked={isConsentChecked} onCheckChange={setIsConsentChecked} />
					</PhoneInputForm>
				</div>
			</CardContent>
		</Card>
	);
};

export default PhoneVerificationV2;
