import React, { useEffect, useState } from "react";
import axios from "axios";
import { plainToInstance } from "class-transformer";
import { ResumeView } from "../../types/ResumeView";
import { useAuth } from "../../contexts/AuthContext";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import ProfileButton from "./ProfileButton";
import { ProfileService } from "../../services/ProfileService";
import { ResumeService } from "../../services/ResumeService";
import { CoverLetterService } from "../../services/CoverLetterService";
import { ProfileView } from "../../types/ProfileView";
import { useFeatureFlagEnabled } from "posthog-js/react";
import NoAccess from "../AuthPages/NoAccess";
import { useEditableField } from "../ResumeAssist/useEditableField";
import { DataTable, ExtendedColumnDef, Filter } from "../shadcn-ui/DataTable";
import { Button } from "../shadcn-ui/Button";
import { DataTableActionMenu } from "../shadcn-ui/DataTableActionMenu";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../shadcn-ui/Dialog";
import { Input } from "../shadcn-ui/Input";

interface EditableCellProps {
	resumeId: string;
	title: string;
	onUpdate: (resumeId: string, field: string, value: string) => Promise<void>;
}

const EditableCell: React.FC<EditableCellProps> = ({ resumeId, title, onUpdate }) => {
	const { renderEditableField } = useEditableField(resumeId, async (updatedResume) => {
		await onUpdate(resumeId, "title", updatedResume.title);
	});

	return renderEditableField("title", title || "Untitled Resume");
};

const ResumeSelector = () => {
	const { instageUser } = useAuth();
	const navigate = useNavigate();
	const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false);

	const [resumes, setResumes] = useState<ResumeView[]>([]);
	const [profile, setProfile] = useState<ProfileView | null>(null);
	const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
	const [selectedResumeId, setSelectedResumeId] = useState<string>("");
	const [newTitle, setNewTitle] = useState("");
	const resumeAssistFeatureFlag = useFeatureFlagEnabled("resume-assist");

	// Add mobile detection on component mount
	useEffect(() => {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			setIsMobileDialogOpen(true);
		}
	}, []);

	useEffect(() => {
		if (instageUser) {
			const loadProfile = async () => {
				const fetchedProfile = await ProfileService.fetchProfileByUserId(instageUser.id);
				console.log("fetchedProfile", fetchedProfile);
				setProfile(fetchedProfile);
			};
			loadProfile();
		}
	}, [instageUser]);

	useEffect(() => {
		if (instageUser) {
			fetchUserResumes().then(setResumes);
		}
	}, [instageUser]);

	const fetchUserResumes = async (): Promise<ResumeView[]> => {
		const response = await ResumeService.fetchUserResumes();
		return plainToInstance(ResumeView, response as ResumeView[]);
	};

	const createResume = async () => {
		try {
			const createdResume = await ResumeService.createResume();
			await CoverLetterService.createCoverLetter(createdResume.id);
			navigate(`/user/resume-assist/${createdResume.id}`);
		} catch (error) {
			console.error("Failed to create resume or cover letter:", error);
			// Handle error appropriately (e.g., show error message to user)
		}
	};

	const formatDate = (date: Date) => {
		return new Date(date).toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric"
		});
	};

	const handleResumeUpdate = async (resumeId: string, field: string, value: string) => {
		try {
			await ResumeService.updateResumeField(resumeId, field, value);
			// Refresh the resumes list
			if (instageUser) {
				fetchUserResumes().then(setResumes);
			}
		} catch (error) {
			console.error("Failed to update resume:", error);
		}
	};

	const handleDeleteResume = async (resumeId: string) => {
		try {
			await CoverLetterService.deleteCoverLetterByResumeId(resumeId);
			await ResumeService.deleteResume(resumeId);

			if (instageUser) {
				fetchUserResumes().then(setResumes);
			}
		} catch (error) {
			console.error("Failed to delete resume:", error);
		}
	};

	const handleRename = async () => {
		try {
			await ResumeService.updateTitle(selectedResumeId, newTitle);
			if (instageUser) {
				fetchUserResumes().then(setResumes);
			}
			setIsRenameModalOpen(false);
			setNewTitle("");
		} catch (error) {
			console.error("Failed to rename resume:", error);
		}
	};

	const columns: ExtendedColumnDef<ResumeView, any>[] = [
		{
			accessorKey: "title",
			header: "Title",
			cell: ({ row }) => <EditableCell resumeId={row.original.id} title={row.original.title} onUpdate={handleResumeUpdate} />
		},
		{
			accessorKey: "jobTitle",
			header: "Job Title",
			cell: ({ row }) => {
				const title = row.original.jobTitle || "";
				return title.length > 15 ? `${title.slice(0, 15)}...` : title;
			}
		},
		{
			accessorKey: "matchScore",
			header: "Match Score",
			cell: ({ row }) => {
				const matchScore = row.original.feedbackNew?.overall?.score || 0;
				return (
					<div className="flex flex-col items-left ml-6">
						<div className="w-12 h-12 relative">
							<svg className="w-full h-full" viewBox="0 0 100 100">
								<circle className="text-gray-200" strokeWidth="8" stroke="currentColor" fill="transparent" r="42" cx="50" cy="50" />
								<circle
									className="text-[#00a9af]"
									strokeWidth="8"
									strokeLinecap="round"
									stroke="currentColor"
									fill="transparent"
									r="42"
									cx="50"
									cy="50"
									strokeDasharray={`${matchScore * 2.64}, 264`}
									transform="rotate(-90 50 50)"
								/>
							</svg>
							<div className="absolute inset-0 flex items-center justify-center">
								<span className="text-sm font-semibold">{Math.round(matchScore)}%</span>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			accessorKey: "createdAt",
			header: "Created",
			cell: ({ row }) => formatDate(row.original.createdAt)
		},
		{
			accessorKey: "updatedAt",
			header: "Last Modified",
			cell: ({ row }) => formatDate(row.original.updatedAt)
		},
		{
			id: "actions",
			cell: ({ row }) => (
				<DataTableActionMenu
					label="Actions"
					menuItems={[
						{
							label: "Edit",
							onClick: () => navigate(`/user/resume-assist/${row.original.id}`)
						},
						{
							label: "Rename",
							onClick: () => {
								setSelectedResumeId(row.original.id);
								setNewTitle(row.original.title || "");
								setIsRenameModalOpen(true);
							}
						},
						{
							label: "Delete",
							onClick: () => handleDeleteResume(row.original.id)
						}
					]}
				/>
			)
		}
	];

	const filters: Filter[] = [
		{
			columnId: "title",
			type: "input",
			placeholder: "Search titles..."
		},
		{
			columnId: "jobTitle",
			type: "input",
			placeholder: "Search job titles..."
		}
	];

	return (
		<>
			{resumeAssistFeatureFlag && (
				<div className="h-full max-w-5xl mx-auto px-4">
					<Dialog open={isMobileDialogOpen} onOpenChange={setIsMobileDialogOpen}>
						<DialogContent>
							<DialogHeader>
								<DialogTitle>Mobile Device Detected</DialogTitle>
							</DialogHeader>
							<div className="space-y-4">
								<p>This tool is not recommended for mobile use. For the best experience, please use a desktop or laptop computer.</p>
								<Button onClick={() => setIsMobileDialogOpen(false)} className="w-full">
									I Understand
								</Button>
							</div>
						</DialogContent>
					</Dialog>

					<h1 className="text-3xl font-bold text-[#16013e] mb-10">Resume Composer</h1>
					<div className="flex flex-col items-left gap-2 mb-4">
						<h1 className="text-lg font-bold text-[#16013e]">Step 1:Your Profile</h1>

						<p className="whitespace-pre-wrap">
							Create your profile to provide details about your work experience, education, and skills. This information will be used to
							generate your resume.
						</p>
					</div>

					<ProfileButton userId={instageUser?.id || ""} profile={profile || null} />
					<div className="flex items-end justify-between gap-2 mb-4">
						<div className="flex flex-col items-left gap-2">
							<h1 className="text-lg font-bold text-[#16013e]">Step 2: Your Resumes</h1>

							<p className="whitespace-pre-wrap">Resumes are generated based on the information in your profile.</p>
						</div>
						{profile && (
							<Button onClick={createResume} className="mb-4">
								<PlusIcon className="w-5 h-5 mr-2" />
								Create Resume
							</Button>
						)}
					</div>
					{profile && <DataTable columns={columns} data={resumes} enableSorting={true} filters={filters} />}
					{!profile && (
						<div className="flex items-center justify-center h-full">
							<p className="text-lg font-normal text-[#16013e]">Complete your profile in Step 1 to unlock this section.</p>
						</div>
					)}
					<Dialog open={isRenameModalOpen} onOpenChange={setIsRenameModalOpen}>
						<DialogContent>
							<DialogHeader>
								<DialogTitle>Rename Resume</DialogTitle>
							</DialogHeader>
							<div className="flex flex-col gap-4">
								<Input
									placeholder="Enter new title"
									value={newTitle}
									onChange={(e) => setNewTitle(e.target.value)}
									className="w-full"
								/>
								<Button onClick={handleRename} className="w-full">
									Rename
								</Button>
							</div>
						</DialogContent>
					</Dialog>
				</div>
			)}
			{!resumeAssistFeatureFlag && (
				<div className="h-full max-w-5xl mx-auto px-4">
					<NoAccess />
				</div>
			)}
		</>
	);
};

export default ResumeSelector;
