import { SparklesIcon } from "@heroicons/react/24/solid";
import { ResumeView } from "../../types/ResumeView";
import { ResumeStyleProps } from "../UserPages/ResumeAssist";

const getScoreColor = (score: number) => {
	const red = "#FF0000";
	const yellow = "#FFD700";
	const teal = "#00a9af";

	if (score <= 50) {
		return `color-mix(in srgb, ${red} ${100 - score * 2}%, ${yellow} ${score * 2}%)`;
	} else {
		return `color-mix(in srgb, ${yellow} ${100 - (score - 50) * 2}%, ${teal} ${(score - 50) * 2}%)`;
	}
};

export const SectionHeader = ({
	title,
	section,
	resume,
	style = { fontSize: 1, accentColor: "#00a9af" }
}: {
	title: string;
	section: string;
	resume: ResumeView;
	style?: ResumeStyleProps;
}) => {
	const score = resume?.feedbackNew?.[section as keyof typeof resume.feedbackNew]?.score;
	return (
		<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
			<h2
				style={{
					fontSize: `${21 * (style?.fontSize || 1)}px`,
					fontWeight: "bold",
					marginBottom: "16px",
					lineHeight: 1.2,
					borderBottom: "1px solid black",
					paddingBottom: "8px",
					width: "100%"
				}}
			>
				{title}
			</h2>
			{/* {typeof score === "number" && section !== "contact" && (
				<div className="score-button" style={{ cursor: "pointer" }}>
					<SparklesIcon style={{ height: "20px", width: "20px", color: getScoreColor(score) }} />
					<span style={{ color: getScoreColor(score), fontWeight: "600" }}>{score}</span>
				</div>
			)} */}
		</div>
	);
};
