import React from "react";
import { BaseTemplateProps } from "./BaseTemplate";
import DocxTemplate from "./DocxTemplate";
import { ResumeView } from "../../../types/ResumeView";
import { CoverLetterView } from "../../../types/CoverLetterView";

export type TemplateType = "classic" | "modern-professional";

export type TemplateTypeCoverLetter = "classic-cl" | "modern-cl";

// Extend BaseTemplateProps to include setResume
interface TemplateProps extends BaseTemplateProps {
	setResume: (resume: ResumeView) => void;
	setCoverLetter: (coverLetter: CoverLetterView) => void;
	isResume: boolean;
}

interface TemplateInfo {
	component: React.ComponentType<TemplateProps>;
	name: string;
	description: string;
}

// Registry of all available templates
export const templates: Record<TemplateType, TemplateInfo> = {
	classic: {
		component: DocxTemplate,
		name: "Classic",
		description: "A traditional single-column layout with a clean, professional look"
	},
	"modern-professional": {
		component: DocxTemplate,
		name: "Modern Professional",
		description: "A modern two-column layout optimized for Word format"
	}
};

export const coverLetterTemplates: Record<TemplateTypeCoverLetter, TemplateInfo> = {
	"modern-cl": {
		component: DocxTemplate,
		name: "Modern Cover Letter",
		description: "A modern cover letter template"
	},
	"classic-cl": {
		component: DocxTemplate,
		name: "Classic Cover Letter",
		description: "A classic cover letter template"
	}
};

// Helper function to get template component
export const getTemplateComponent = (templateType: TemplateType | TemplateTypeCoverLetter, isResume: boolean): React.ComponentType<TemplateProps> => {
	if (isResume) {
		return templates[templateType as TemplateType].component;
	} else {
		return coverLetterTemplates[templateType as TemplateTypeCoverLetter].component;
	}
};
