import { Expose } from "class-transformer";
import { IsUUID, IsString, IsDate, IsObject, IsBoolean, IsArray } from "class-validator";
import { ViewColumn } from "./typeorm-mocks";

export interface Contact {
	firstName: string;
	lastName: string;
	email: string;
	address: string;
	phone: string;
	linkedinURL: string;
	website: string;
}

export interface Experience {
	id: string;
	jobTitle: string;
	companyName: string;
	city: string;
	stateOrProv: string;
	startMonth: string;
	startYear: string;
	endMonth: string;
	endYear: string;
	roles: string[];
}

export interface Education {
	id: string;
	school: string;
	degree: string;
	program: string;
	graduatingYear: string;
	notes: string[];
}

export interface ExtraCurricular {
	id: string;
	activity: string;
	orgName: string;
	startMonth: string;
	startYear: string;
	endMonth: string;
	endYear: string;
	tasks: string[];
}

export interface Skills {
	technical: string[];
	coreCompetencies: string[];
	languages: string[];
	tools: string[];
	frameworks: string[];
}

export interface Certification {
	id: string;
	certificationName: string;
	issuingOrganization: string;
	issueMonth: string;
	issueYear: string;
	expirationMonth: string;
	expirationYear: string;
}

export interface Project {
	id: string;
	projectName: string;
	description: string;
	startMonth: string;
	startYear: string;
	endMonth: string;
	endYear: string;
	projectURL: string;
	notes: string[];
}

export class ProfileView {
	@ViewColumn()
	@Expose()
	@IsUUID()
	id!: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	userId!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	userEmail!: string;

	@ViewColumn()
	@Expose()
	@IsObject()
	contact!: Contact;

	@ViewColumn()
	@Expose()
	@IsString()
	summary!: string;

	@ViewColumn()
	@Expose()
	@IsArray()
	experience!: Experience[];

	@ViewColumn()
	@Expose()
	@IsArray()
	education!: Education[];

	@ViewColumn()
	@Expose()
	@IsArray()
	extraCurricular!: ExtraCurricular[];

	@ViewColumn()
	@Expose()
	@IsObject()
	skills!: Skills;

	@ViewColumn()
	@Expose()
	@IsArray()
	certifications!: Certification[];

	@ViewColumn()
	@Expose()
	@IsArray()
	projects!: Project[];

	@ViewColumn()
	@Expose()
	@IsArray()
	hobbies!: string[];

	@ViewColumn()
	@Expose()
	@IsArray()
	interests!: string[];

	@ViewColumn()
	@Expose()
	@IsBoolean()
	hasAgreedToPrivacy!: boolean;

	@ViewColumn()
	@Expose()
	@IsArray()
	chatHistory!: Array<{
		role: "User" | "Assistant";
		content: string;
		timestamp: Date;
	}>;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	isPublic!: boolean;

	@ViewColumn()
	@Expose()
	@IsDate()
	createdAt!: Date;

	@ViewColumn()
	@Expose()
	@IsDate()
	updatedAt!: Date;
}
