export enum ExperienceType {
	// SessionType.REFLECTION_COOP
	CO_OP_EXPERIENCE = "Co-op Experience",
	INTERNSHIP = "Internship",
	FIELD_PLACEMENT = "Field Placement",
	CAPSTONE_PROJECT = "Capstone Project",
	WEEKLY_SPRINT = "Weekly Sprint",
	MONTHLY_PROJECT = "Monthly Project",

	// SessionType.REFLECTION_COURSE
	COURSE = "Course",

	// SessionType.INBOUND
	CONFERENCE = "Conference",
	DEMO = "Demo",

	// SessionType.INTERVIEW
	INTERVIEW = "Interview",

	// SessionType.FRONTLINE
	FRONTLINE = "Frontline",

	// SessionType.JOB_SEARCH
	JOB_SEARCH_EMPLOYMENT = "Job Search for Employment",
	JOB_SEARCH_COOP = "Job Search for Co-op",
	JOB_SEARCH_INTERNSHIP = "Job Search for Internship",
	JOB_SEARCH_OTHER = "Job Search (Other)",

	// SessionType.CHECK_IN_SIM
	CHECK_IN = "Check-In",

	// SessionType.CAREER_PATH
	CAREER_PATH = "Career Path"
}
